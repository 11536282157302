/* eslint-disable */
import Vue from 'vue'
import router from './router/'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGtm from 'vue-gtm';
import 'swiper/swiper-bundle.css'
import "./assets/stylesheets/base.scss";

import Api from "./lib/api";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    region: 'JP',
    language: 'ja'
  }
})

Vue.use(VueGtm, {
  id: 'GTM-P5WR3KW',
});


new Vue({
  data: {
    showLoadingCount: 0,
    productsInCart: [],
  },
  computed: {
    isStaging: function () {
      return `${window.location.origin}` !== 'https://www.o-welcia.com';
    },
    isLoading: function() {
      return this.$root.showLoadingCount > 0
    },
    selectedShop: function() { // 選択中のショップ
      if (this.productsInCart.length > 0) {
        return this.productsInCart[0].shop;
      } else {
        return null;
      }
    },
  },
  methods :{
    showLoading() {
      this.$root.showLoadingCount++
    },
    hideLoading() {
      this.$root.showLoadingCount--
    },
    onDefaultFailureRequest() {
      this.hideLoading()
    },
    setProductsInCart: function (info) {
      this.productsInCart = info.data;
      this.hideLoading();
    },
    callGetProductsInCart() {
      this.showLoading();
      Api.getUserReservesInCart(this.setProductsInCart, this.$root.onDefaultFailureRequest);
    },
  },
  router,
  render: h => h(App),
}).$mount('#app')
