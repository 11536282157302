<template>
  <div class="mainContent">
    <div class="campaignBanner" v-if="isKaoCampaign">
      <a href="https://m.e-welcia.com/drug/kao_cp_20211101" target="_blank" alt="">
        <img class="point_x_img" src="../../assets/images/else/img_campaign_banner_sample_1.png" alt="">
      </a>
      <p>※下記対象商品は一例です。店舗の取り扱いについては、<br>商品詳細ページからご確認いだだけます。</p>
    </div>
    <div class="innerContent01" :class="{'withCampaign' : isKaoCampaign }">
      <div class="resultTop">
        <p class="txtResult01">
          <span v-show="labelResutl !== ''">
            {{ labelResutl }}の検索結果
          </span>
        </p>
        <p class="txtResult02">全<strong>{{ total_count }}</strong>商品</p>
      </div>
      <p class="btnSearch" @click="openModal"><span>条件で絞り込む</span></p>
      <div class="resultOuter">
        <div class="resultInner">
          <div class="resultBox" v-for="(product, index) in products" :key="product.id">
            <div class="resultLink">
              <a href="javascript:void(0)" @click="selectProduct(product)">
                <div class="upperBlock">
                  <div class="imageBlock">
                    <div class="image">
                      <v-lazy-image
                        :src="$root.$baseApiURL + product.product_images[0].product_image_url" alt=""
                        :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                        v-if="product.product_images[0].product_image_url != ''"
                      />
                      <NoImage 
                        :categoryName='categoryName(product)' 
                        :productMajorId='product.product_major_id'
                        v-else />
                      <div class="text02">
                        {{ product.regulation }}
                      </div>
                    </div>
                    <div class="thumb" v-if="canShowVariation(product)">
                      <div class="img" v-if="canShowVariationImage(product)">
                        <img :src="$baseApiURL + product.product_variation_image_url" alt="">
                      </div>
                      <div class="label"><p>{{ product.product_variation_name }}</p></div>
                    </div>
                  </div>
                  <div class="infoBlock">
                    <div class="pointBox" v-for="product_point in product.product_points" :key="product_point.id">
                      <div class="label02">
                        <PointBox :productPoint="product_point" :position="'left'" />
                      </div>
                    </div>
                    <div class="textBox">
                      <p class="txtItem01">
                        <span class="t01" v-if='product.product_item != null'><span>{{ product.product_item.item_name }}</span></span>
                        <span class="t02" v-if='product.maker != null'>{{ product.maker.maker_name }}</span>
                      </p>
                      <p class="txtItem02">
                        {{ product.product_name | ellipsis }}
                      </p>
                      <p class="txtPrice01">
                        販売価格：<strong class="en">{{ product.price | numberFormat }}円</strong>
                      </p>
                      <p>
                        (税込：{{ product.taxed_price | taxedPrice }}円)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="lowerBlock">
                  <a href="javascript:void(0)" @click.stop="selectProduct(product)" class="btnDetail">
                    <span>商品について詳しく見る</span>
                  </a>
                  <a href="javascript:void(0)" @click.stop="selectProductForBrand(product)" class="btnDetail red" v-if="product.product_major_id === 4">
                    <span>ブランドで見る</span>
                  </a>
                  <a href="javascript:void(0)" @click.stop="selectProductForRack(product)" class="btnDetail red" v-else-if="product.rack_set_items.length > 0">
                    <span>商品のある棚を見る</span>
                  </a>
                </div>
                <Favorite :product_id="product.id" :favorite="product.favorite" />
              </a>
            </div>
            <transition name="fade">
              <div class="promptFavorite" v-if="canShowPromoteFavorite && index == 0">
                <div class="promptFavorite__close" @click="closePromoteFavorite"></div>
                タップしてマイリストに<br>追加できます。
              </div>
            </transition>
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" spinner="circles" v-if="hasCondition">
          <div slot="no-results" class="no-results">検索結果はありません</div>
          <div slot="no-more" class="no-more"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from "jquery";
import Favorite from "../parts/Favorite"
import NoImage from "../parts/NoImage"
import PointBox from "../parts/PointBox"
import utils from "../../packs/mixins/utils.js";

export default {
  name: "SearchResuts01",
  components: {
    Favorite,
    NoImage,
    PointBox,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      products: [],
      total_count: 0,
      current_page: 1,
      infiniteId: 0,
      canShowPromoteFavorite: true,
    };
  },
  filters: {
    ellipsis(text) {
      return text.length >= 32 ? text.slice(0, 32 - "...".length) + "..." : text
    },
  },
  methods: {
    infiniteHandler($state) {
      const onComplete = (info) => {
          this.total_count = info.data.meta.total_count
          if (info.data.data.length != 0) {
            this.products = this.products.concat(info.data.data)
            this.current_page += 1
            $state.loaded();
          } else {
            $state.complete();
          }
          // this.$root.hideLoading()
      }

      // this.$root.showLoading()
      Api.searchProducts(
        this.$route.query.keyword, 
        this.$route.query.product_major_id, 
        this.$route.query.product_middle_id, 
        this.$route.query.product_minor_id, 
        this.$route.query.exists_points,
        null, // exists_image
        null, // product_category
        this.current_page,
        null, // key
        null, // order
        onComplete,
        this.$root.onDefaultFailureRequest
      )
    },
    resetHandler() {
      this.products = []
      this.total_count = 0
      this.current_page = 1
      this.infiniteId ++
    },
    selectProduct(product) {
      if (this.canShowVariation(product)) { // カウンセリング化粧品 or ビューティケア
        this.$router.push({ 
          name: 'cosmetics_02', 
          params: { product_id: product.id },
        })
      } else {
        this.$router.push({ 
          name: 'product_detail_01', 
          params: { product_id: product.id },
        })
      }
    },
    selectProductForBrand(product) {
      this.$router.push({
        name: 'cosmetics_01',
        query: {
          product_major_id: product.product_major_id, 
          product_middle_id: product.product_middle_id,
          product_minor_id: product.product_minor_id,
        },
      })
    },
    selectProductForRack(product) {
      this.$router.push({
        name: 'product_detail_03',
        query: {
          product_major_id: product.rack_set_items[0].product_major_id, 
          product_middle_id: product.rack_set_items[0].product_middle_id,
          product_minor_id: product.rack_set_items[0].product_minor_id,
          rack_set_id: product.rack_set_items[0].rack_set_id,
          stand_no: product.rack_set_items[0].stand_no,
          product_id: product.id,
        },
      })
    },
    openModal() {
      this.$router.push({
        name: "search_results_01",
        query: Object.assign({},this.$route.query,{modal: null})
      });
    },
    closePromoteFavorite() {
      this.canShowPromoteFavorite = false;
    },
  },
  computed: {
    isKaoCampaign() {
      return this.$route.query.keyword === "花王11月まとめ買いキャンペーン"
    },
    labelResutl() {
      let label = ""
      if (this.$route.query.keyword) {
        label = label + this.$route.query.keyword + " "
      }
      if (this.$route.query.exists_points) {
        label = label + "ボーナスポイントあり "
      }
      return label
    },
    hasCondition() {
      return this.$route.query.keyword !== undefined 
      || this.$route.query.product_major_id !== undefined 
      || this.$route.query.product_middle_id !== undefined
      || this.$route.query.product_minor_id !== undefined
      || this.$route.query.exists_points !== undefined
    }
  },
  watch: {
    "$route.query.keyword"() {
      this.resetHandler()

      // TODO 暫定 発火しないので強制発火(ただしクルクルが出ない。)
      this.infiniteHandler({ loaded: () => {}, complete: () => {} });
    },
    "$route.query.product_major_id"() {
      this.resetHandler()

      // TODO 暫定 発火しないので強制発火(ただしクルクルが出ない。)
      this.infiniteHandler({ loaded: () => {}, complete: () => {} });
    },
    "$route.query.product_middle_id"() {
      this.resetHandler()

      // TODO 暫定 発火しないので強制発火(ただしクルクルが出ない。)
      this.infiniteHandler({ loaded: () => {}, complete: () => {} });
    },
    "$route.query.product_minor_id"() {
      this.resetHandler()

      // TODO 暫定 発火しないので強制発火(ただしクルクルが出ない。)
      this.infiniteHandler({ loaded: () => {}, complete: () => {} });
    },
    "$route.query.exists_points"() {
      this.resetHandler()

      // TODO 暫定 発火しないので強制発火(ただしクルクルが出ない。)
      this.infiniteHandler({ loaded: () => {}, complete: () => {} });
    },
    "$route.query.modal"() {
      if (this.$route.query.modal === null) {
        $('#searchOverlay').fadeIn();
        if($(this).hasClass('open')){
          $(this).removeClass('open');
          $('.searchBox').slideUp();
          $('#searchOverlay').fadeOut();
        }else{
          $(this).addClass('open');
          $('.searchBox').slideDown();
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.modal === null) {
      $('#searchOverlay').fadeIn();
      if($(this).hasClass('open')){
        $(this).removeClass('open');
        $('.searchBox').slideUp();
        $('#searchOverlay').fadeOut();
      }else{
        $(this).addClass('open');
        $('.searchBox').slideDown();
      }
    }
    if (!this.hasCondition) {
      this.openModal()
    } else {
      this.resetHandler()
    }
  },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/search_results.scss";
  .no-results {
    font-size: 1.5rem;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>