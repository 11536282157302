<template>
  <div>
    <h1 class="title01">
      マイリスト
    </h1>
  </div>
</template>

<script>
export default {
  name: "MyListHeader",
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";  
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>