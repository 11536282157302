<template>
  <div>
    <StoreListHeader />
    <router-view/>
  </div>
</template>

<script>
import StoreListHeader from '../global/StoreListHeader'

export default {
  name: 'StoreListLayout',
  components: {
    StoreListHeader,
  },
  data() {
    return {};
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
