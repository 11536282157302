<template>
  <div>
    <div>
      <header id="header" :class="{ 'topHader': isTransparent }">
        <div class="hNav">
          <button type="button" aria-expanded="false" class="hBtnMenu js-gnav">
            <span class="line line01"></span>
            <span class="line line02"></span>
            <span class="line line03"></span>
            <span class="open">MENU</span>
            <span class="close">閉じる</span>
          </button>
          <div class="hNavInner">
            <nav class="hNavBody" aria-hidden="true" aria-label="メインメニュー">
              <ul class="hNavList">
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_top"> TOP </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_how_to_use_01"> このサイトの使い方 </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_search_results_01_with_exists_points"> ボーナスポイント商品を見る </a>
                </li>
                <li class="hNavItem">
                  <a class="hNavLink closeNav" @click="goto_store_map_01"> 売り場で探す </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_my_list_01"> マイリスト </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_backorder_histories_01"> 注文履歴 </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_faq_01"> よくあるご質問 </a>
                </li>
                <li class="hNavItem">
                  <a href="javascript:void(0)" class="hNavLink closeNav" @click="goto_store_list_01"> 対象店舗一覧 </a>
                </li>
                <li class="hNavItem">
                  <a href="https://www.e-welcia.com/" target="_blank" class="hNavLink"><span class="other_site">ウエルシアドットコム</span></a>
                </li>
                <li class="hNavItem">
                  <a href="https://www.welcia-yakkyoku.co.jp/" target="_blank" class="hNavLink"><span class="other_site">会社案内</span></a>
                </li>
              </ul>
            </nav>
            <div id="footMenuBox" class="footer" v-if="!hasOnlyMenu">
              <div id="footMenu">
                <p class="fSearch">
                  <input type="text" placeholder="商品名・キーワード・JANコードで探す">
                  <button class="fBtnSearch"> 探す </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="hSearch">
          <a href="javascript:void(0)" class="searchLink" @click="goto_search_results_01"><span>検索</span></a>
        </p>
        <div class="headerLogo">
          <h1 class="headerTtl">
            <a href="/" aria-current="page" class="router-link-exact-active router-link-active">
              <img src="../../assets/images/common/logo.png" alt="welcia">
            </a>
          </h1>
        </div>
        <p class="hMyList" :class="{ 'hMyListWithoutMenu' : hasOnlyMenu }">
          <router-link :to="{ name: 'my_list_01' }" class="hMyListLink" :class="{ 'hMyListLink-top' : isBlinkingMyList }">
            <span>マイリスト</span>
          </router-link>
        </p>
        <div class="hMyCart">
          <router-link :to="{ name: 'cart_01' }" class="hMyCartLink">
            <span>お取り寄せ</span><div class="number" v-if="this.$root.productsInCart.length > 0">{{ this.$root.productsInCart.length }}</div>
          </router-link>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
// import SearchFooter from '../parts/SearchFooter'

import $ from 'jquery';

export default {
  name: "TitleHeader",
  components: {
    // SearchFooter,
  },
  props: {
    hasOnlyMenu: {
      type: Boolean,
      default: false
    },
    isBlinkingMyList: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    goto_search_results_01() {
      this.close_nav();

      if(this.$route.name === "search_results_01" ) { // 商品検索にいるページにいる
        if (this.$route.query.modal === null ) { // 検索モーダルを開いている場合、検索モーダルを閉じる
          this.$router.push({
            name: "search_results_01",
            query: Object.assign({},this.$route.query,{modal: undefined}) 
          });
        } else { // 検索モーダルを閉じている場合、検索モーダルを開く
          this.$router.push({
            name: "search_results_01",
            query: Object.assign({},this.$route.query,{modal: null})
          });
        }
      } else { // 商品検索ページ以外にいる場合、検索ページ遷移して、検索モーダルを開く
        this.$router.push({
          name: "search_results_01",
          query: {modal: null},
        });
      }
    },
    goto_top() {
      this.open_close_nav();
      this.$router.push({
        name: "top",
      });
    },
    goto_search_results_01_with_exists_points() {
      this.open_close_nav();
      this.$router.push({
        name: "search_results_01",
        query: {exists_points: true},
      });
    },
    goto_store_map_01() {
      this.open_close_nav();
      this.$router.push({
        name: "store_map_01",
      });
    },
    goto_my_list_01() {
      this.open_close_nav();
      this.$router.push({
        name: "my_list_01",
      });
    },
    goto_backorder_histories_01() {
      this.open_close_nav();
      this.$router.push({
        name: "backorder_histories_01",
      });
    },
    goto_faq_01() {
      this.open_close_nav();
      this.$router.push({
        name: "faq_01",
      });
    },
    goto_how_to_use_01() {
      this.open_close_nav();
      this.$router.push({
        name: "how_to_use_01",
      });
    },
    goto_store_list_01() {
      this.open_close_nav();
      this.$router.push({
        name: "store_list_01",
      });
    },
    goto_search_result_01() {
      this.open_close_nav();
    },
    close_nav() {
      $(".hNav").removeClass("js-navOpen");

    },
    open_close_nav() {
      $(".hNav").toggleClass("js-navOpen");
    }
  },
  mounted() {
    this.$root.callGetProductsInCart();

    var self = this;
    // jQuery
    $('.hBtnMenu.js-gnav').click(function (e) {
        e.preventDefault();
        self.open_close_nav();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  
  // z-index: 10000だと各種モーダルより前に表示されてしまうため、1001に変更
  #header {
    .hNavInner {
      // z-index: 1001;
    }
    &.topHader {
      background-color: rgba(0, 0, 0, 0);
    }
    .hMyListWithoutMenu::before {
      @media screen and (min-width: 1025px) {
        display: none;
      }
    }
  }
</style>
