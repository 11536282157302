<template>
  <div>
    <WhatIsThisHeader/>
    <router-view/>
  </div>
</template>

<script>
import WhatIsThisHeader from '../global/WhatIsThisHeader'

export default {
  name: 'WhatIsThisLayout',
  components: {
    WhatIsThisHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
