<template>
  <div>
    <MyStoreListModal 
      :myshops="myshops" 
      @addMyshop="addMyshop" 
      @openMyStoreSearchModal="openMyStoreSearchModal"
      @changeSeletedShop="changeSeletedShop"
      @removeMyshop="clickRemoveMyshop" />

    <MyStoreSearchModal 
      :productId="productId" 
      :myshops="myshops" 
      @addMyshop="addMyshop" />

    <div class="confirm-modal modal" id="confirm-modal-01">
      <div class="modal-overlay"></div>
      <div class="content">
        <div class="ttl">ご案内</div>
        <div class="content-info">
          <p>「よく使う店舗」から削除しますか？</p>
          <div class="btn-wrapper">
            <div class="btn" @click="confirmRemoveMyshop">はい</div>
            <div class="btn" @click="cancelRemoveMyshop">いいえ</div>
          </div>
        </div>
      </div>
    </div>

    <div class="confirm-modal modal" id="confirm-modal-02">
      <div class="modal-overlay"></div>
      <div class="content">
        <div class="ttl">ご案内</div>
        <div class="content-info">
          <p>「よく使う店舗」へ登録完了しました。</p>
          <div class="btn-wrapper">
            <div class="btn" @click="closeAddMyshopModal">閉じる <img src="../../assets/images/common/ico_none.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>

    <div class="confirm-modal modal" id="confirm-modal-03">
      <div class="modal-overlay"></div>
      <div class="content">
        <div class="ttl">ご案内</div>
        <div class="content-info">
          <p>「よく使う店舗」の登録数は<br>最大3件までとなっております。<br>既にご登録されている店舗を削除してから、<br>再度ご登録ください。</p>
          <div class="btn-wrapper">
            <div class="btn" @click="closeUpperlimitModal">閉じる <img src="../../assets/images/common/ico_none.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Api from "../../lib/api";
import utils from "../../packs/mixins/utils.js";

import MyStoreListModal from "../parts/MyStoreListModal"
import MyStoreSearchModal from "../parts/MyStoreSearchModal"

export default {
  name: "MyStoreList",
  components: {
    MyStoreListModal,
    MyStoreSearchModal,
  },
  mixins: [utils],
  props: {
    myshops: Array,
    productId: Number,
  },
  data() {
    return {
      selected_shop_id: "",
    };
  },
  computed: {
  },
  methods: {
    clickRemoveMyshop: function(myshop) {
      this.selected_shop_id = myshop.id
      $('#confirm-modal-01').fadeIn();
    },
    confirmRemoveMyshop: function() {
      $('#confirm-modal-01').fadeOut();
      this.callRemoveMyshopApi();
    },
    cancelRemoveMyshop: function() {
      this.selected_shop_id = "";
      $('#confirm-modal-01').fadeOut();
    },
    completeAddMyshop: function() {
      $('#confirm-modal-02').fadeIn();
    },
    closeAddMyshopModal: function() {
      if (this.is_no_header) {
        window.location.href = this.$route.query.redirect || '#';
      } else {
        $('#confirm-modal-02').fadeOut();
      }
    },
    showUpperlimitModal: function() {
      $('#confirm-modal-03').fadeIn();
    },
    closeUpperlimitModal: function() {
      $('#confirm-modal-03').fadeOut();
    },
    openMyStoreSearchModal() {
      $('#main-modal').fadeIn();
    },
    openMyStoreListModal() {
      $('#main-modal-2').fadeIn();
    },
    addMyshop(shop) {
      if(this.myshops.length >= 3) {
        this.showUpperlimitModal();
        return;
      }
      this.callPostMyshopApi(shop);
    },
    changeSeletedShop(shop) {
      this.callChangeSelectedShopApi(shop);
    },
    callbackPostMyshopApi: function() {
      this.$root.hideLoading();
      this.$emit("getMyshops");
      this.completeAddMyshop();
    },
    callPostMyshopApi: function(shop) {
      this.$root.showLoading();
      Api.postMyshop(
        shop.id,
        this.callbackPostMyshopApi,
        this.$root.onDefaultFailureRequest
      );
    },
    callbackChangeSelectedShopApi: function() {
      this.$root.hideLoading();
      this.$emit("getMyshops");
    },
    callChangeSelectedShopApi: function(shop) {
      this.$root.showLoading();
      Api.putSelectedMyShop(
        shop.id,
        this.callbackChangeSelectedShopApi,
        this.$root.onDefaultFailureRequest
      );
    },
    callbackRemoveMyshopApi: function() {
      this.selected_shop_id = "";
      this.$root.hideLoading();
      this.$emit("getMyshops");
    },
    callRemoveMyshopApi: function() {
      this.$root.showLoading()
      Api.deleteMyshop(
        this.selected_shop_id,
        this.callbackRemoveMyshopApi,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";
</style>
