<template>
  <div class="mCatBox">
    <div class="mCatBoxInner">
      <p class="mTitle01">{{ product_middle_name }}</p>
      <router-link
        class="closeModal"
        @click.native="closeModal()"
        :to="{ 
          name: 'product_detail_03', 
          query: { 
            product_major_id: $props.product_major_id, 
            product_middle_id: $props.product_middle_id, 
            product_minor_id: $props.product_minor_id, 
            rack_set_id: $props.rack_set_id, 
            stand_no: 1, 
          }
        }">
        <span class="text">売場に行く</span>
      </router-link>
    </div>
    <div class="mCatBoxInner border">
      <!-- <p class="mTitle01"><small>条件を絞り込む</small></p> -->
      <ul class="mListCat">
        <li v-for="product_minor in this.product_minors" :key="product_minor.id">
          <router-link
            @click.native="closeModal()"
            :to="{ 
              name: 'product_detail_03', 
              query: { 
                product_major_id: $props.product_major_id, 
                product_middle_id: $props.product_middle_id, 
                product_minor_id: product_minor.id, 
                rack_set_id: $props.rack_set_id, 
                stand_no: 1, 
              }
            }">
            <span class="image">
              <v-lazy-image
                :src="$root.$baseApiURL + product_minor.product_image_url"
                :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                alt=""
                v-if="product_minor.product_image_url != ''"
              />
              <img src="../../assets/images/else/img_product_no_image.png" alt=""  v-else />
            </span>
            <span class="text">{{ product_minor.product_minor_name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
// import $ from "jquery";

export default {
  name: "MiddleCategoryBox",
  components: {
  },
  props: {
    product_major_id: Number,
    product_middle_name: String,
    product_middle_id: Number,
    product_minor_id: Number,
    rack_set_id: Number,
  },
  data() {
    return {
      product_minors: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    setProductMinors: function (info) {
      this.product_minors = info.data
      this.$root.hideLoading()
    },
    callProductMinorApi() {
      this.$root.showLoading()
      Api.getProductMiners(this.product_middle_id, true, this.setProductMinors, this.$root.onDefaultFailureRequest);
    },
  },
  watch: {
    "$props.product_middle_id"() {
      if (this.$props.product_middle_id !== 0) {
        this.callProductMinorApi()
      }
    },
  },
  mounted() {
    this.callProductMinorApi();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  #modalCategory {
    .mCatBox {
      .mCatBoxInner {
        .mListCat {
          li {
            a {
              .image {
                height: 8.1rem;
                @media print, screen and (min-width: 1025px) {
                  height: 64px;
                }
                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
</style>