<template>
  <div>
    <div id="modalMiddleCategory">
      <div id="modalCategory">
        <div id="categoryOverlay"></div>
        <p id="categoryModalClose01" @click="closeModal()">閉じる</p>
        <MiddleCategoryBox
          :product_major_id="product_major_id"
          :product_middle_id="product_middle_id"
          :product_middle_name="product_middle_name"
          :product_minor_id="product_minor_id"
          :rack_set_id="rack_set_id"
          @closeModal="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Api from "../../lib/api";
import MiddleCategoryBox from "../parts/MiddleCategoryBox";
import $ from "jquery";

export default {
  name: "MiddleCategoryModal",
  components: {
    MiddleCategoryBox,
  },
  props: {
    product_major_id: Number,
    product_middle_id: Number,
    product_middle_name: String,
    product_minor_id: Number,
    rack_set_id: Number,
  },
  data() {
    return {
    };
  },
  methods: {
    closeModal() {
      $('#modalMiddleCategory #modalCategory').fadeOut();

      // modalのパラメタを消す
      this.$router.push({
        name: "product_detail_03",
        query: { 
          product_major_id: this.$route.query.product_major_id, 
          product_middle_id: this.$route.query.product_middle_id, 
          product_minor_id: this.$route.query.product_minor_id, 
          rack_set_id: this.$route.query.rack_set_id, 
          stand_no: this.$route.query.stand_no,
          can_cross_product_minors: this.$route.query.can_cross_product_minors,
        }
      });
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#modalMiddleCategory {
  visibility: visible; // MiddleCategoryHeaderがvisibility: hidden;でも表示できるようにする
}
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
</style>