<template>
  <div class="modal" id="main-modal">
    <div class="modal-overlay"></div>
    <p class="modal-close" @click="closeModal()">閉じる</p>
    <div class="content">
      <div class="whitebox">
        <div class="ttl">マイ店舗の選択</div>
        <p class="note">※一部実施していないエリア・店舗がございます。<br>対象店舗は
          <router-link class="link01" :to="{ name: 'store_list_01' }">
            こちら。
          </router-link>
        </p>
        <div class="boxItem03">
          <div class="boxItemInner01">
            <div class="store_choose_box">
              <div class="ttl">店舗を検索</div>
              <div class="label">現在地から検索</div>
              <div class="btn-wrapper">
                <div class="btn-search red" @click="searchByCurrentLocation">近くの実施店舗を探す</div>
              </div>
            </div>
          </div>
        </div>

        <div class="boxItem03">
          <div class="boxItemInner01">
            <div class="store_choose_box">
              <div class="label">地名を入力して検索</div>
              <input type="text" name="" placeholder="例：静岡県、千代田区など" v-model="place">
              <p class="error" v-if="this.messagePlaceError.length">
                {{ this.messagePlaceError }}
              </p>
              <div class="label">郵便番号で検索</div>
              <input type="text" name="" placeholder="1110000（ハイフンなしで入力）" v-model="zipcode">
              <p class="error" v-if="this.messageZipcodeError.length">
                {{ this.messageZipcodeError }}
              </p>
              <div class="btn-wrapper">
                <div class="btn-search blue" @click="comfirmKeyword">検索</div>
              </div>
              <p class="txtInfo07" v-if="shops.length > 0 && productId"><label for="check01">お取り扱いのある店舗のみ表示<input type="checkbox" id="check01" class="checkBox" v-model="filtered"></label></p>
            </div>
          </div>
        </div>

        <div class="boxItem03" v-if="has_searched">
          <div class="boxItemInner01">
            <div class="store_choose_box">
              <div class="result-info">
                <p v-if="has_searched_by_current_location">「現在地」の検索結果</p>
                <p v-else>「{{ keyword }}」の検索結果</p>
                <p>全{{ filterd_shops.length }}件</p>
              </div>
              <div class="store_list result_list">
                <div class="store" v-for="shop in filterd_shops" :key="shop.id">
                  <div class="leftBox">
                    <p class="shopName"><a href="javascript:void(0)" class="icoBlank" target="_blank" @click="openShopSite(shop)">{{ shop.shop_name }}</a></p>
                    <p class="shopText">〒{{ shop.zip_code }}</p>
                    <p class="shopText">{{ shop.shop_address }}</p>
                  </div>
                  <div class="btn_list">
                    <div class="btnStock01 blue">
                      <router-link
                        :to="{
                          name: 'store_search_03',
                          params: {
                            shopCode: shop.id,
                            shopName: shop.shop_name,
                            currentLatitude: current_latitude,
                            currentLongitude: current_longitude,
                          },
                          query: {
                            shop_latitude: shop.latitude,
                            shop_longitude: shop.longitude,
                          },
                        }"
                        >
                        MAPで確認
                      </router-link>
                    </div>
                    <div class="btnStock01 blue2" @click="addMyshop(shop)" v-if="!registered(shop)"><a href="javascript:void(0)">この店舗を登録</a></div>
                    <div class="btnStock01 blue2 gray" v-else><a href="javascript:void(0)">登録済</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import Geo from "../../lib/geo";
import $ from "jquery";

export default {
  name: "MyStoreSearchModal",
  components: {
  },
  props: {
    myshops: Array,
    productId: Number,
  },
  data() {
    return {
      shops: [],

      place: "",
      zipcode: "",
      filtered: false,

      has_searched_by_keyword: false,
      has_searched_by_current_location: false,

      current_latitude: null,
      current_longitude: null,

      messagePlaceError: "",
      messageZipcodeError: "",
    };
  },
  computed: {
    filterd_shops: function() {
      if (this.filtered) {
        return this.shops.filter(s => s.available)
      } else {
        return this.shops
      }
    },
    keyword: function() {
      if(this.place && this.zipcode) {
        return this.place + " " + this.zipcode; // 場所と郵便番号が両方ある場合はスペース区切り
      } else {
        return this.place + this.zipcode; // 場所と郵便番号が片方の場合はどちらかだけ
      }
    },
    has_searched: function() {
      return this.has_searched_by_keyword || this.has_searched_by_current_location;
    },
  },
  methods: {
    reset: function() {
      this.shops = [];
      this.place = "";
      this.zipcode = "";
      this.filtered = false;
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = false;
    },
    registered: function(shop) { // マイショップ登録済か
      return this.myshops.some(s => s.id === shop.id)
    },
    closeModal() {
      this.reset();
      $('#main-modal').fadeOut();
    },
    addMyshop(shop) {
      this.reset();
      $('#main-modal').fadeOut();
      this.$emit("addMyshop", shop);
    },
    openShopSite(shop) {
      window.open(shop.site_url)
    },
    searchByCurrentLocation: function() {
      this.$root.showLoading();
      this.reset();
      const onSuccess = (position) => {
        this.current_latitude = position.coords.latitude;
        this.current_longitud = position.coords.longitude;
        this.callSearchNearShopsApi(
          position.coords.latitude,
          position.coords.longitude,
        )
        this.$root.hideLoading();
      }
      const onFailure = () => {
        this.$root.hideLoading();
      }
      Geo.getLocation(onSuccess, onFailure)
    },
    comfirmKeyword() {
      if (this.keyword.length == 0) {
        this.messagePlaceError = "地名または郵便番号のどちらかを入力してください。";
        this.messageZipcodeError = "地名または郵便番号のどちらかを入力してください。";
        return;

      } else {
        this.messagePlaceError = "";
        this.messageZipcodeError = "";
      }

      if (this.zipcode.trim().length !== 0 && this.zipcode.trim().length < 3) {
        this.messageZipcodeError = "郵便番号は3桁以上を入力してください。";
        return;
      } else {
        this.messageZipcodeError = "";
      }

      this.callSearchShops()
    },
    setShops: function (info) {
      this.shops = info.data.filter(s => this.myshops.length === 0 || this.myshops.some(ms => ms.id !== s.id)); // マイショップに含まれていない店舗だけ抽出
      this.$root.hideLoading();
    },
    callSearchNearShopsApi: function(latitude, longitude) {
      this.$root.showLoading()
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = true;
      Api.searchNearShops(
        this.productId,
        latitude,
        longitude,
        this.setShops,
        this.$root.onDefaultFailureRequest
      );
    },
    callSearchShops: function() {
      this.$root.showLoading();
      this.has_searched_by_keyword = true;
      this.has_searched_by_current_location = false;
      Api.searchShops(
        this.productId, 
        this.place,
        this.zipcode,
        this.setShops, 
        this.$root.onDefaultFailureRequest
      );
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.error {
  font-size: 1.6rem;
  color: #dd4141;
}

  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";

</style>
