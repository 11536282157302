import { render, staticRenderFns } from "./ProductDetail03.vue?vue&type=template&id=f725ef90&scoped=true&"
import script from "./ProductDetail03.vue?vue&type=script&lang=js&"
export * from "./ProductDetail03.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetail03.vue?vue&type=style&index=0&id=f725ef90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f725ef90",
  null
  
)

export default component.exports