<template>
  <div>
    <TopHeader/>
    <router-view/>
  </div>
</template>

<script>
import TopHeader from '../global/TopHeader'

export default {
  name: 'TopLayout',
  components: {
    TopHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = 'blue';
  },
}
</script>

<style scoped>
* {
  background-color: #0185cf;
}
</style>
