<template>
  <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
    <SelectedStoreBox :selectedShop='this.$root.selectedShop'/>
    <div class="innerContent01">
      <div class="gray-box-column"><!--タグ追加-->
        <CartBox :productsInCart='this.$root.productsInCart' @getProductsInCart='this.$root.callGetProductsInCart' />
        <div class="boxInfo01-wrapper"><!--タグ追加-->
          <div class="boxInfo01">
            <h2 class="ttlInfo01">お客様情報の登録</h2>
            <div class="info06">
              <h3 class="ttlInfo03">認証コード</h3>
              <p class="inputInfo01">
                <input
                  type="text"
                  placeholder="番号4桁（例：1234）"
                  v-model="auth_code"
                />
              </p>
              <p class="error" v-if="this.messageAuthCodeError.length">
                {{ this.messageAuthCodeError }}
              </p>
              <p class="btnRed01">
                <a @click="comfirmAuthCode()">認証する</a>
              </p>
            </div>
          </div>
        </div><!--タグ追加-->
      </div><!--タグ追加 /.gray-box-column-->
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import utils from "../../packs/mixins/utils.js";

import SelectedStoreBox from "../parts/SelectedStoreBox"
import CartBox from "../parts/CartBox"


export default {
  name: "Backorder02_2",
  props: {
  },
  components: {
    SelectedStoreBox,
    CartBox,
  },
  mixins: [utils],
  data() {
    return {
      auth_code: "",
      messageAuthCodeError: "",
    };
  },
  computed: {
  },
  methods: {
    callbackVerifyAuthCodeApi: function (info) {
      if (info.data.check_result) {
        this.$router.push({
          name: "backorder_03",
          query: Object.assign({},this.$route.query),
        });
      } else {
        this.messageAuthCodeError = "認証コードが間違っています";
      }
      this.$root.hideLoading()
    },
    callVerifyAuthCodeApi() {
      this.$root.showLoading();

      const user_reserve_ids = this.$root.productsInCart.map(i => i.id);
      Api.verifyAuthCode(
        user_reserve_ids,
        this.auth_code,
        this.callbackVerifyAuthCodeApi,
        this.$root.onDefaultFailureRequest
      );
    },
    comfirmAuthCode() {
      if (this.auth_code == null || this.auth_code.length == 0) {
        this.messageAuthCodeError = "認証コードを入力してください";
      } else if (!Number.isInteger(Number(this.auth_code))) {
        this.messageAuthCodeError = "認証コードは数字を入力してください";
      } else if (this.auth_code.length != 4) {
        this.messageAuthCodeError = "認証コードは4桁で入力してください";
      } else {
        this.messageAuthCodeError = "";
      }

      if (
        this.messageAuthCodeError.length > 0
      ) {
        return;
      }
      this.callVerifyAuthCodeApi()
    },
  },
  mounted() {
    this.$root.callGetProductsInCart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .error {
    font-size: 1.6rem;
    color: #dd4141;
  }
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>
