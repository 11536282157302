<template>
  <div id="headMenu">
    <TitleHeader />
    <div v-if="isCosmetics()" class="cosmetics">
      <MajorCategoryHeader />
      <MiddleCategoryHeader is-hidden="false" />
      <MinerBrandHeader />
    </div>
    <div v-else>  
      <div v-if="hasNeighborhood()">
        <MajorCategoryHeader />
        <MiddleCategoryHeader />
        <MinerCategoryHeader />
        <p class="aid" v-if="isRack()">
          棚にない商品は、
          <router-link :to= "{
            name: 'search_results_01',
          }">
            検索
          </router-link>
          からお探しください
        </p>
      </div>
      <div v-else>
        <BreadcrumbHeader />
      </div>
    </div>
  </div>
</template>

<script>
import TitleHeader from "../parts/TitleHeader";
import MajorCategoryHeader from "../parts/MajorCategoryHeader";
import MiddleCategoryHeader from "../parts/MiddleCategoryHeader";
import MinerCategoryHeader from "../parts/MinerCategoryHeader";
import MinerBrandHeader from "../parts/MinerBrandHeader";
import BreadcrumbHeader from "../parts/BreadcrumbHeader";

export default {
  name: "Header",
  components: {
    TitleHeader,
    MajorCategoryHeader,
    MiddleCategoryHeader,
    MinerCategoryHeader,
    MinerBrandHeader,
    BreadcrumbHeader,
  },
  data() {
    return {
    };
  },
  methods: {
    isCosmetics() {
      return this.$route.query.product_major_id == 4;
    },
    hasNeighborhood() {
      return this.$route.query.rack_set_id !== undefined;
    },
    isRack() {
      return this.$route.name === 'product_detail_03';
    },
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  .cosmetics {
    @import "../../assets/stylesheets/responsive/cosmetics.scss";
  }
</style>
