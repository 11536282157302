<template>
  <div>
    <div class="mainContent" ref="main_content">
      <div class="title02 red" v-if="!selectedMyshop">
        <p>
          マイ店舗を<span v-if="hasRegisteredMyShops">選択</span><span v-else>ご登録</span>いただくと、<br>商品の取り扱いが確認できます。
        </p>
        <div class="btn" @click="openMyStoreListModal">マイ店舗を<span v-if="hasRegisteredMyShops">選択</span><span v-else>登録</span></div>
      </div>
      <div class="title02" v-else>
        <p>選択中のマイ店舗</p>
        <div class="ttl">{{ selectedMyshop.shop_name }}</div>
        <div class="btn" @click="openMyStoreListModal">変更</div>
      </div>
      <div class="editActionArea" v-if="favorites.length > 0">
        <button class="editActionArea-btn" @click.stop="toggleEditing">
          <span v-if="!is_editing_order" class="editActionArea-btn-text-edit">並び替える</span> 
          <span v-else class="editActionArea-btn-text-done">並び替え完了</span>
        </button> 
      </div>
      <div class="innerContent01" v-if="favorites.length > 0">
        <div class="productBox01">
          <draggable 
            v-model="favorites" 
            :disabled="!is_editing_order"
            draggable=".itemWrapper"
            handle=".img"
            class="productBoxInner01" 
            :options="{ animation: 150 }" 
            @start="draggableStart"
            @end="draggableEnd">
            <div class="itemWrapper" :class="{'no-ship' : !selectedMyshop}" v-for="(favorite, index) in favorites" :key="favorite.id">
              <a href="javascript:void(0)" class="productItem" @click="selectProduct(favorite)">
                <p class="title">{{ favorite.product_name | ellipsis }}</p>
                <div class="price">販売価格：{{ favorite.price | numberFormat }}円<br>（税込：{{
                  favorite.taxed_price | numberFormat
                }}円）</div>
                <div class="color-wrapper">
                  <div v-if="hasVariationsWithNeighborhood(index)">
                    <img class="color" :src="$baseApiURL + favorite.product_variation_image_url" alt="" v-if="favorite.product_variation_image_url">
                    <div class="color" v-else></div>
                    <p>{{ favorite.product_variation_name }}</p>
                  </div>
                </div>
                <div class="img" :class="{ 'shiver' : is_editing_order }">
                  <p class="btnRemove" @click.stop="clickRemove(favorite.id)" v-if="!is_editing_order"></p>
                  <v-lazy-image
                    :src="$baseApiURL + favorite.product_main_image_url" alt=""
                    :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                    v-if="favorite.product_main_image_url != ''"
                  />
                  <NoImage 
                    :categoryName='categoryName(favorite)' 
                    :productMajorId='favorite.product_major_id'
                    v-else />
                  <div class="text02">
                    {{ favorite.regulation }}
                  </div>
                </div>
                <div class="ship-wrapper" v-if="selectedMyshop">
                  <div class="btn btnStock01 stock" v-if="isInStock(favorite)">取り扱い</div>
                  <div class="btn btnStock01 none" v-else>取り扱い</div>
                </div>
                <div class="btn-add" v-if="selectedMyshop && backorderable(favorite)" @click.stop="clickAddToCart(favorite.id)">取り寄せ</div>
                <div class="btn-add hide" v-else>取り寄せ</div>
              </a>
            </div>
          </draggable>
        </div>
      </div>
      <div class="explanation">
        <div class="explanation__image">
          <img src="../../assets/images/else/img_mylist_00.png" alt="">
        </div>
        <div class="explanation__arrow">
        </div>
        <div class="explanation__image">
          <img src="../../assets/images/else/img_mylist_01.png" alt="">
        </div>
        <div class="explanation__arrow">
        </div>
        <div class="explanation__image">
          <img src="../../assets/images/else/img_mylist_02.png" alt="">
        </div>
        <div class="explanation__desc">
          ※マイリストは登録されたブラウザでのみ表示されます。
        </div>
      </div>
    </div>
    <div class="warning-modal modal" id="warning-modal-01">
      <div class="modal-overlay"></div>
      <div class="content">
        <div class="content-info">
          <div class="ttl"><span>ご注意</span></div>
          <p class="btnClose" @click="cancelAddToCart"></p>
          <p>取り寄せ店舗を変更すると<br>お取り寄せ一覧がリセットされます。</p>
          <div class="btn-wrapper">
            <div class="btn" @click="goToCart">お取り寄せ一覧を見る</div>
            <div class="btn red js-modal" data-id="main-modal-2" @click="confirmAddToCart">リセットして続ける</div>
          </div>
        </div>
      </div>
    </div>
    <MyStoreList
      ref="my_store_list"
      :myshops="myshops"
      @getMyshops="callGetMyshopsApi" />
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from "jquery";
import NoImage from "../parts/NoImage"
import MyStoreList from "../parts/MyStoreList"
import utils from "../../packs/mixins/utils.js";
import draggable from 'vuedraggable'

export default {
  name: "MyList01",
  components: {
    NoImage,
    MyStoreList,
    draggable,
  },
  mixins: [
    utils
  ],
  props: {
  },
  data() {
    return {
      favorites: [],
      removing_product_id: null,

      myshops: [],

      selected_product_id: "", // 取り寄せボタン押下時に一時的に選択した商品を保存しておく

      is_editing_order: false,
      ordered_product_ids: [],
    }
  },
  filters: {
    ellipsis(text) {
      return text.length >= 20 ? text.slice(0, 20 - "...".length) + "..." : text
    },
  },
  computed: {
    hasRegisteredMyShops: function() { // マイショップ登録済みか
      return this.myshops.length > 0;
    },
    selectedMyshop: function() { // 選択中のマイショップ
      return this.myshops.find(s=>s.selected)
    },
  },
  methods: {
    toggleEditing: function() {
      if(this.is_editing_order) {
        this.updateFavoritesOrder(this.ordered_product_ids);
      }

      this.is_editing_order = !this.is_editing_order;
    },
    draggableStart: function() {
    },
    draggableEnd: function() {
      this.ordered_product_ids = this.favorites.map(f=>f.id);
      console.log(this.ordered_product_ids);
    },
    hasVariationsWithNeighborhood: function(index) { // indexの列全体がカラバリをもっているか。
      switch (index % 3) {
        case 0:
          return this.hasVariation(this.favorites[index]) 
            || this.favorites.length > index + 1 && this.hasVariation(this.favorites[index + 1]) 
            || this.favorites.length > index + 2 && this.hasVariation(this.favorites[index + 2]);
        case 1:
          return this.hasVariation(this.favorites[index - 1]) 
            || this.hasVariation(this.favorites[index]) 
            || this.favorites.length > index + 1 && this.hasVariation(this.favorites[index + 1]);
        case 2:
          return this.hasVariation(this.favorites[index - 2]) 
            || this.hasVariation(this.favorites[index - 1]) 
            || this.hasVariation(this.favorites[index]);
      }
    },
    hasVariation: function(favorite) { // カラバリをもっているか。
      return favorite && (favorite.product_variation_name || favorite.product_variation_image_url)
    },
    openMyStoreListModal() {
      this.$refs.my_store_list.openMyStoreListModal(); // 子のメソッド呼び出し
    },
    isInStock: function(favorite) { // 在庫有無
      return favorite.available;
    },
    backorderable: function(favorite) { // 取り寄せ可否
      return !this.isInStock(favorite) && favorite.backorderable;
    },
    selectProduct: function (product) {
      if (this.canShowVariation(product)) { // カウンセリング化粧品 or ビューティーケア
        this.$router.push({ 
          name: 'cosmetics_02', 
          params: { product_id: product.id },
        })
      } else {
        this.$router.push({ 
          name: 'product_detail_01', 
          params: { product_id: product.id },
        })
      }
    },
    backorder(product_id) {
      this.$router.push({ 
        name: 'backorder_02_1', 
        params: { 
          product_id: product_id,
          shopId: this.selectedMyshop.shop_code,
          shopName: this.selectedMyshop.shop_name,
        },
      })
    }, 
    setFavorites: function (info) {
      this.favorites = info.data;
      this.$root.hideLoading()
    },
    callbackDeleteFavorite(info) {
      console.log(info)
      this.favorites = this.favorites.filter(n => n.id !== this.removing_product_id)
      this.removing_product_id = null;
    },
    callGetFavoritesApi() {
      this.$root.showLoading()
      Api.getFavorites(this.setFavorites, this.$root.onDefaultFailureRequest);
    },
    clickRemove(product_id) {
      this.removing_product_id = product_id
      Api.deleteFavorite(
        product_id,
        this.callbackDeleteFavorite,
        this.$root.onDefaultFailureRequest
      );
    },
    callbackUpdateFavoritesOrder(info) {
      console.log(info)
    },
    updateFavoritesOrder(ordered_product_ids) {
      Api.updateFavoritesOrder(
        ordered_product_ids,
        this.callbackUpdateFavoritesOrder,
        this.$root.onDefaultFailureRequest
      );
    },
    setMyshops: function (info) {
      this.myshops = info.data;
      this.callGetFavoritesApi();
      this.$root.hideLoading()
    },
    callGetMyshopsApi() {
      this.$root.showLoading()
      Api.getMyshops(
        null,
        this.setMyshops,
        this.$root.onDefaultFailureRequest
      );
    },
    confirmAddToCart: function() {
      $('#warning-modal-01').fadeOut();
      this.addToCart();
    },
    cancelAddToCart: function() {
      this.selected_product_id = "";
      $('#warning-modal-01').fadeOut();
    },
    goToCart: function() {
      this.$router.push({ 
        name: 'cart_01', 
      });
    },
    clickAddToCart: function(product_id) {
      this.selected_product_id = product_id;
      if (this.$root.selectedShop === null || this.selectedMyshop.shop_code === this.$root.selectedShop.shop_code) {
        this.confirmAddToCart();
      } else {
        $('#warning-modal-01').fadeIn();
      }
    },
    callbackAddToCart: function () {
      this.$root.callGetProductsInCart();
      this.$root.hideLoading();
    },
    addToCart: function () {
      this.$root.showLoading();
      Api.addToCart(
        this.selectedMyshop.shop_code,
        this.selected_product_id,
        this.callbackAddToCart,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  watch: {
  },
  mounted() {

    this.$refs.main_content.oncontextmenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    this.callGetMyshopsApi();
    this.$root.callGetProductsInCart();
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  
  .mainContent {
    background: #f7f7f7 !important;
  }

  @media print, screen and (min-width: 1025px) {
    .mainContent .innerContent01 {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }

  .shiver {
    animation: shiver .2s  infinite;
  }
  @keyframes shiver {
    0% {transform: translate(0px, 0px) rotateZ(0deg)}
    25% {transform: translate(2px, 2px) rotateZ(1deg)}
    50% {transform: translate(0px, 2px) rotateZ(0deg)}
    75% {transform: translate(2px, 0px) rotateZ(-1deg)}
    100% {transform: translate(0px, 0px) rotateZ(0deg)}
  }

  .mainContent {
    .innerContent01 {
      .productBox01 {
        .productBoxInner01 {
          .productItem {
            .img {
              img {
                height: 100%;
                object-fit: contain;
                background-color: white;
              }
            }
          }
        }
      }
    }
  }

  // .editActionArea {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 2rem 0;
  //   &-btn {
  //     position: relative;
  //     width: 50%;
  //     height: 4rem;
  //     font-size: 1.6rem;
  //     line-height: 1.6rem;
  //     padding: 0.2rem 4rem;
  //     border-radius: 2rem;
  //     color: #fff;
  //     background-color: #707070;
  //     &-text-done, &-text-edit {
  //       &::before {
  //         position: absolute;
  //         top: 50%;
  //         left: 1.5rem;
  //         transform: translateY(-50%);
  //       }
  //     }
  //     &-text-done {
  //       &::before {
  //         content: url(../../assets/images/else/ico_check_circle.svg);
  //       }
  //     }
  //     &-text-edit {
  //       &::before {
  //         content: url(../../assets/images/else/ico_exchange.svg);
  //       }
  //     }
  //   }  
  // }
  // .mainContent {
  //   padding-top: 8.8rem;
  //   -webkit-touch-callout: none; // リンク長押しのポップアップを無効化
  //   .innerContent01 {
  //     padding-top: 0;
  //     .productBox01 {
  //       .productBoxInner01 { 
  //         .productItem {
  //           .title {
  //             height: 6.4rem;
  //             @media screen and (max-width: 850px) {
  //               height: 3.2rem;
  //             }
  //           }
  //           .color-wrapper {
  //             .color {
  //               border-radius: initial;
  //               background-color: initial;
  //             }
  //             p {
  //               height: 2rem;
  //               @media screen and (max-width: 850px) {
  //                 height: 1rem;
  //               }
  //             }
  //           }
  //           .ship-wrapper {
  //             .btnStock01 {
  //               margin: 0 1.5rem;
  //               &.none {
  //                 display: flex;
  //                 justify-content: center;
  //                 align-items: center;
  //                 width: 100%;
  //                 padding-top: 0;
  //                 line-height: 3rem;
  //                 background: initial;
  //                 background-color: #fff;
  //                 &:after {
  //                   content: "";
  //                   display: block;
  //                   width: 2rem;
  //                   height: 2.2rem;
  //                   background: #fff url(../../assets/images/common/ico_none.png) no-repeat bottom 0.5rem center/1.2rem;
  //                 }
  //               }
  //               &.stock {
  //                 display: flex;
  //                 justify-content: center;
  //                 align-items: center;
  //                 width: 100%;
  //                 padding-top: 0;
  //                 line-height: 3rem;
  //                 background: initial;
  //                 background-color: #fff;
  //                 &:after {
  //                   content: "";
  //                   display: block;
  //                   width: 2rem;
  //                   height: 2.2rem;
  //                   background: #fff url(../../assets/images/common/ico_ok.png) no-repeat bottom 0.5rem center/1.2rem;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         .itemWrapper {
  //           .productItem {
  //             .img {
  //               img {
  //                 height: 100%;
  //                 object-fit: contain;       
  //                 background-color: white;         
  //               }
  //             }
  //           }
  //           &:before {
  //             height: 5rem;
  //             background: linear-gradient(#D9C79D, #F2DEB2);
  //             bottom: 14rem;
  //             @media screen and (max-width: 850px) {
  //               height: calc(55 / 750 * 100vw);
  //               bottom: 85px;
  //             }
  //           }
  //           &:after {
  //             box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
  //           }
  //           &.sortable-drag {
  //             &:before, &:after {
  //               display: none;
  //             }
  //           }
  //           .ship-wrapper {
  //             .btnStock01 {
  //               margin: 0 1.5rem;
  //               &.none {
  //                 display: flex;
  //                 justify-content: center;
  //                 align-items: center;
  //                 width: 100%;
  //                 padding-top: 0;
  //                 line-height: 3rem;
  //                 background: initial;
  //                 background-color: #fff;
  //                 &:after {
  //                   content: "";
  //                   display: block;
  //                   width: 2rem;
  //                   height: 2.2rem;
  //                   background: #fff url(../../assets/images/common/ico_none.png) no-repeat bottom 0.5rem center/1.2rem;
  //                 }
  //               }
  //               &.stock {
  //                 display: flex;
  //                 justify-content: center;
  //                 align-items: center;
  //                 width: 100%;
  //                 padding-top: 0;
  //                 line-height: 3rem;
  //                 background: initial;
  //                 background-color: #fff;
  //                 &:after {
  //                   content: "";
  //                   display: block;
  //                   width: 2rem;
  //                   height: 2.2rem;
  //                   background: #fff url(../../assets/images/common/ico_ok.png) no-repeat bottom 0.5rem center/1.2rem;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .explanation {
  //     margin: 4rem;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     &__image {

  //     }
  //     &__arrow {
  //       margin: 1rem 0;
  //       width: 0;
  //       height: 0;
  //       border-left: 10px solid transparent;
  //       border-right: 10px solid transparent;
  //       border-top: 15px solid #0E318F;
  //     }
  //     &__desc {
  //       margin: 1rem 0;
  //       font-size: 1rem;
  //       color: #000;
  //     }
  //   }
  // }
  // .no-ship {
  //   .img {
  //     margin-bottom: -2.5rem;
  //   }
  // }

  // .img {
  //   width: 20rem;
  //   height: 20rem;
  //   @media screen and (max-width: 850px) {
  //     width: 10.4rem;
  //     height: 10.4rem;
  //   }
  //   .text02 {
  //     position: absolute;
  //     bottom: 0;
  //     right: 0;
  //     font-size: 1.2rem;
  //     background-color: #707070;
  //     color: #fff;
  //     padding: 0 0.75rem;
  //   }
  // }
  // .no_favorite {
  //   margin: 5rem auto;
  // }

  // .modal {
  //   &.warning-modal {
  //     .content {
  //       .content-info {
  //         position: relative;
  //         .btnClose{
  //           display: inline-block;
  //           width: 5rem;
  //           height: 5rem;
  //           border-radius: 50%;
  //           position: absolute;
  //           top: 2.5rem;
  //           right: 2.5rem;
  //           z-index: 2;
  //           cursor: pointer;
  //           background: url(../../assets/images/common/ico_none.png) no-repeat 50% 50%;
  //           background-color: #aaa;
  //           background-size: 2.0rem 2.0rem;
  //           filter: invert(1);
  //           @media screen and (max-width: 850px) {
  //             width: 6.6666666667vw;
  //             height: 6.6666666667vw;
  //             top: 10px;
  //             right: 10px;
  //             background-size: 2.4vw 2.4vw;
  //           }
  //           @media screen and (max-width: 850px) and (min-width: 768px) {
  //             width: 4vw;
  //             height: 4vw;
  //             background-size: 1.8vw 1.8vw;
  //           }
  //         }
  //         .btn-wrapper {
  //           .btn {
  //             background-color: #0086D5;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
</style>