<template>
  <div id="modalMajorCategory">
    <div id="modalCategory">
      <div id="categoryOverlay"></div>
      <p id="categoryModalClose01" @click="closeModal()">閉じる</p>

      <div class="mCatBox parent">
        <div class="mCatBoxInner">
          <!-- <p class="mTitle01 parent">{{ product_major_name }}</p> -->
          <ul class="mListCat parent">
            <li v-for="product_middle in this.valid_product_middles" :key="product_middle.id">
              <div v-if="product_middle.id !== special_kaigo_middle_id">
                <a href="javascript:void(0)"
                  class="btnModalCategory"
                  @click="selectMiddleCategory(
                    product_middle.id, 
                    product_middle.first_minor_id, 
                    product_middle.first_rack_set_id, 
                    product_middle.first_stand_no,
                    product_middle.use_product_minors)
                    ">
                  <span class="image">
                    <v-lazy-image
                      :src="$root.$baseApiURL + product_middle.product_image_url"
                      :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                      alt=""
                    />
                  </span>
                  <span class="text">
                    {{ product_middle.product_middle_name }}
                  </span>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <!-- お取り寄せ専用商品 -->
        <div v-for="product_middle in this.valid_product_middles" :key="product_middle.id">
          <button type="button" class="btnModalCategoryChange" v-if="product_middle.id === special_kaigo_middle_id" @click="selectMiddleCategory(
            product_middle.id, 
            product_middle.first_minor_id, 
            product_middle.first_rack_set_id, 
            product_middle.first_stand_no,
            product_middle.use_product_minors)">
            <span class="image">
              <v-lazy-image
                :src="$root.$baseApiURL + product_middle.product_image_url"
                :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                alt=""
              />
            </span>
            <span class="text">{{ product_middle.product_middle_name }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from "jquery";

export default {
  name: "MajorCategoryModal",
  components: {
  },
  props: {
    product_major_id: Number,
    product_major_name: String,
    tmp_product_major_id: Number,
  },
  data() {
    return {
      product_middles: [],

      special_kaigo_middle_id: 65, // お取り寄せ専用商品の中分類ID

      // 介護用品の分割
      category_2_1_ids: [13],
      category_2_2_ids: [11, 12, 65],

      // ホームケアの分割
      category_6_1_ids: [41],
      category_6_2_ids: [40, 43, 44, 42, 46, 9],
      category_6_3_ids: [20],
      category_6_4_ids: [47, 48],
    };
  },
  methods: {
    selectMiddleCategory(product_middle_id, product_minor_id, rack_set_id, stand_no, use_product_minors) {
      let query = {}
      if (this.$route.name === "product_detail_03") { // もともと棚画面にいる場合
        if (use_product_minors) {
          query = { 
            product_major_id: this.$route.query.product_major_id, 
            product_middle_id: this.$route.query.product_middle_id,
            product_minor_id: this.$route.query.product_minor_id,
            rack_set_id: this.$route.query.rack_set_id,
            stand_no: this.$route.query.stand_no,
            selected_product_middle_id: product_middle_id,
            selected_product_major_id: this.$props.product_major_id,
            modal: null, // モーダルありで開く
          }
        } else {
          query = { 
            product_major_id: this.$props.product_major_id, 
            product_middle_id: product_middle_id,
            product_minor_id: product_minor_id,
            rack_set_id: rack_set_id,
            stand_no: stand_no,
            can_cross_product_minors: null, // 小分類選択なしで開く
          }
        }
      } else {
        query = { 
          product_major_id: this.$props.product_major_id, 
          product_middle_id: product_middle_id,
          product_minor_id: product_minor_id,
          rack_set_id: rack_set_id,
          stand_no: stand_no,
        }
        if (use_product_minors) {
          query["modal"] = null // モーダルありで開く
        } else {
          query["can_cross_product_minors"] = null // 小分類選択なしで開く
        }
      }
      this.$router.push({ 
        name: 'product_detail_03', 
        query: query
      })

      $('#modalMajorCategory #modalCategory').fadeOut();
    },
    closeModal() {
      if (this.$route.name === "product_detail_03" ) {
        this.$router.push({ 
          name: 'product_detail_03', 
          query: { 
            product_major_id: this.$route.query.product_major_id, 
            product_middle_id: this.$route.query.product_middle_id,
            product_minor_id: this.$route.query.product_minor_id,
            rack_set_id: this.$route.query.rack_set_id,
            stand_no: this.$route.query.stand_no,
            can_cross_product_minors: this.$route.query.can_cross_product_minors,
          }
        })
      }
      $('#modalMajorCategory #modalCategory').fadeOut();
    },
    setProductMiddles: function (info) {
      if(this.tmp_product_major_id === null) {
        this.product_middles = info.data;
      } else if(this.tmp_product_major_id === 9) {
        this.product_middles = info.data.filter((product_middle) => this.category_6_1_ids.includes(product_middle.id))
      } else if(this.tmp_product_major_id === 10) {
        this.product_middles = info.data.filter((product_middle) => this.category_6_2_ids.includes(product_middle.id))
      } else if(this.tmp_product_major_id === 11) {
        this.product_middles = info.data.filter((product_middle) => this.category_6_3_ids.includes(product_middle.id))
      } else if(this.tmp_product_major_id === 12) {
        this.product_middles = info.data.filter((product_middle) => this.category_6_4_ids.includes(product_middle.id))
      } else if(this.tmp_product_major_id === 13) {
        this.product_middles = info.data.filter((product_middle) => this.category_2_1_ids.includes(product_middle.id))
      } else if(this.tmp_product_major_id === 14) {
        this.product_middles = info.data.filter((product_middle) => this.category_2_2_ids.includes(product_middle.id))
      }
      this.$root.hideLoading()
    },
    callProductMiddlesApi() {
      this.$root.showLoading()
      Api.getProductMiddles(this.product_major_id, this.setProductMiddles, this.$root.onDefaultFailureRequest);
    },
  },
  mounted() {
  },
  computed: {
    valid_product_middles() {
      return this.product_middles.filter((product_middle) => product_middle.first_minor_id !== null)
    }
  },
  watch: {
    "$props.product_major_id"() {
      if (this.$props.product_major_id !== 0) {
        this.callProductMiddlesApi()
      }
    },
    "$props.tmp_product_major_id"() {
      if (this.$props.tmp_product_major_id !== null) {
        this.callProductMiddlesApi()
      }
    },
  },

};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
</style>