<template>
  <div id="footMenuBox" ref="footMenuBox">
    <div id="footMenu">
      <p class="fSearch" ref="fSearch">
        <input
          type="text"
          placeholder="商品名・キーワード・JANコードで探す"
          v-model="keyword"
        /><button 
          class="fBtnSearch"
          v-on:click="gotoSearchResult()"
          >
          探す
        </button>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "SearchFooter",
  components: {
  },
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    gotoSearchResult() {
      this.$emit("gotoSearchResult")
      this.$router.push({
        name: "search_results_01",
        query: {
          keyword: this.keyword,
        },
      });      
      this.visible = false
    },
  },
  created () {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
</style>