import Vue from 'vue'
import Router from 'vue-router'
import Top from '../components/pages/Top'
import StoreList01 from '../components/pages/StoreList01'
import StoreMap01 from '../components/pages/StoreMap01'
import SearchResults01 from '../components/pages/SearchResults01'
import ProductDetail01 from '../components/pages/ProductDetail01'
import ProductDetail02 from '../components/pages/ProductDetail02'
import ProductDetail03 from '../components/pages/ProductDetail03'
import Backorder01_1 from '../components/pages/Backorder01_1'
import Backorder01_2 from '../components/pages/Backorder01_2'
import Backorder02_1 from '../components/pages/Backorder02_1'
import Backorder02_2 from '../components/pages/Backorder02_2'
import Backorder03 from '../components/pages/Backorder03'
import Backorder04 from '../components/pages/Backorder04'
import Backorder05 from '../components/pages/Backorder05'
import StoreSearch03 from '../components/pages/StoreSearch03'
import Faq01 from '../components/pages/Faq01'
import HowToUse01 from '../components/pages/HowToUse01'
import MyList01 from '../components/pages/MyList01'
import MyList03 from '../components/pages/MyList03'
import BackorderHistories01 from '../components/pages/BackorderHistories01'
import Cosmetics01 from '../components/pages/Cosmetics01'
import Cosmetics02 from '../components/pages/Cosmetics02'
import Cart01 from '../components/pages/Cart01'
import WhatIsThis01 from '../components/pages/WhatIsThis01'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

const routerReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error=> error)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      meta: { layout: 'top'},
      component: Top
    },
    {
      path: '/store_list/01',
      name: 'store_list_01',
      meta: { layout: 'storeList'},
      component: StoreList01,
    },
    {
      path: '/store_map/01',
      name: 'store_map_01',
      meta: { layout: 'storeMap'},
      component: StoreMap01
    },
    {
      path: '/search_results/01',
      name: 'search_results_01',
      meta: { layout: 'searchResults'},
      component: SearchResults01,
    },
    {
      path: '/product_details/03',
      name: 'product_detail_03',
      component: ProductDetail03,
    },
    {
      path: '/product_details/:product_id/01',
      name: 'product_detail_01',
      component: ProductDetail01,
    },
    {
      path: '/product_details/:product_id/02',
      name: 'product_detail_02',
      component: ProductDetail02,
    },
    {
      path: '/backorder/:product_id/01_1',
      name: 'backorder_01_1',
      meta: { layout: 'backorder'},
      component: Backorder01_1,
      props: true,
    },
    {
      path: '/backorder/:product_id/01_2',
      name: 'backorder_01_2',
      component: Backorder01_2,
    },    {
      path: '/backorder/02_1',
      name: 'backorder_02_1',
      meta: { layout: 'cart'},
      component: Backorder02_1,
      props: true,
    },
    {
      path: '/backorder/02_2',
      name: 'backorder_02_2',
      meta: { layout: 'cart'},
      component: Backorder02_2,
      props: true,
    },
    {
      path: '/backorder/03',
      name: 'backorder_03',
      meta: { layout: 'cart'},
      component: Backorder03,
      props: true,
    },
    {
      path: '/backorder/04',
      name: 'backorder_04',
      meta: { layout: 'cart'},
      component: Backorder04,
      props: true,
    },
    {
      path: '/backorder/05',
      name: 'backorder_05',
      meta: { layout: 'cart'},
      component: Backorder05,
      props: true,
    },
    {
      path: '/store_search/:product_id/03',
      name: 'store_search_03',
      meta: { layout: 'backorder'},
      component: StoreSearch03,
    },
    {
      path: '/faq/01',
      name: 'faq_01',
      meta: { layout: 'faq'},
      component: Faq01,
    },
    {
      path: '/how_to_use/01',
      name: 'how_to_use_01',
      meta: { layout: 'howToUse'},
      component: HowToUse01,
    },
    {
      path: '/my_list/01',
      name: 'my_list_01',
      meta: { layout: 'myList'},
      component: MyList01,
    },
    {
      path: '/my_list/03',
      name: 'my_list_03',
      meta: { layout: 'noHeader'},
      component: MyList03,
    },
    {
      path: '/backorder_histories/01',
      name: 'backorder_histories_01',
      meta: { layout: 'backorderHistories'},
      component: BackorderHistories01,
    },
    {
      path: '/cosmetics/01',
      name: 'cosmetics_01',
      component: Cosmetics01,
    },
    {
      path: '/cosmetics/:product_id/02',
      name: 'cosmetics_02',
      component: Cosmetics02,
    },
    {
      path: '/cart/01',
      name: 'cart_01',
      meta: { layout: 'cart'},
      component: Cart01,
    },
    {
      path: '/what_is_this/01',
      name: 'what_is_this_01',
      meta: { layout: 'whatIsThis'},
      component: WhatIsThis01,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!(from.query.mobile === undefined) && to.query.mobile === undefined) {
    next({
      path: to.path,
      query: Object.assign({}, to.query, { mobile: from.query.mobile }),
      hash: to.hash,
    })
  }
  if (!(from.query.fullscreen === undefined) && to.query.fullscreen === undefined) {
    next({
      path: to.path,
      query: Object.assign({}, to.query, { fullscreen: from.query.fullscreen }),
      hash: to.hash,
    })
  }
  next()
})


export default router