var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"mOrder"}},[_c('h1',{staticClass:"ttlOrder"},[_vm._v("商品を取り寄せる")]),_c('p',{staticClass:"btnOrder"},[(this.$route.params.product_id)?_c('router-link',{attrs:{"to":{
        name: 'product_detail_02',
        query: {
          product_major_id: _vm.$route.query.product_major_id,
          product_middle_id: _vm.$route.query.product_middle_id,
          rack_set_id: _vm.$route.query.rack_set_id,
          stand_no: _vm.$route.query.stand_no,
          row_no: _vm.$route.query.row_no,
          can_cross_product_minors: _vm.$route.query.can_cross_product_minors,
        },
      }}},[_c('span',[_vm._v("商品取り扱い"),_c('br'),_vm._v("ページに戻る")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }