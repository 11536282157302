<template>
  <div></div>  
</template>
<script>
import Api from "../../lib/api";
export default {
  name: "Backorder01_2",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    callbackAddToCart: function () {
      this.$root.callGetProductsInCart();
      this.$root.hideLoading();
      this.$router.replace({
        name: 'cart_01',
      });
    },
    addToCart: function () {
      this.$root.showLoading();
      Api.addToCart(
        this.$route.query.shop_id,
        this.$route.params.product_id,
        this.callbackAddToCart,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  mounted() {
    this.addToCart();

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
