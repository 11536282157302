<template>
  <div class="no_image_box" :class="major_name_class">
    <div class="product_middle_name" :class="is_no_product_name ? 'fill' : ''">
      <span class="name">{{ categoryName }}</span>
    </div>
    <div class="product_name" v-if="!is_no_product_name">
      {{ productName | ellipsis }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NoImage",
  props: {
    productName: String,
    productMajorId: Number,
    categoryName: String,
  },
  filters: {
    ellipsis(text) {
      return text.length >= 20 ? text.slice(0, 20 - "...".length) + "..." : text
    },
  },
  computed: {
    is_no_product_name() {
      return this.productName === undefined;
    },
    major_name_class() {
      switch (this.productMajorId) {
      case 1:
        return 'healthcare';
      case 2:
        return 'healthcare';
      case 3:
        return 'healthcare';
      case 4:
        return 'beautycare';
      case 5:
        return 'beautycare';
      case 6:
        return 'homecare';
      case 7:
        return 'food';
      default: 
        return 'no_major_id';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.healthcare {
  .product_middle_name {
    background-color: #8FCBE8;
  }
  .product_name {
    background-color: #EAF3F7;
  }
}
.beautycare {
  .product_middle_name {
    background-color: #E59D9D;
  }
  .product_name {
    background-color: #FFF0F0;
  }
}
.homecare {
  .product_middle_name {
    background-color: #AFD678;
  }
  .product_name {
    background-color: #EAF4DC;
  }
}
.food {
  .product_middle_name {
    background-color: #EACC79;
  }
  .product_name {
    background-color: #FAF2DC;
  }
}
.no_major_id {
  .product_middle_name {
    background-color: #999;
  }
}

.no_image_box {
  width: 100%;
  height: 100%;
  .product_middle_name {
    display:table;
    width: 100%;
    height: 36%;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    word-wrap:break-all;
    .name {
      display:table-cell;
      vertical-align:middle;
      word-wrap:break-all;
    }
    &.fill {
      height: 100%;
    }
  }
  .product_name {
    width: 100%;
    height: 64%;
    padding: 3% 8%;
    color: #555;
    font-size: 50%
  }
}
.fullscreen {
  .no_image_box {
    .product_middle_name {
      &.fill {
        font-size: 3.5rem;
      }
    }
  }
}

.mobile {
  .no_image_box {
    .product_middle_name {
      &.fill {
        font-size: 1.5rem;
      }
    } 
  }
}

</style>