<template>
  <div>
    <div class="itemInfo02">
      <div class="boxItemInner01">
        <div class="imageBox">
          <p>
            <v-lazy-image
              :src="product_image_url" alt=""
              :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
              v-if="product_image_url != ''"
            />
            <NoImage 
              :categoryName='categoryName(product)' 
              :productMajorId='product_major_id'
              v-else />
        </p>
        </div>
        <div class="txtBox">
          <p class="txtItem01">
            <span class="t01" v-if="product_item_name !== ''">{{ product_item_name }}</span>
            <span class="t02">{{ maker_name }}</span>
          </p>
          <p class="txtItem02">{{ product_name }}</p>
          <p class="txtPrice01">
            販売価格：<b>{{ price | numberFormat }}円</b><br>(税込：{{ taxed_price | taxedPrice }}円)
          </p>
          <p class="txtOrder04" v-if="lot > 1">最低注文数：{{ lot }}</p>
          <p class="txtOrder04" v-if="maximum_order_quantity !== 10" >こちらの商品のご注文は{{ lot * maximum_order_quantity }}個までです。</p>
          <p class="txtCaution01">※価格は店舗により異なる可能性があります。詳しくは店頭でご確認ください。</p>
          <p class="txtItem02">JANコード : {{ jan_code }}</p>
        </div>
        <Favorite :product_id="productId" :favorite="favorite" />
        <!-- <button type="button" class="myList"><span>マイリスト</span></button> -->
      </div>
      <p class="txtOrder01" v-if="showCurrentShop">お取り寄せ店舗：{{ this.shopName }}</p>
    </div>
  </div>
</template>


<script>
import Api from "../../lib/api";

import Favorite from "../parts/Favorite"
import NoImage from "../parts/NoImage"
import utils from "../../packs/mixins/utils.js";


export default {
  name: "BoxItem",
  components: {
    Favorite,
    NoImage,
  },
  mixins: [
    utils
  ],
  props: {
    title: String,
    productId: Number,
    shopName: String,
    showCurrentShop: Boolean,
    available: Boolean,
  },
  data() {
    return {
      product: null,
      product_name: "",
      maker_name: "",
      product_item_name: "",
      price: null,
      taxed_price: null,
      product_major_id: 0,
      product_middle_name: "",
      product_minor_name: "",
      product_image_url: "",
      lot: 1,
      maximum_order_quantity: 10,
      jan_code: "",
      favorite: false,
      reservable: true, // 取扱可否
    }
  },
  methods: {
    setProduct: function (info) {
      // 選択中の商品
      this.product = info.data;
      this.product_name = info.data.product_name;
      if (info.data.maker) {
        this.maker_name = info.data.maker.maker_name;
      }
      if (info.data.product_item) {
        this.product_item_name = info.data.product_item.item_name;
      }
      this.price = info.data.price;
      this.taxed_price = info.data.taxed_price;
      if (info.data.product_major) {
        this.product_major_id = info.data.product_major.id;
      }
      if (info.data.product_middle) {
        this.product_middle_name = info.data.product_middle.product_middle_name;
      }
      if (info.data.product_minor) {
        this.product_minor_name = info.data.product_minor.product_minor_name;
      }
      if (info.data.product_main_image_url) {
        this.product_image_url = this.$baseApiURL + info.data.product_main_image_url;
      }
      if (info.data.ext_link) {
        this.$emit('setExtLink', info.data.ext_link)
      }
      if (info.data.lot) {
        this.lot = info.data.lot
        this.$emit('setLot', info.data.lot)
      }
      if (info.data.restricted_maximum_order_quantity) {
        this.maximum_order_quantity = info.data.restricted_maximum_order_quantity
        this.$emit('setMaxOrderQuantity', info.data.restricted_maximum_order_quantity)
      }
      this.jan_code = info.data.jan_code;
      this.favorite = info.data.favorite;
      this.reservable = info.data.reservable;
      this.$emit('setReservable', info.data.reservable)
      this.$root.hideLoading()
    },
    callGetProductApi() {
      this.$root.showLoading()
      Api.getProduct(this.productId, this.setProduct, this.$root.onDefaultFailureRequest);
    },
  },
  watch: {
    "productId"() {
      if (this.productId !== 0) {
        this.callGetProductApi();
      }
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  // @import "../../assets/stylesheets/responsive/product_details_11.scss";
  // @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/store_search.scss";


  .itemInfo02 .boxItemInner01 .imageBox {
    width: 25rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .itemInfo02 .boxItemInner01 .imageBox {
      width: 12rem;
    }
  }
  @media screen and (max-width: 850px) {
    .itemInfo02 .boxItemInner01 .imageBox {
      width: calc(160 / 750 * 100vw);
    }
  }

  .itemInfo02 {
    // .txtItem02 {
    //   @media screen and (max-width: 850px) {
    //     font-size: calc(25 / 750 * 100vw);
    //   }
    // }
    .boxItemInner01 {
      .imageBox {
        height: 25rem;
        @media screen and (min-width: 1024px) and (max-width: 1366px) {
          height: 12rem;
        }
        @media screen and (max-width: 850px) {
          height: calc(160 / 750 * 100vw);
        }
        p {
          width: 100%;
          height: 100%;
          img {
            height: 100%;
            object-fit: contain;          
          }
        }
      }
    }
  }
</style>