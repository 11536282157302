<template>
  <div id="mStore_search">
    <h1 class="ttlStore_search">対象店舗</h1>
  </div>
</template>

<script>
export default {
  name: "StoreListHeader",
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_list.scss";
</style>