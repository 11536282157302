<template>
  <div>
    <p class="btnFavorite" :class="{ active: m_favorite }" @click.stop="clickFavorite"></p>
  </div>
</template>


<script>
import Api from "../../lib/api";

export default {
  name: "Favorite",
  components: {
  },
  props: {
    product_id: Number,
    favorite: Boolean,
  },
  data() {
    return {
      m_favorite: false,
    }
  },
  methods: {
    callbackPostFavorite(info) {
      console.log(info);
      this.m_favorite = true;
    },
    callbackDeleteFavorite(info) {
      console.log(info);
      this.m_favorite = false;
    },
    clickFavorite() {
      if (this.m_favorite) {
        Api.deleteFavorite(
          this.product_id,
          this.callbackDeleteFavorite,
          this.$root.onDefaultFailureRequest
        );
      } else {
        Api.postFavorite(
          this.product_id,
          this.callbackPostFavorite,
          this.$root.onDefaultFailureRequest
        );
      }
    }
  },
  watch: {
    "favorite"() {
      this.m_favorite = this.favorite
    }
  },
  mounted() {
    this.m_favorite = this.favorite
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/search_results.scss";
</style>