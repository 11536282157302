<template>
  <div class="pickup-wrapper">
    <div class="aid">
      現在食品の掲載はございません
      <div class="aid-button" :class="{ close : hidden_pickup }" @click="closePickUp"></div>
    </div>
    <div id="slider02" class="slideBox02" ref="slideBox02" v-show="!hidden_pickup">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide3">
          <img src="@/assets/images/else/map_bnr.png" alt="">
        </div>
        <div class="swiper-slide slide1">
          <div class="box">
            <div class="image"><img src="@/assets/images/else/img_home.png" alt=""></div>
            <div class="text">
              <p class="text__top">ホーム画面に追加！</p>
              <p class="text__middle">使いたいときにすぐにアクセスできます。</p>
              <p class="text__bottom">詳しく見る＞</p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide2">
          <div class="box modalMyList">
            <div class="image"><img src="@/assets/images/else/img_mylist.svg" alt=""></div>
            <div class="text">
              <p class="text__top">マイリストを活用しよう！</p>
              <p class="text__middle">マイリストを活用して、お買い物をもっと便利に！</p>
              <p class="text__bottom">詳しく見る＞</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Api from "../../lib/api";
import $ from "jquery";
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

export default {
  name: "PickUp",
  components: {
  },
  props: {
  },
  data() {
    return {
      hidden_pickup: false,
    };
  },
  methods: {
    closePickUp() {
      this.hidden_pickup = !this.hidden_pickup;
    },
    jquery() {
      const self = this
      // jQuery
      $(function(){
        new Swiper('#slider02', {
          speed: 600,
          loop: true,
          slidesPerView: 'auto',
          autoplay: {
            delay: 3000,
          },
        });

        $('.swiper-slide.slide1').click(function() {
          self.$router.push({
            name: 'how_to_use_01', // このサイトの使い方
          });
        });
        $('.swiper-slide.slide3').click(function() {
          self.$router.push({ 
            name: 'product_detail_03', 
            query: { 
              product_major_id: 1, 
              product_middle_id: 8,
              product_minor_id: 84,
              rack_set_id:  448,
              stand_no: 1,
              can_cross_product_minors: false,
            }
          })
        });
        // $('.swiper-slide.slide2').click(function() {
        //   self.$router.push({
        //     name: 'product_detail_01',
        //     params: { product_id: 4362 }, // ロハスメディカル 新型コロナ抗原検査キット １個
        //   });
        // });
        // $('.swiper-slide.slide3').click(function() {
        //   self.$router.push({
        //     name: 'product_detail_01',
        //     params: { product_id: 4362 }, // JOIN STAR 新型コロナウィルス 抗原検査キット 1回用
        //   });
        // });
        // $('.swiper-slide.slide4').click(function() {
        //   self.$router.push({
        //     name: 'product_detail_01',
        //     params: { product_id: 16092 }, // 新型コロナウイルスＰＣＲ検査 唾液採取用検査キット
        //   });
        // });
        // $('.swiper-slide.slide5').click(function() {
        //   self.$router.push({
        //     name: "search_results_01",
        //     query: {keyword: "花王11月まとめ買いキャンペーン"},
        //   });
        // });
      });
    }
  },
  mounted() {
    this.jquery()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
  .swiper-slide {
    cursor: pointer;

    &.slide3 {
      width: 290px;
      @media screen and (max-width: 850px) {
        width: 280px;
      }
    }
  }
</style>
