<template>
  <div>
    <div class="mainContent">
      <div class="innerContent01">
        <div class="bigImgWrapper zoom">
          <div class="bigImgInner">
          <!--追加タグ-->
            <div class="bigImg">
              <img src="../../assets/images/store_map/img_store_map01.png" alt="">

              <div class="modalBtnWrapper btn01 short" @click="selectProductMajor(3, 'ベビー', null)">
                <div class="dot"></div>
                <p>ベビーおむつ／<br class="pc">ベビー食品・用品</p>
              </div>
              <div class="modalBtnWrapper btn02 short" @click="selectProductMajor(1, 'ヘルスケア', null)">
                <div class="dot"></div>
                <p>風邪薬／胃腸薬／<br class="pc">目薬／健康食品</p>
              </div>
              <div class="modalBtnWrapper btn03 short" @click="selectProductMajor(2, '医療用品', 13)">
                <div class="dot"></div>
                <p>医療用品</p>
              </div>
              <div class="modalBtnWrapper btn10 short" @click="selectProductMajor(2, '介護用品', 14)">
                <div class="dot"></div>
                <p>介護用品</p>
              </div>

              <div class="modalBtnWrapper btn04 green short"  @click="selectProductMajor(6, 'ホームケア', 9)">
                <div class="dot"></div>
                <p>オーラル</p>
              </div>
              <div class="modalBtnWrapper btn05 green short" @click="selectProductMajor(6, 'ホームケア', 10)">
                <div class="dot"></div>
                <p>洗剤／家庭用品／防虫芳香／消耗品</p>
              </div>
              <div class="modalBtnWrapper btn06 green short" @click="selectProductMajor(6, 'ホームケア', 11)">
                <div class="dot"></div>
                <p>生理用品</p>
              </div>
              <div class="modalBtnWrapper btn09 green short" @click="selectProductMajor(6, 'ホームケア', 12)">
                <div class="dot"></div>
                <p>ペット／園芸</p>
              </div>

              <div class="modalBtnWrapper btn07 pink short" @click="selectBrandMajor()">
                <div class="dot"></div>
                <p>カウンセリング化粧品</p>
              </div>
              <div class="modalBtnWrapper btn08 pink short" @click="selectProductMajor(5, 'ビューテーケア', null)">
                <div class="dot"></div>
                <p>基礎化粧品／男性化粧品／ヘアケア</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MajorCategoryModal 
      :product_major_id="selected_product_major_id" 
      :product_major_name="selected_product_major_name" 
      :tmp_product_major_id="tmp_selected_product_major_id"
    />
    <MajorBrandModal 
      @setProductMiddle="setProductMiddle" 
    />
    <MiddleBrandModal 
      :productMiddleId="selected_product_middle_id"
      :productMiddleName="selected_product_middle_name" />
    <MyListModal />
  </div>
</template>
<script>
import MajorCategoryModal from "../parts/MajorCategoryModal"
import MajorBrandModal from "../parts/MajorBrandModal"
import MiddleBrandModal from "../parts/MiddleBrandModal"
import MyListModal from "../parts/MyListModal"

import $ from "jquery";
import gsap from "gsap";
import Draggable from "gsap/Draggable";

export default {
  name: "StoreMap01",
  components: {
    MajorCategoryModal,
    MajorBrandModal,
    MiddleBrandModal,
    MyListModal,
  },
  data() {
    return {
      selected_product_major_id: 0,
      selected_product_major_name: "",
      tmp_selected_product_major_id: null,

      // カウンセリング商品用
      selected_product_middle_id: 0,
      selected_product_middle_name: "",
    };
  },
  methods: {
    setProductMiddle(object) {
      this.selected_product_middle_id = object.id;
      this.selected_product_middle_name = object.product_middle_name;
    },
    selectBrandMajor() {
      // jQuery
      $('#modalCategory3').fadeIn();
    },
    selectProductMajor(id, name, tmp_id) {
      this.selected_product_major_id = id
      this.selected_product_major_name = name
      this.tmp_selected_product_major_id = tmp_id

      // jQuery
      $('#modalMajorCategory #modalCategory').fadeIn();
      // $('#modalMajorCategory').fadeIn();
    },
    zoom() {
      setTimeout(function() {
        $('.bigImgWrapper').removeClass('zoom').addClass('zoom02');
        setTimeout(function() {
          $('.modalBtnWrapper').animate({
            opacity: 1
          }, 'fast');
        }, 1000)
      }, 1000)
    },
    toggleDraggable() {
      let draggableInstance;
      const breakpoint = 1024;
      if (window.innerWidth > breakpoint) {
        // PC
        if (!draggableInstance) {
          gsap.registerPlugin(Draggable);
          draggableInstance = Draggable.create(".bigImgInner", {
            type: "x,y",
            // type: "x",
            // edgeResistance: 1.2,
            // bounds: window,
            bounds: ".bigImgWrapper",
            throwProps: true
          });
        }
      } else {
        // SP - Draggable無効化
        if (draggableInstance) {
          draggableInstance[0].kill();
          draggableInstance = null;
        }
      }
    },
    jquery() {
      this.zoom()
      this.toggleDraggable()

      $(function(){
        $(document).ready(function() {
          if ($(window).width() < 420) {
            $('.bigImgWrapper').scrollLeft($('.bigImgWrapper').width() * 2);
            $('.bigImgWrapper').scrollTop(250);
          }
          else {
            $('.bigImgWrapper').scrollLeft($('.bigImgWrapper').width());
            $('.bigImgWrapper').scrollTop(250);
          }
        })
      })
    }
  },
  mounted() {
    this.jquery()
  },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/store_map.scss";
  .mainContent .bigImgWrapper.zoom02 .modalBtnWrapper.btn10 {
    top: 53%;
    left: 68%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .mainContent .bigImgWrapper.zoom02 .modalBtnWrapper.btn10 {
      top: 71.5%;
      left: 56.5%;
    }
  }
  @media screen and (min-width: 1367px) {
    .mainContent .bigImgWrapper.zoom02 .modalBtnWrapper.btn10 {
      top: 53%;
      left: 68%;
    }
  }
  @media screen and (max-width: 850px) {
    .mainContent .bigImgWrapper.zoom02 .modalBtnWrapper.btn10 {
      top: 56.5%;
      left: 67.5%;
    }
  }
  @media screen and (max-width: 850px) and (min-width: 768px) {
    .mainContent .bigImgWrapper.zoom02 .modalBtnWrapper.btn10 {
      left: 68.5%;
      top: 57%;
    }
  }
</style>