<template>
  <div id="headMenu">
    <TitleHeader />
    <MyListHeader />
  </div>
</template>

<script>
import TitleHeader from '../parts/TitleHeader'
import MyListHeader from '../parts/MyListHeader'


export default {
  name: 'Header',
  components: {
    TitleHeader,
    MyListHeader,
  },
  mounted(){
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
</style>
