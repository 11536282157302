<template>
  <div>
    <HowToUseHeader />
    <router-view/>
  </div>
</template>

<script>
import HowToUseHeader from '../global/HowToUseHeader'

export default {
  name: 'HowToUseLayout',
  components: {
    HowToUseHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = '';
  },
}
</script>

<style scoped>
</style>
