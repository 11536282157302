<template>
  <div class="boxMapInfo">
    <div class="boxMapInfoName">
      <p class="shopName">
        <span class="name">
          <a v-bind:href="this.shop.site_url" target="_blank" class="icoBlank">{{
            this.shop.name
          }}</a>
        </span>
      </p>
      <p class="btnMapInfoClose"><a href="#">閉じる</a></p>
    </div>
    <div class="boxMapInfoInner">
      <div class="leftBox">
        <p class="shopText">
          〒{{ this.shop.zip_code }}<br />{{ this.shop.shop_address }}
        </p>
        <p class="shopTel">{{ this.shop.tel_no }}</p>
      </div>
      <div class="rightBox">
        <ul class="listBtn">
          <li class="btnStock01">
            <span class="outer">
              <span class="stock" v-if="shop.available">あり</span>
              <span class="none" v-else>なし</span>
            </span>
          </li>
          <li class="btnStock01"><a href="#" @click="openModal(shop)">詳細</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "StoreInfoBox",
  props: {
    shop: {
      shop_id: Number,
      position: { lat: Number, lng: Number },
      name: String,
      shop_address: String,
      zip_code: String,
      available: String,
      marker: Object,
      site_url: String,
    },
  },
  methods: {
    openModal() {
      this.$emit('openModal', this.shop)
    },
    jquery() {
      // jQuery
      $(function(){
        // var scrollpos = 0;
        $('.btnModal01').click(function(){
          $('#itemModal').fadeIn();

          // scrollpos = $('html, body').scrollTop();
          // $('html, body').addClass('freezePage').css({'top': scrollpos });  
          
          $('.itemModalBox02').find('.itemModalBoxInner01:eq(0)').show().addClass('active');
          var itemH = $('.itemModalBox02').find('.itemModalBoxInner01.active').outerHeight();
          $('.itemModalBox02').find('.itemModalBoxOuter01').css('height',itemH);
          
          return false;
        });

        $('.btnMapInfoClose').click(function(){
          $('.boxMapInfo').fadeOut();
          // $('html, body').removeClass('freezePage').css({'top': 0});
          // $('html, body').scrollTop(scrollpos);
        });
      })
    }
  },
  mounted() {
    this.jquery()
  }
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_search.scss";
</style>