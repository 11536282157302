<template>
  <div id="modalCategory02">
    <div id="categoryOverlay02"></div>
    <p id="categoryModalClose03" @click="closeModal()">閉じる</p>
    <div class="content">
      <div class="ttl">カラー／バリエーションを選択</div>
      <div class="item-list">
        <div class="item" data-name="text 1" data-price="1,000円" v-for="variation in $props.variations" :key="variation.id" @click="selectVariation(variation)">
          <div class="label">{{ variation.product_variation_name }}</div>
          <div class="img">
            <img :src="$baseApiURL + variation.product_variation_image_url" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "VariationModal",
  components: {
  },
  props: {
    variations: Array,
  },
  data() {
    return {
    };
  },
  methods: {
    closeModal() {
      $('#modalCategory02').fadeOut();
    },
    selectVariation :function(variation) {
      $('#modalCategory02').fadeOut();
      this.$router.push({
        name: 'cosmetics_02',
        params: {
          product_id: variation.product_id,
        },
        query: {
          product_major_id: this.$route.query.product_major_id,
          product_middle_id: this.$route.query.product_middle_id,
          product_minor_id: this.$route.query.product_minor_id,
        }}
      );
    },
  },
  watch: {
  },
  mounted() {
    console.log(this.$props.variations);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/cosmetics_details.scss";
</style>