<template>
  <div class="foot-wrapper">
    <SearchFooter />
    <PickUp :hasBackgroud="true" />
  </div>
</template>

<script>
import PickUp from "../parts/PickUp";
import SearchFooter from "../parts/SearchFooter.vue";

export default {
  name: "Footer",
  components: {
    PickUp,
    SearchFooter,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created () {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
</style>