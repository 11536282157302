<template>
  <div class="modal" id="modalCategory3">
    <div id="categoryOverlay"></div>
    <p id="categoryModalClose01" class="modal-close" @click="closeModal()">閉じる</p>
    <div class="mCatBox">
      <div class="mCatBoxInner">
        <p class="mTitle01">カウンセリング化粧品</p>
        <ul class="mListCat">
          <li v-for="product_middle in product_middles"
            :key="product_middle.id">
            <a href="javascript:void(0)" class="btnModalCategory" @click="selectBrandMiddle(product_middle)">
              <img :src="$root.$baseApiURL + product_middle.product_image_url" alt="">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from "jquery";

export default {
  name: "MajorBrandModal",
  components: {
  },
  props: {
  },
  data() {
    return {
      product_middles: [],
    };
  },
  methods: {
    selectBrandMiddle(product_middle) {
      this.$emit('setProductMiddle', product_middle);

      $('#modalCategory3').fadeOut();
      $('#modalCategory4').fadeIn();
    },
    closeModal() {
      $('.modal').fadeOut();
    },
    setProductMiddles: function (info) {
      this.product_middles = info.data;
      this.$root.hideLoading()
    },
    callProductMiddlesApi() {
      this.$root.showLoading()
      Api.getProductMiddles(4, this.setProductMiddles, this.$root.onDefaultFailureRequest); // product_major_id :4(カウンセリング化粧品)
    },
  },
  mounted() {
    this.callProductMiddlesApi();
  },
  computed: {
  },
  watch: {
  },

};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
</style>