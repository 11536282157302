<template>
  <div>
    <div id="itemModal">
      <div id="itemOverlay" @click="closeModal"></div>
      <p id="itemModalClose" @click="closeModal">閉じる</p>
      <div class="itemModalOuter">
        <div class="itemModalInner">
          <div class="itemModalBox01">
            <p class="txtItemModal01">商品の画像</p>
            <p class="txtItemModal02">
              （タップすると大きな画像が表示されます）
            </p>
            <div id="itemModalSlider">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(product_image, index) in this.product_images"
                  :key="product_image.id"
                >
                  <a href="#" class="btnModalImage" :data-index="index"
                    ><img
                      v-bind:src="
                        $root.$baseApiURL + product_image.product_image_url
                      "
                      alt=""
                  /></a>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="itemModalInner02">
            <ul class="tab" v-if="hasDetail || hasPdf">
              <li class="active" v-if="hasDetail"><a href="javascript:void(0)" data-index="0">商品の説明</a></li>
              <li v-if="hasPdf"><a href="javascript:void(0)" data-index="1">添付文書</a></li>
              <!-- <li><a href="#" data-index="2">お役立ち情報</a></li> -->
            </ul>
            <div class="itemModalBox02" v-if="hasDetail || hasPdf">
              <div class="itemModalBoxOuter01">
                <div class="itemModalBoxInner01">
                  <h2 class="ttlItemModal01" v-if="hasDetail">商品説明</h2>
                  <p class="txtItemModal03" v-html="product_detail" v-if="hasDetail"></p>
                </div>
                <div
                  id="pdfBox"
                  class="itemModalBoxInner01"
                >
                  <div class="flexBox" v-if="hasPdf">
                    <p class="btnPDF">
                      <a
                        :href="$root.$baseApiURL + product_pdf_url"
                        target="_blank"
                        ><span>添付文書を見る</span></a
                      >
                    </p>
                  </div>
                </div>
                <!-- <div class="itemModalBoxInner01" v-if="this.product_id % 2 === 0">
                  <h2 class="ttlItemModal02">
                    面倒な食器汚れは“泡”で攻めよう！  食器用泡スプレー
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_1.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                    スポンジでは洗いにくいマイボトルや子供用のストローマグ、お弁当箱、プロテインシェイカーなど、凸凹の多い食器やフライパンなどの油汚れ。現在は、テレワークや外出自粛で自宅での食事の機会が増えている人も多いはず。さらに家族の分までとなると、これまでよりも食器洗いの負担が増えていると感じるのではないでしょうか。
                    <br/>
                    <br/>
                    そこでおすすめしたいのが、スプレーしてしばらく置くだけで汚れが落とせる、食器用泡スプレーです。
                  </p>

                  <h2 class="ttlItemModal02">
                    スプレーして数分後に洗い流すだけ！
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_2.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                    食器用泡スプレーの基本的な使い方は、食器や調理器具に直接スプレーしてしばらく置いてから、水で洗い流すだけ。落ちやすい汚れはスポンジを使わなくても汚れを落とせるため、手荒れの心配も減りそうですね。
                  </p>
                  <h2 class="ttlItemModal02">
                    キッチンにプラスして、「おうち時間」をもっとラクに！
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_3.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                    食事が終わったらすぐに食器洗いも終わらせるのが理想ですが、実際には、「子供の世話がある」「観たいドラマが始まる時間」「仕事のメールを先に返したい」と、食器洗いよりも優先したい、優先しなければいけないいことも。そんなときも、シンクに置いた食器にサッとスプレーしておけば、汚れのこびりつきを防いでくれますよ。
                    <br/>
                    <br/>
                    食器用泡スプレーを、今日からキッチンにプラスして、「おうち時間」をもっとラクに過ごしましょう！
                  </p>
                </div>
                <div class="itemModalBoxInner01" v-else>
                  <h2 class="ttlItemModal02">
                    これからのキズは「モイストヒーリング」で治そう！
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_4.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                  うっかり切ったり、転んで擦りむいたり、靴擦れしたり。よくあるキズも、使う絆創膏を変えるだけで、痛みを和らげながら、きれいに治せるのをご存知ですか？
                  <br/>
                  <br/>
                  キズが乾いてかさぶたができるのが普通のキズの治り方で、専門的には「ドライヒーリング」と呼びます。しかし、デコボコしたかさぶたによって表皮の形成が妨げられ、治りが遅く、キズ痕も残りやすいというデメリットがあります。
                  <br/>
                  <br/>
                  一方、半透明のシート状の「ハイドロコロイド素材」の絆創膏は、洗い流した傷口を密閉して体液を保持することで、痛みをやわらげ、きれいに早く治せる「モイストヒーリング（湿潤療法）」を目的として作られているもの。皮膚にピッタリ密着して体液を吸収、ゲル状に膨らむ性質をもっています。ばい菌を殺したり、キズを修復する成分を含む体液を保持することで表皮の形成を促し、キズを早く、キレイに治せるんです。
                  </p>
                  <h2 class="ttlItemModal02">
                    「モイストヒーリング」で痛みが和らぐのはなぜ？
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_5.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                    キズの痛みは、傷口が空気や衝撃などの刺激にさらされることでも強く感じるため、キズを密閉することで痛みが和らぐというメリットもあります。擦りキズや靴擦れなど面のキズは痛みを強く感じやすいので、ハイドロコロイド素材の絆創膏でのケアがおすすめです。
                  </p>
                  <h2 class="ttlItemModal02">
                    キズによって絆創膏を使い分けよう！
                  </h2>
                  <img
                    class="imgItemModal01"
                    src="../../assets/images/else/img_sample_useful_6.png"
                    alt=""
                  />
                  <p class="txtItemModal03">
                    パッドとテープを組み合わせた従来の絆創膏は、ちょっとしたキズの保護に。痛みのある切りキズや擦りキズ、靴擦れ、目立つところにあるキズを、早く治したい時は、ハイドロコロイド素材の絆創膏を使うのがおすすめです。ハイドロコロイド素材の絆創膏は、キズ口が乾いていない状態で使いはじめるのがポイントなので、ストックしておくと、いざという時に助かりますよ。
                    <br/>
                    <br/>
                    ただし、ハイドロコロイド素材の絆創膏は、傷口を密閉して、体液を利用して治癒を促すという特徴から、①感染源となるゴミや砂などを事前にしっかり洗い流す、②体液のはたらきを妨げる消毒液は使わない、③深いキズ、感染の恐れのあるキズには使わない、④順調に治っているか経過を観察するといった注意点を守る必要があります。上手に使って、ケガを快適に、早く治しましょう！
                  </p>
                </div> -->
              </div>
            </div>
            <div class="itemModalBox03">
              <ul class="listItem01">
                <li
                  v-for="product_image in this.product_images"
                  :key="product_image.id"
                >
                  <img
                    v-bind:src="
                      $root.$baseApiURL + product_image.product_image_url
                    "
                    alt=""
                  />
                </li>
              </ul>
              <p id="listItemClose"><a href="#">閉じる</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
// import Swiper from "swiper";

export default {
  name: "ItemModal",
  props: {
    product_id: Number,
    product_images: Array,
    product_detail: String,
    product_pdf_url: String,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    },
    jquery() {

      // jQuery
      $(function () {
        $('.tab a').click(function(){
          if(!($(this).parent().hasClass('active'))){
            var currentIndex = $(this).attr('data-index');
            
            
            $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').siblings().removeClass('active').fadeOut(500);
            
            var itemH = $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').outerHeight();
            $('.itemModalBox02').find('.itemModalBoxOuter01').css('height',itemH);
            $('.itemModalBox02').animate({scrollTop: '0'},0);
            $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').fadeIn();
            
            $(this).parent().addClass('active').siblings().removeClass('active');
            
            var isPDF = $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').attr('data-pdf');
            var pdfBox = $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')');
            
            if(isPDF){
              if(!(pdfBox.hasClass('setPDF'))){
                pdfBox.addClass('setPDF').find('iframe').attr('src',isPDF);
              }
            }
          }
          
          return false;
        });
        
        
        $('.btnModalImage').click(function(){
          if(!($(this).parent().hasClass('active'))){
            var currentIndex = $(this).attr('data-index');
            
            $('.itemModalBox02').fadeOut();
            $('.itemModalBox03').fadeIn();
            $('.itemModalBox03').find('.listItem01').find('li').fadeOut();
            $('.itemModalBox03').find('.listItem01').find('li:eq('+currentIndex+')').fadeIn();
            
            $(this).parent().addClass('active').siblings().removeClass('active');
          }
          
          return false;
        });
        
        $('#listItemClose').click(function(){
          
          $('.itemModalBox02').fadeIn();
          $('.itemModalBox03').fadeOut();
          $('.itemModalBox03').find('.listItem01').find('li').fadeOut();
          
          $('.btnModalImage').parent().removeClass('active').siblings().removeClass('active');
          
          return false;
        });
      });
    }
  },
  watch: {
    "product_images"() {
      // prodcut_imagesが揃ったら、jquery実行
      this.jquery()
    },
  },
  computed: {
    hasDetail() {
      return this.product_detail !== null
    },
    hasPdf() {
      return this.product_pdf_url !== ""
    },
    pdfPath() {
      return encodeURIComponent(this.$root.$baseApiURL + this.product_pdf_url);
    }
  },
  mounted() {
    this.jquery()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '';
  }
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: '';
  }
  #itemModalSlider {
    .btnModalImage {
      height: 100%;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  #itemModal{
    .listItem01 {
      @media print, screen and (min-width: 1025px) {
        margin: 0;
        padding: 50px;
      }
      // box-sizing: border-box;
      height: 100%;
      li {
        height: 100%;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }


  // もともとposition: absoluteが指定されているがこれがあると2枚目、4枚目、6枚目..の画像が右にずれるため初期化
  #itemModal {
    .listItem01 {
      li:nth-child(n + 2) {
        position: initial;
        top: initial;
      }
    }
  }
</style>