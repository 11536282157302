<template>
  <div class="gray-box">
    <div class="gray-box-inner"><!--タグ追加-->
      <div class="ttl"></div>
      <div class="no_product" v-if="productsInCart.length === 0">
        現在お取り寄せ一覧に商品はありません。
      </div>
      <div class="boxItem01-wrapper"><!--タグ追加-->
        <div class="boxItem01" v-for="product_in_cart in productsInCart" :key="product_in_cart.id">
          <div class="boxItemInner01">
            <div class="imageBox">
              <v-lazy-image
                :src="$baseApiURL + product_in_cart.product_main_image_url" alt=""
                :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                v-if="product_in_cart.product_main_image_url != ''"
              />
              <NoImage 
                :categoryName='categoryName(product_in_cart.product)' 
                :productMajorId='product_in_cart.product.product_major_id'
                v-else />
              <div class="info">{{ product_in_cart.product.regulation }}</div>
            </div>
            <div class="txtBox">
              <div class="product_tag">
                <!-- <img src="../../assets/images/backorder/product_tag.png" alt=""> -->
              </div>
              <p class="txtItem02">{{ product_in_cart.product.product_name }}</p>
              <p class="txtPrice01">販売価格：<strong class="en">{{ product_in_cart.product.price | numberFormat }}円</strong><br> (税込：{{ product_in_cart.product.taxed_price | taxedPrice }}円)</p>
            </div>
            <div class="quality-block">
              <span v-if="canEdit">数量：
                <select v-model="product_in_cart.number" @change="editProductInCart(product_in_cart, product_in_cart.number)">
                  <option v-for="n of product_in_cart.product.restricted_maximum_order_quantity" :value="n * product_in_cart.product.lot" :key="n">
                    {{ n * product_in_cart.product.lot }}
                  </option>
                </select>
              個</span>
              <span v-else>数量：{{ product_in_cart.number }}個</span>
            </div>
            <div class="btn-wrapper" v-if="canEdit">
              <div v-if="!is_no_header" class="btn blue" @click="selectProduct(product_in_cart.product)">詳しく見る</div>
              <div class="btn" @click="removeProductInCart(product_in_cart)">一覧から削除</div>
            </div>
          </div>
        </div>
      </div>
      <router-link class="boxItem01 empty" :to="{ name: 'store_map_01' }" v-if="canEdit && !is_no_header">
        <div class="boxItemInner01">
          <span>他にも商品を追加する<img src="../../assets/images/common/ico_add.png" alt=""></span>
        </div>
      </router-link>
      <div class="boxInfo01 no-bg" v-if="canEdit && productsInCart.length">
        <p class="btnRed01" :class="{disabled : productsInCart.length === 0}">
          <a href="javascript:void(0)" @click="backorder">
            店舗に取り寄せる
          </a>
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import Api from "../../lib/api";
import NoImage from "../parts/NoImage"
import utils from "../../packs/mixins/utils.js";

export default {
  name: "CartBox",
  components: {
    NoImage,    
  },
  mixins: [
    utils
  ],
  props: {
    productsInCart: Array,
    canEdit: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    selectProduct(product) {
      if (this.canShowVariation(product)) { // カウンセリング化粧品 or ビューティケア
        this.$router.push({ 
          name: 'cosmetics_02', 
          params: { product_id: product.id },
        })
      } else {
        this.$router.push({ 
          name: 'product_detail_01', 
          params: { product_id: product.id },
        })
      }
    },
    backorder() {
      if (this.productsInCart.length === 0) {
        return;
      }
      this.$router.push({ 
        name: 'backorder_02_1',
        query: Object.assign({},this.$route.query),
      });
    },
    callbackRemoveProductInCart: function () {
      this.$root.hideLoading();

      this.$emit('getProductsInCart');
    },
    removeProductInCart(product_in_cart) {
      this.$root.showLoading();
      Api.removeFromCart(product_in_cart.id, this.callbackRemoveProductInCart, this.$root.onDefaultFailureRequest)
    },
    callbackEditProductInCart: function () {
      this.$root.hideLoading();

      // this.$emit('getProductsInCart');
    },
    editProductInCart(product_in_cart, quality) {
      this.$root.showLoading();
      Api.editInCart(product_in_cart.id, quality, this.callbackRemoveProductInCart, this.$root.onDefaultFailureRequest)
    },
  },
  watch: {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  .no_product {
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .mainContent {
    .boxItem01 {
      .boxItemInner01 {
        .imageBox {
          img {
            height: 100%;
            object-fit: contain;          
          }
        }
      }
    }
  }
</style>