<template>
  <div id="dMenu" aria-hidden="true">
    <div id="dMenuList">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="product_minor in product_minors" :key="product_minor.id">
          <a href="javascript:void(0)" @click="selectBrandMinor(product_minor)">
            <img :src="$root.$baseApiURL + product_minor.product_image_url" alt="" v-if="product_minor.product_image_url !== ''">
            <div v-else>{{ product_minor.product_minor_name }}</div> <!-- 暫定 -->
          </a>
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import $ from "jquery";
import Swiper from "swiper";

let swiper;

export default {
  name: "MinerBrandHeader",
  props: {
  },
  data() {
    return {
      product_minors: [],
    };
  },
  methods: {
    selectBrandMinor: function (product_minor) {
      this.$router.push({ 
        name: 'cosmetics_01', 
        query: {
          product_major_id: 4, // product_major_id :4(カウンセリング化粧品)
          product_middle_id: this.$route.query.product_middle_id,
          product_minor_id: product_minor.id,
        }
      });
    },
    setProductMinors: function (info) {
      this.product_minors = info.data;

      var index = this.product_minors.findIndex (
        (item) => item.id == this.$route.query.product_minor_id
      )
      this.jquery(index);
    },
    callProductMinorsApi() {
      Api.getProductMiners(this.$route.query.product_middle_id, null, this.setProductMinors, this.$root.onDefaultFailureRequest);
    },
    jquery(index) {
      var self = this;
      $(function () {
        if (swiper === undefined) {
          swiper = new Swiper('#dMenuList', {
            speed: 600,
            loop: false, // trueにしたいが色々ずれるので暫定でfalse
            centeredSlides: true,
            slidesPerView: 'auto',
            initialSlide: index,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
          });
          swiper.on('slideChange', function () {
            self.$router.push({ 
              name: 'cosmetics_01', 
              query: {
                product_major_id: 4, // product_major_id :4(カウンセリング化粧品)
                product_middle_id: self.$route.query.product_middle_id,
                product_minor_id: self.product_minors[swiper.realIndex].id,
              }
            });
          });
        } else {
          swiper.slideTo(index)
        }
      })
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.query.product_middle_id !== to.query.product_middle_id) {
        swiper = undefined;
        this.product_minors = [];
        this.callProductMinorsApi();
      } else if (from.query.product_minor_id !== to.query.product_minor_id) {
        if(swiper === undefined) { // swiperがインスタンス化前に呼ばれることがあるため事前チェック
          return;
        }
        const index = this.product_minors.findIndex((product_minor) => product_minor.id == to.query.product_minor_id)
        if (swiper.realIndex !== index) {
          swiper.slideTo(index);
        }
      }
    },
  },
  mounted() {
    // swiperを初期化
    swiper = undefined;

    this.callProductMinorsApi();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/cosmetics.scss";
  
  // ブランドロゴがなくテキストでブランド名を表示する場合の調整
  #dMenu {
  .swiper-slide {
    a {
      div {
        height: 5rem;
        line-height: 5rem;
      }
    }
  }
}
</style>
