import { render, staticRenderFns } from "./FaqHeader.vue?vue&type=template&id=1eafe106&scoped=true&"
import script from "./FaqHeader.vue?vue&type=script&lang=js&"
export * from "./FaqHeader.vue?vue&type=script&lang=js&"
import style0 from "./FaqHeader.vue?vue&type=style&index=0&id=1eafe106&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eafe106",
  null
  
)

export default component.exports