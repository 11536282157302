<template>
  <div>
    <BackorderHistoriesHeader />
    <router-view/>
  </div>
</template>

<script>
import BackorderHistoriesHeader from '../global/BackorderHistoriesHeader'

export default {
  name: 'BackorderHistoriesLayout',
  components: {
    BackorderHistoriesHeader,
  },
  data() {
    return {};
  },
}
</script>

<style scoped>
</style>
