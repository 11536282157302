<template>
  <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
    <div class="innerContent01">
      <div class="gray-box"></div>
      <div class="boxInfo01">
        <h2 class="ttlInfo01">注文完了</h2>
        <div class="info10">
          <p class="icoCheck">
            <img src="../../assets/images/common/ico_check.png" alt="" />
          </p>
          <p class="txtInfo06">お取り寄せのご要望を受け付けました。<br>お取り寄せ内容はメニューの注文履歴からご確認ください。<br>商品の入荷が確認出来ましたら、ご登録頂いた<br>携帯電話のSMSにご連絡させていただきます。</p>
          <p class="txtInfoNote">※商品の到着にはお時間を頂く場合があります。予めご了承ください。</p>
        </div>
        <div class="info08">
          <ul class="listBtn">
            <li class="btnBack02">
              <a v-if="$route.query.redirect" :href="this.$route.query.redirect">戻る</a>
              <router-link v-else :to="{ name: 'top' }">TOPに戻る</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../../packs/mixins/utils.js";

export default {
  name: "Backorder05",
  components: {
  },
  mixins: [utils],
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>
