<template>
  <router-view/>
</template>

<script>

export default {
  name: 'NoHeaderLayout',
  components: {
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = '';
  },
}
</script>

<style scoped>
</style>
