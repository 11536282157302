<template>
  <div>
    <div id="mMenu">
      <div id="mMenuList">
        <div class="swiper-wrapper">
          <div
            :class="itemStyle(product_major.id)"
            v-for="product_major in this.product_majors"
            :key="product_major.id"
          >
            <a href="javascript:void(0)" @click="selectProductMajor(product_major.id, product_major.product_major_name)">
              {{ product_major.product_major_name }}
            </a>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <MajorCategoryModal 
      :product_major_id="selected_product_major_id" 
      :product_major_name="selected_product_major_name"
      :tmp_product_major_id="null"
    />
    <MajorBrandModal 
      @setProductMiddle="setProductMiddle" 
    />
  </div>
</template>

<script>
import $ from "jquery";
import Swiper from "swiper";
import MajorCategoryModal from "../parts/MajorCategoryModal"
import MajorBrandModal from "../parts/MajorBrandModal"

var swiper;

export default {
  name: "MajorCategoryHeader",
  components: {
    MajorCategoryModal,
    MajorBrandModal,
  },
  data() {
    return {
      selected_product_major_id: 0,
      selected_product_major_name: "",
      product_majors: [
        {
          id: 1,
          product_major_name : "ヘルスケア"
        },
        {
          id: 2,
          product_major_name : "衛生介護"
        },
        {
          id: 3,
          product_major_name : "ベビー"
        },
        {
          id: 4,
          product_major_name : "カウンセリング化粧品"
        },
        {
          id: 5,
          product_major_name : "ビューティケア"
        },
        {
          id: 6,
          product_major_name : "ホームケア"
        },
      ],
    };
  },
  methods: {
    itemStyle: function(id) {
      if (id == this.$route.query.product_major_id) {
        return "mMenuCurrent swiper-slide btnModalMajorCategory"
      } else {
        return "swiper-slide btnModalMajorCategory"
      }
    }, 
    selectProductMajor(id, name) {
      this.selected_product_major_id = id
      this.selected_product_major_name = name
      
      if (id === 4) { // カウンセリング化粧品のとき
        $('#modalCategory3').fadeIn();
      } else {
        // jQuery
        $('#modalMajorCategory #modalCategory').fadeIn();
      }
    },
    setProductMiddle(object) {
      var newQuery = Object.assign({}, this.$route.query); // 新しいqueryを生成
      newQuery.selected_product_major_id = object.product_major_id; // クエリパラメタにselected_product_middle_idを追加
      newQuery.selected_product_middle_id = object.id; // クエリパラメタにselected_product_middle_idを追加
      newQuery.modal = null; // クエリパラメタにmodalを追加
      this.$router.push({query: newQuery});
    },
    jquery(index) {
      // jQuery
      $(function () {
        $("#mMenuCurrent").index();
        swiper = new Swiper("#mMenuList", {
          speed: 600,
          loop: false,
          centeredSlides: true,
          slidesPerView: "auto",
          initialSlide: index,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    }
  },
  mounted() {
    // swiperを初期化
    swiper = undefined;


    var index = this.product_majors.findIndex (
      (item) => item.id == this.$route.query.product_major_id
    )
    this.jquery(index);
  },
  watch: {
    "$route.query.product_major_id"() {
      var index = this.product_majors.findIndex (
        (item) => item.id == this.$route.query.product_major_id
      )
      swiper.slideTo(index, false, false);    
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '';
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '';
}
  @import "../../assets/stylesheets/responsive/base.scss";
</style>
