<template>
  <div>
    <div id="headMenu">
      <TitleHeader />
    </div>
    <SearchResultsHeader />
  </div>
</template>

<script>
import TitleHeader from '../parts/TitleHeader'
import SearchResultsHeader from '../parts/SearchResultsHeader'


export default {
  name: 'Header',
  components: {
    TitleHeader,
    SearchResultsHeader,
  },
  mounted(){
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
</style>
