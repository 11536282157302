<template>
  <div id="dMenu">
    <div id="dMenuList" class="dMenuList">
      <p>{{ breadcrumb }}</p>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";

export default {
  name: "BreadcrumbHeader",
  data() {
    return {
      product_id: 0,
      product_major: null,
      product_middle: null,
      product_miner: null,
    };
  },
  computed: {
    breadcrumb: function () {
      return (this.product_major ? this.product_major.product_major_name : "")
        + (this.product_middle ? ( "> " + this.product_middle.product_middle_name) : "")
        + (this.product_minor ? ( "> " + this.product_minor.product_minor_name) : "");
    },
  },
  methods: {
    setProduct: function (info) {
      // 選択中の商品
      this.product_major = info.data.product_major;
      this.product_middle = info.data.product_middle;
      this.product_miner = info.data.product_minor;
    },
    callProductApi() {
      Api.getProduct(this.product_id, this.setProduct, this.$root.onDefaultFailureRequest);
    },
  },
  watch: {
    "$route.params.product_id"() {
      this.product_id = Number(this.$route.params.product_id);
      this.callProductApi();
    },
  },
  mounted() {
    this.product_id = Number(this.$route.params.product_id);
    this.callProductApi();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";
  .dMenuList p {
    font-size: 13px;
    padding: 10px 0;
  }
</style>