import moment from 'moment'

export default {
  filters: {
    year(date) {
      return moment(date).format('YYYY年');
    },
    month_day(date) {
      return moment(date).format('M/D');
    },
    year_month_day(date) {
      return moment(date).format('YYYY年MM月DD日');
    },
  },
  computed: {
    is_no_header() {
      return this.$route.query.no_header === null;
    }
  },
  methods: {
    // startParse: function(start) {
    //   return moment(start).format('YYYY-MM-DD')
    // },
    // endParse: function(end) {
    //   return moment(end).format('YYYY-MM-DD')
    // },
    categoryName :function(product) {
      return product ? (product.product_minor ? product.product_minor.product_minor_name : product.product_name) : "";
    },
    canShowVariation(product) {
      return product.product_major_id === 4 || product.product_major_id === 5;
    },
    canShowVariationImage(product) {
      return product.product_variation_image_url !== '';
    },
  }
}