<template>
  <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
    <SelectedStoreBox :selectedShop='this.$root.selectedShop'/>
    <div class="innerContent01">
      <div class="gray-box-column"><!--タグ追加-->
        <CartBox :productsInCart='this.$root.productsInCart' @getProductsInCart='this.$root.callGetProductsInCart' />
        <div class="info">
          <p class="txtInfo">下記商品のお取り寄せを希望される場合は<br/>携帯電話のSMS機能からお申し込みください。</p>
        </div>
        <div class="boxInfo01-wrapper"><!--タグ追加-->
          <div class="boxInfo01">
            <h2 class="ttlInfo01">お客様情報の登録</h2>
            <div class="info04">
              <p class="txtInfo09">※こちらの商品は3〜5営業日で入荷予定です。</p>
              <p class="txtInfo03">ご注文の確認用の連絡先を登録してください。</p>
            </div>
            <div class="info05">
              <h3 class="ttlInfo03">携帯電話番号</h3>
              <p class="inputInfo01">
                <input
                  type="text"
                  placeholder="09012345678（ハイフン無し）"
                  v-model="tel"
                />
              </p>
              <p class="error" v-if="this.messageTelError.length">
                {{ this.messageTelError }}
              </p>
              <p class="txtInfo04">
                上記電話番号のショートメールに認証コードを送信致します。
              </p>
              <p class="btnRed01" @click="comfirmTel()">
                <a href="javascript:void(0)">認証コードを送信</a>
              </p>
            </div>
          </div>
        </div><!--タグ追加-->
      </div><!--タグ追加 /.gray-box-column-->
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import utils from "../../packs/mixins/utils.js";

import SelectedStoreBox from "../parts/SelectedStoreBox"
import CartBox from "../parts/CartBox"

export default {
  name: "Backorder02_1",
  props: {
  },
  components: {
    SelectedStoreBox,
    CartBox,
  },
  mixins: [utils],
  data() {
    return {
      tel: "",
      messageTelError: "",
    };
  },
  computed: {
  },
  methods: {
    setUserReserveInfo(info) {
      console.log(info);
      if (info.data.errors) {
        for(let error of info.data.errors) {
          this.messageTelError = error.message;
          break;
        }
      } else {
        this.messageTelError = "";
        this.$router.push({
          name: "backorder_02_2",
          query: Object.assign({},this.$route.query),
        });
      }
      this.$root.hideLoading()
    },
    callSendAuthCodeApi() {
      this.$root.showLoading()
      const user_reserve_ids = this.$root.productsInCart.map(i => i.id);
      Api.sendAuthCode(user_reserve_ids, this.tel, this.setUserReserveInfo, this.$root.onDefaultFailureRequest);
    },
    comfirmTel() {
      if (this.tel == null || !this.tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
        this.messageTelError = "携帯電話番号を入力してください";
      } else {
        this.messageTelError = "";
      }

      if (
        this.messageTelError.length > 0
      ) {
        return;
      }
      this.callSendAuthCodeApi();
    },
  },
  mounted() {
    this.$root.callGetProductsInCart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  .error {
    font-size: 1.4rem;
    color: #dd4141;
  }
</style>
