<template>  
  <div>
    <div class="top_board" v-if="is_special_stand" :style="{ background: spcial_stand_info.board_bg_color }">
      <img :src="spcial_stand_info.top_board_image" alt="" class="top_board_image">
    </div>
    <div class="productBoxWrap01" :style="{ marginTop: is_special_stand ? '-6.5rem' : '-10rem' }">
      <div class="productBox01" v-for="(rack_row, index) in this.rack_rows" :key="rack_row.id" :id="'productBox01_' + stand_no + '_' + index">
        <div class="productBoxInner01 row_board" :class="{ 'first' : index === 0 }" v-if="is_special_stand && is_image_row(index + 1)">
          <div class="productSlide01">
            <!-- 特別なスタンドの1段目の場合 -->
            <div class="swiper-wrapper" :style="{ background: row_board_info(index + 1).board_bg_color }" @click="openSpecialModal(row_board_info(index + 1).special_modal_image)">
              <img :src="row_board_info(index + 1).image" alt="" class="row_board_image">
            </div>
          </div>
        </div>
        <div class="productBoxInner01" v-else>
          <div class="productSlide01">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="rack_item in rack_row" :key="rack_item.id">
                <a @click="clickProduct(rack_item)">
                  <span class="image01">
                    <div v-if="rack_item.product_main_image_url != ''">
                      <v-lazy-image
                        :src="$root.$baseApiURL + rack_item.product_main_small_image_url"
                        :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                        alt=""
                      />
                    </div>
                    <NoImage 
                      :productName='rack_item.product_name' 
                      :categoryName='rack_item.product_minor_name' 
                      :productMajorId='rack_item.product_major_id'
                      v-else />
                    <div class="text02">{{ rack_item.regulation }}</div>
                  </span>
                  <span class="text01" v-if="rack_item.product_minor_name !== '' && rack_item.product_major_id !== 4"
                    ><span class="color01">{{
                      rack_item.product_minor_name.substr(0, 5)
                    }}</span></span
                  >
                </a>
              </div>
              <div class="swiper-slide" v-for="n in 8 - (rack_row.length < 8 ? rack_row.length : 8)" :key="n">
                <span class="image01">
                  <div>
                    <img :src="require('../../assets/images/else/img_dummy_product_0' + (n % 5 + 1) + '.png')" alt="" class="dummy">
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="loader" v-if="rack_row.length > 8">
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoImage from "../parts/NoImage"
import Api from "../../lib/api";
import $ from 'jquery';
import Swiper from "swiper";
import utils from "../../packs/mixins/utils.js";

export default {
  name: "StandBox",
  components: {
    NoImage,
  },
  mixins: [
    utils
  ],
  props: {
    product_middle_id: Number,
    rack_set_id: Number,
    stand_no: Number,
  },
  data() {
    return {
      rack_rows: [],
      special_stands : [
        {
          rack_set_id : 448,
          stand_no : 1,
          top_board_image : require("../../assets/images/else/board_kv.png"),
          row_board_images : [ 
            {
              row_no : 1,
              image : require("../../assets/images/else/content_01.png"),
              special_modal_image : require("../../assets/images/else/img_kinen_01.png"),
              board_bg_color : "#80CEEB",
            },
            {
              row_no : 3,
              image : require("../../assets/images/else/content_02.png"),
              special_modal_image : require("../../assets/images/else/img_kinen_02.png"),
              board_bg_color : "#80CEEB",
            },
          ],
          board_bg_color : "#80CEEB",
        },
        {
          rack_set_id : 448,
          stand_no : 2,
          top_board_image : require("../../assets/images/else/board_kv.png"),
          row_board_images : [ 
            {
              row_no : 1,
              image : require("../../assets/images/else/content_03.png"),
              special_modal_image : require("../../assets/images/else/img_kinen_03.png"),
              board_bg_color : "#80CEEB",
            },
          ],
          board_bg_color : "#80CEEB",
        },
      ]
    };
  },
  computed: {
    is_special_stand() {
      var is_special_stand = false
      this.special_stands.map((stand) => {
        if (stand.rack_set_id == this.rack_set_id && stand.stand_no == this.stand_no) {
          is_special_stand = true
        }
      })
      return is_special_stand
    },
    spcial_stand_info() {
      var info = {}
      this.special_stands.map((stand) => {
        if (stand.rack_set_id == this.rack_set_id && stand.stand_no == this.stand_no) {
          info = stand
        }
      })
      return info
    },
  },
  methods: {
    row_board_info(row_no) {
      var info = {}
      this.spcial_stand_info.row_board_images.map((image) => {
        if (image.row_no == row_no) {
          info = image
        }
      })
      return info
    },
    is_image_row(row_no) {
      if (!this.is_special_stand) {
        return false
      }
      var is_image_row = false
      this.spcial_stand_info.row_board_images.map((image) => {
        if (image.row_no == row_no) {
          is_image_row = true
        }
      })
      return is_image_row
    },
    openSpecialModal(image) {
      this.$emit('open-special-modal', image)
    },
    jquery() {
      var pos;
      $(window).on("scroll", function() {
        pos = $(window).scrollTop();
        var top = $(".productBoxWrap01").offset().top;
        // var height = $(".productBoxWrap01").height();
        if (pos >= top + 170) {
          $(".bgVertical").fadeOut();
        } else {
          $(".bgVertical").fadeIn();
        }
      });
    },
    clickProduct(rack_item) {
      if (this.canShowVariation(rack_item)) { // カウンセリング化粧品 or ビューティーケア
        this.$router.push({ 
          name: 'cosmetics_02', 
          params: { product_id: rack_item.product_id },
        })
      } else {
        this.$router.push({ 
          name: 'product_detail_01', 
          params: { product_id: rack_item.product_id },
          query: {
            product_major_id: this.$route.query.product_major_id,
            product_middle_id: this.$route.query.product_middle_id,
            product_minor_id: this.$route.query.product_minor_id,
            rack_set_id: this.rack_set_id,
            stand_no: this.stand_no,
            row_no: rack_item.row_no,
            can_cross_product_minors: this.$route.query.can_cross_product_minors,
          },
        })
      }
    },
    setRackSetItems: function (info) {
      // 特別なスタンド
      if (this.is_special_stand) {
          this.rack_rows.push([])
      }

      // 棚は上から1段,2段
      for (var i = 1; i < 10; i++) {
        var rack_items = [];
        info.data.filter((value) => {
          if (value.row_no == i) {
            rack_items.push(value)
          }
        });
        if (rack_items.length > 0) { // 段に1商品以上あれば
          this.rack_rows.push(rack_items)
        }
      }

      // queryのproduct_idをハッシュ(#)に変換
      if(this.$route.query.product_id) {
        var index = this.rack_rows.findIndex((rack_items) => {
          return rack_items.some((rack_item) => {
            return rack_item.product_id === Number(this.$route.query.product_id)
          })
        });
        if (index >= 0) {
          this.$router.replace({
            name: 'product_detail_03',
            query: {
              product_major_id: this.$route.query.product_major_id,
              product_middle_id: this.$route.query.product_middle_id,
              product_minor_id: this.$route.query.product_minor_id,
              rack_set_id: this.$route.query.rack_set_id,
              stand_no: this.$route.query.stand_no,
            },
            hash: '#productBox01_' + this.stand_no + '_' + index
          });
        }
      }
      // 指定のハッシュ(#)まで移動
      setTimeout(()=> {
        const hash = this.$route.hash;
        if (hash && hash.match(/^#.+$/)) {
          $("html, body").animate({scrollTop: $(hash).offset().top - 190}, 500, 'swing'); // -190でヘッダーに隠れないようにする
        }
      }, 500)
      
      this.$root.hideLoading()

      this.jquery();
    },
    callGetRackSetItemsApi() {
      this.$root.showLoading()
      Api.getRackSetItems(
        this.rack_set_id,
        this.stand_no,
        null,
        this.setRackSetItems,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  mounted() {
    this.callGetRackSetItemsApi();
  },
  updated() {
    // jQuery
    new Swiper(".productSlide01", {
      slidesPerView: "auto",
      grabCursor: true,
      freeMode: true,
      noSwiping: false,
      on: {
        activeIndexChange: function() {
          if (this.realIndex >= this.slides.length - 4) {
            this.$el.closest(".productBox01").addClass("end");
          } else {
            this.$el.closest(".productBox01").removeClass("end");
          }
        },
        init : function() {
          if (this.slides.length <= 4) {
            this.$el.closest(".productBox01").addClass("end");
          }
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  .productBox01 {
    &::before {
      @media screen and (max-width: 850px) {
        margin-top: -30px;
        height: 100%;
      }
    }
  }
  .top_board {
    margin: -75px auto 0;
    height: 200px;
    width: 105rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 850px) {
      margin: -40px auto 0;
      height: 120px;
      width: calc(750 / 750 * 100vw);
    }
    &_image {
      width: auto;
      height: 80%;
    }
  }
  .row_board {
    padding-top: 0 !important;
    width: 100%;
    height: 190px;

    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 850px) {
      height: 120px;
    }
    &.first {
      @media screen and (max-width: 850px) {
        height: 150px;
      }
      .productSlide01 {
        margin-top: 0;
        @media screen and (max-width: 850px) {
          margin-top: -20px;
        //   margin-top: -20px;
        }
      }
    }
    .productSlide01 {
      width: 100%;
      height: 150px;
      margin-top: -70px;
      @media screen and (max-width: 850px) {
        height: 100px;
        margin-top: -55px;
      }
      .swiper-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 850px) {
          justify-content: flex-start;
          margin-left: 30px;
        }
        .row_board_image {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .image01 {
    img {
      height: 10rem;
      @media screen and (max-width: 850px) {
        height: calc(154 / 750 * 100vw);
      }
      object-fit: contain;
      background-color: white;
      &.dummy {
        background-color: transparent;
      }
    }
  }
</style>
