<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div class="boxSearch-wrapper"><!--タグ追加-->

        <BoxItem 
          :title="'取り扱い確認商品'"
          :productId="product_id"
          :showCurrentShop="false"
          @setReservable="setReservable"
        />

        <div class="boxSearch01">
          <h2 class="ttlSearch01">{{ default_shop_name }}</h2>
          <div class="search03">
            <div class="boxMap">
              <p>
                <GmapMap
                  ref="map"
                  :center="{
                    lat: this.default_shop_latitude,
                    lng: this.default_shop_longitude,
                  }"
                  :zoom="16"
                  :options="{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'greedy',
                    clickableIcons: false,
                  }"
                  map-type-id="roadmap"
                  @dragend="onDragend"
                >
                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in this.shops"
                    :position="m.position"
                    :title="m.name"
                    :clickable="true"
                    :draggable="false"
                    @click="onClickMarker(index)"
                    :icon="m.marker"
                    :zIndex="100 - index"
                  />
                </GmapMap>
              </p>
              <StoreInfoBox
                v-if="this.shops.length > 1 && this.selected_shop_no != 0 && this.selected_shop_no != null"
                :shop="selected_shop"
                @openModal="openModal"
              />
            </div>
          </div>
        </div>

      </div><!--タグ追加-->
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import Geo from "../../lib/geo";
import $ from "jquery";

import StoreInfoBox from "../parts/StoreInfoBox";
import BoxItem from "../parts/BoxItem";

const noneMarkerIcon = require("../../assets/images/else/map_pin_g.png");
const stockMarkerIcon = require("../../assets/images/else/map_pin_b.png");
const currentLocationMarkerIcon = require("../../assets/images/else/now.svg");


export default {
  name: "StoreSearch03",
  components: {
    StoreInfoBox,
    BoxItem,
  },
  props: {
    shopCode: Number,
    shopName: String,
    currentLatitude: Number,
    currentLongitude: Number,
  },
  data() {
    return {
      product_id: 0,
      reservable: true, // 取扱可否

      shops: [],

      default_shop_code: "", // 選択して遷移してきたお店の名前
      default_shop_name: "", // 選択して遷移してきたお店の名前
      default_shop_latitude: null, // 選択して遷移してきたお店の場所
      default_shop_longitude: null, // 選択して遷移してきたお店の場所

      current_latitude: null, // 現在地 (GPS)
      current_longitude: null, // 現在地 (GPS)

      center_latitude: null, // 現在の地図の中心
      center_longitude: null, // 現在の地図の中心

      selected_shop_no: null,
      selected_shop: null,

      maker_width: 40,
      maker_height: 60,
      current_maker_width: 30,
      current_maker_height: 20,
      none_marker: {
        url: noneMarkerIcon,
        size: { f: "px", b: "px" },
        scaledSize: { f: "px", b: "px" },
      },
      stock_marker: {
        url: stockMarkerIcon,
        size: { f: "px", b: "px" },
        scaledSize: { f: "px", b: "px" },
      },
      current_marker: {
        url: currentLocationMarkerIcon,
        size: { f: "px", b: "px" },
        scaledSize: { f: "px", b: "px" },
      },

      selected_shop_site_url: "",
    };
  },
  methods: {
    openModal(shop) {
      window.open(shop.site_url)
    },
    getCurrentLocation: function() {
      this.$root.showLoading();
      const onSuccess = (position) => {
        this.current_latitude = position.coords.latitude;
        this.current_longitude = position.coords.longitude;
        this.$root.hideLoading();

        this.callSearchNearShopsApi();
      }
      const onFailure = () => {
        this.$root.hideLoading();

        this.callSearchNearShopsApi();
      }
      Geo.getLocation(onSuccess, onFailure)
    },
    setReservable(reservable) {
      this.reservable = reservable
    },
    setSearchNearShops(info) {
      if (this.shops.length === 0) {
        // 現在地を追加
        if (this.current_latitude && this.current_longitude) {
          this.shops.push({
            position: { lat: this.current_latitude, lng: this.current_longitude },
            marker: this.current_marker,
          });
        }
      }

      info.data.forEach((element) => {

        if (this.default_shop_code === undefined) { // (リロード時など)選択してきた店舗が空なら取得できた最初の店舗をセット
          this.default_shop_code = element.shop_code;
          this.default_shop_name = element.shop_name;
        }

        if (this.shops.findIndex((s) => {s.shop_id === element.id}) >= 0) {
          return;
        }
        this.shops.push({
          shop_id: element.id,
          shop_code: element.shop_code,
          position: { lat: element.latitude, lng: element.longitude },
          name: element.shop_name,
          shop_address: element.shop_address,
          zip_code: element.zip_code,
          available: element.available,
          backorderable: element.backorderable,
          marker: element.available ? this.stock_marker : this.none_marker,
          site_url: element.site_url,
        });
      });
    },
    callSearchNearShopsApi() {
      Api.searchNearShops(
        this.product_id,
        this.default_shop_latitude,
        this.default_shop_longitude,
        this.setSearchNearShops,
        this.$root.onDefaultFailureRequest
      );
    },
    onClickMarker(shop_no) {
      if (shop_no === 0) {
        return
      }
      this.selected_shop_no = shop_no;
      this.selected_shop = this.shops[shop_no];

      // if (this.selected_shop.shop_id === undefined) {
      //   return
      // }

      // jQuery
      $(".boxMapInfo").fadeIn();
    },
    onDragend() {
      let center = this.$refs.map.$mapObject.getCenter();
      console.log(center);
      this.center_latitude = center.lat();
      this.center_longitude = center.lng();
      Api.searchNearShops(
        this.product_id,
        this.center_latitude,
        this.center_longitude,
        this.setSearchNearShops
      );
    },
  },
  computed: {
    backorderable: function() { // 取り寄せ可否
      return !this.isInStock && this.reservable && this.shops.length > 1 && this.shops[1].backorderable;
    },
    isInStock: function() {
      return this.shops.length > 1 && this.shops[1].available;
    },
  },
  watch: {
  },
  mounted() {
    this.product_id = parseInt(this.$route.params.product_id);
  
    this.default_shop_latitude = Number(this.$route.query.shop_latitude)
    this.default_shop_longitude = Number(this.$route.query.shop_longitude)

    this.default_shop_code = this.shopCode;
    this.default_shop_name = this.shopName;

    // サイズ初期化
    this.none_marker.size.width = this.maker_width;
    this.none_marker.size.height = this.maker_height;
    this.none_marker.scaledSize.width = this.maker_width;
    this.none_marker.scaledSize.height = this.maker_height;

    this.stock_marker.size.width = this.maker_width;
    this.stock_marker.size.height = this.maker_height;
    this.stock_marker.scaledSize.width = this.maker_width;
    this.stock_marker.scaledSize.height = this.maker_height;

    this.current_marker.size.width = this.current_maker_width;
    this.current_marker.size.height = this.current_maker_height;
    this.current_marker.scaledSize.width = this.current_maker_width;
    this.current_marker.scaledSize.height = this.current_maker_height;

    // 現在地
    if (this.currentLatitude && this.currentLongitude) {
      this.current_longitude = this.currentLatitude;
      this.current_longitude = this.currentLongitude;

      this.callSearchNearShopsApi();
    } else {
      this.getCurrentLocation();
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/store_search.scss";

  .vue-map-container {
    height: 64rem;
  }
  @media screen and (max-width: 850px) {
    .vue-map-container {
      height: calc(560 / 750 * 100vw);
    }
  }
</style>

