<template>
  <div>
    <div id="headMenu">
      <TitleHeader />
      <MajorCategoryHeader />
      <BackorderHeader />
    </div>
    <MiddleCategoryHeader style="visibility: hidden;"/>
  </div>
</template>

<script>
import TitleHeader from '../parts/TitleHeader'
import MajorCategoryHeader from '../parts/MajorCategoryHeader'
import BackorderHeader from '../parts/BackorderHeader'
import MiddleCategoryHeader from "../parts/MiddleCategoryHeader";


export default {
  name: 'Header',
  components: {
    TitleHeader,
    MajorCategoryHeader,
    BackorderHeader,
    MiddleCategoryHeader,
  },
  mounted(){
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
</style>
