<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div class="clip-column"><!--タグ追加-->
        <div class="clip-column__item clip-column__item-01"><!--タグ追加-->
          <div class="clip-wrapper">
            <div class="info">
              <div class="ttl">このサイトで<br>できること</div>
              <a class="btn" href="/211223.mp4">
                動画で見る
              </a>
            </div>
            <div class="img">
              <img src="../../assets/images/howtouse/img_05.png" alt="">
            </div>
          </div>
      
          <div class="collapse-wrapper">
            <div class="faqWrapper" id="faq-1">
              <img src="../../assets/images/howtouse/img_01.png" alt="">
            </div>
            <div class="faqWrapper" id="faq-2">
              <img src="../../assets/images/howtouse/img_02.png" alt="">
            </div>
            <div class="faqWrapper" id="faq-3">
              <img src="../../assets/images/howtouse/img_03.png" alt="">
            </div>
          </div>
          <div class="collapse-btn">
            <span class="close">詳しい説明はこちら</span>
            <span class="open">閉じる</span>
          </div>
        </div><!--タグ追加-->
        <div class="clip-column__item clip-column__item-02"><!--タグ追加-->
          <div class="img">
            <!--タグ編集-->
            <picture>
              <source media="(min-width: 851px)" srcset="../../assets/images/howtouse/img_04_pc.png">
              <img src="../../assets/images/howtouse/img_04.png" alt="ホーム画面に追加してかんたんアクセス！">
            </picture>
            <!--タグ編集-->
          </div>
        </div><!--タグ追加-->
      </div><!--タグ追加-->

      <div class="faqContent">
        <router-link :to="{ name: 'top' }">TOPに戻る</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
require('fancybox')($);
import 'fancybox/dist/css/jquery.fancybox.css';


export default {
  name: "HowToUse01",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    jquery() {
      $(function(){
        $(".clip-wrapper .info .btn").fancybox({
          'type': 'iframe',
          'padding': 0,
          'margin': 0,
          'width': 320,
          'height': 568,
          'autoSize': false,
        });

        $('.collapse-btn').click(function(){
          $('.collapse-wrapper').slideToggle();
          $(this).toggleClass('active');
        })
      });
    }
  },
  watch: {
  },
  mounted() {
    this.jquery();
    setTimeout(()=> {
      const hash = this.$route.hash;
      if (hash && hash.match(/^#.+$/)) {
        $("html, body").animate({scrollTop: $(hash).offset().top}, 500, 'swing');
      }
    }, 500)
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mobile {
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/howtouse.scss";
}
</style>
