<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div class="description">
        <p>※一部実施していないエリア・店舗がございます。</p>
      </div>

      <div class="boxItem03">
        <div class="ttlItem01 center">
          対象店舗を検索
        </div>
        <div class="boxItemInner01">
          <div class="store_choose_box">
            <div class="ttl">現在地から店舗を検索</div>
            <div class="btn-wrapper">
              <div class="btn-search red" @click="searchByCurrentLocation">近くの対象店舗を探す</div>
            </div>
            <hr class="dashed">
            <div class="ttl">地名・郵便番号から検索</div>
              <div class="searchAddress-wrapper"><!--タグ追加-->

                <div><!--タグ追加-->

                  <div class="label">地名を入力して検索</div>
                  <input type="text" name="" placeholder="例：静岡県、千代田区など" v-model="place" @focus="show(0)">
                  <p class="error" v-if="this.messagePlaceError.length">
                    {{ this.messagePlaceError }}
                  </p>

              </div><!--タグ追加-->

              <div><!--タグ追加-->

                <div class="label">郵便番号で検索</div>
                <input type="text" name="" placeholder="1110000（ハイフンなしで入力）" v-model="zipcode" @focus="show(1)">
                <p class="error" v-if="this.messageZipcodeError.length">
                  {{ this.messageZipcodeError }}
                </p>

              </div><!--タグ追加-->

            </div><!--タグ追加-->

            <div class="btn-wrapper">
              <div class="btn-search blue" @click="comfirmKeyword">検索</div>
            </div>
          </div>
        </div>
      </div>

      <div id="storeSearchResult" class="boxSearch01" v-if="has_searched">
        <div class="store_choose_box">
          <p class="txtOrder03 left border">※一部実施していないエリア・店舗がございます。</p>
          <div class="result-info">
            <p v-if="has_searched_by_current_location">「現在地」の検索結果</p>
            <p v-else>「{{ keyword }}」の検索結果</p>
            <p>全{{ shops.length }}件</p>
          </div>
        </div>
        <ul class="listSearch01">
          <li v-for="shop in shops" :key="shop.id">
            <div class="leftBox">
              <p class="shopName"><a href="javascript:void(0)" class="icoBlank" @click="openModal(shop)">{{ shop.shop_name }}</a></p>
              <p class="shopText">〒{{ shop.zip_code }}<br>{{ shop.shop_address }}</p>
            </div>
            <div class="rightBox">
              <ul class="listBtn multi">
                <li class="btnStock01"><a href="javascript:void(0)" class="outer" @click="openModal(shop)">詳細</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="button-wrapper">
        <router-link :to="{ name: 'top' }" class="back">戻る</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import Geo from "../../lib/geo";
import VueScrollTo from "vue-scrollto";


export default {
  name: "StoreList01",
  components: {
  },
  data() {
    return {
      place: "",
      zipcode: "",
      filtered: false,

      shops: [],

      has_searched_by_keyword: false,
      has_searched_by_current_location: false,

      current_latitude: null,
      current_longitud: null,

      messagePlaceError: "",
      messageZipcodeError: "",

      selected_shop_site_url: "",

      visible: false,
      inputNo: 0,
    };
  },
  computed: {
    keyword: function() {
      if(this.place && this.zipcode) {
        return (this.place + " " + this.zipcode).trim(); // 場所と郵便番号が両方ある場合はスペース区切り
      } else {
        return (this.place + this.zipcode).trim(); // 場所と郵便番号が片方の場合はどちらかだけ
      }
    },
    has_searched: function() {
      return this.has_searched_by_keyword || this.has_searched_by_current_location;
    },
    selectedMyshop: function() { // 選択中のマイショップ
      return this.myshops.find(s=>s.selected)
    },
  },
  methods: {
    reset: function() {
      this.shops = [];
      this.place = "";
      this.zipcode = "";
      this.filtered = false;
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = false;
    },
    searchByCurrentLocation: function () {
      this.$root.showLoading();
      this.reset();
      const onSuccess = (position) => {
        this.current_latitude = position.coords.latitude;
        this.current_longitud = position.coords.longitude;
        this.callSearchNearShopsApi(
          position.coords.latitude,
          position.coords.longitude,
        )
        this.$root.hideLoading();
      }
      const onFailure = () => {
        this.$root.hideLoading();
      }
      Geo.getLocation(onSuccess, onFailure)
    },
    setShops: function (info) {
      this.shops = info.data;
      this.$root.hideLoading()

      VueScrollTo.scrollTo("#storeSearchResult", 1000);
      // $("html, body").animate({scrollTop: $("#storeSearchResult").offset().top - 200}, 500, 'swing');
    },
    callSearchNearShopsApi: function(latitude, longitude) {
      this.$root.showLoading();
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = true;
      Api.searchNearShops(
        this.product_id,
        latitude,
        longitude,
        this.setShops,
        this.$root.onDefaultFailureRequest
      );
    },
    comfirmKeyword() {
      this.visible = false
      if (this.keyword.length == 0) {
        this.messagePlaceError = "地名または郵便番号のどちらかを入力してください。";
        this.messageZipcodeError = "地名または郵便番号のどちらかを入力してください。";
        return;

      } else {
        this.messagePlaceError = "";
        this.messageZipcodeError = "";
      }

      if (this.zipcode.trim().length !== 0 && this.zipcode.trim().length < 3) {
        this.messageZipcodeError = "郵便番号は3桁以上を入力してください。";
        return;
      } else {
        this.messageZipcodeError = "";
      }

      this.callSearchShops()
    },
    callSearchShops: function() {
      this.$root.showLoading();
      this.has_searched_by_keyword = true;
      this.has_searched_by_current_location = false;
      Api.searchShops(
        this.product_id, 
        this.place,
        this.zipcode,
        this.setShops, 
        this.$root.onDefaultFailureRequest
      );
    },
    openModal(shop) {
      window.open(shop.site_url)
    },
    onEnter(input) {
      if (this.inputNo === 0) {
        if (this.place == null) {
          this.place = input
        } else {
          this.place += input
        }
      } else {
        if (this.place == null) {
          this.zipcode = input
        } else {
          this.zipcode += input
        }
      }
      this.comfirmKeyword()
    },
    onBksp() {
      if (this.inputNo === 0) {
        this.place = this.place.slice(0, -1)
      } else {
        this.zipcode = this.zipcode.slice(0, -1)
      }
    },
    onClear() {
      if (this.inputNo === 0) {
        this.place = ""
      } else {
        this.zipcode = ""
      }
    },
    show(inputNo) {
      if (!this.visible) {
        this.visible = true
      }
      this.inputNo = inputNo
    },
    hide() {
      this.visible = false
    },

  },
  watch: {
  },
  mounted() {
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";
  @import "../../assets/stylesheets/responsive/store_list.scss";
  .error {
    font-size: 1.6rem;
    color: #dd4141;
  }
</style>
