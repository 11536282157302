<template>
  <div>
    <div class="mainContent">
      <div class="boxItem03 boxMyStore">
        <div class="ttlItem01 center"> マイ店舗の登録 </div>
        <div class="boxItemInner01">
          <p class="boxMyStoreLead">マイ店舗をご登録いただくと、<br>商品の取り扱いが確認できます。</p>
          <div class="boxMyStoreBtns">
            <button class="boxMyStoreBtn red" @click="openMyStoreListModal">マイ店舗を登録</button>
            <a :href="this.$route.query.redirect || '#'" class="boxMyStoreBtn blue">戻る</a>
          </div>
        </div>
      </div>
    </div>
    <MyStoreList
      ref="my_store_list"
      :myshops="myshops"
      @getMyshops="callGetMyshopsApi" />
  </div>
</template>
<script>
import Api from "../../lib/api";
import MyStoreList from "../parts/MyStoreList"

export default {
  name: "MyList01",
  components: {
    MyStoreList,
  },
  mixins: [
  ],
  props: {
  },
  data() {
    return {
      myshops: [],
    }
  },
  filters: {
  },
  computed: {
  },
  methods: {
    openMyStoreListModal() {
      this.$refs.my_store_list.openMyStoreListModal(); // 子のメソッド呼び出し
    },
    setMyshops: function (info) {
      this.myshops = info.data;
      this.$root.hideLoading()
    },
    callGetMyshopsApi() {
      this.$root.showLoading()
      Api.getMyshops(
        null,
        this.setMyshops,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  watch: {
  },
  mounted() {
    this.callGetMyshopsApi();
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  
  .mainContent {
    background: #f7f7f7 !important;
  }

  @media print, screen and (min-width: 1025px) {
    .mainContent .innerContent01 {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }
</style>