<template>
  <div>
    <div id="sMenu" v-show="!isHidden">
      <div id="sMenu">
        <div id="sMenuList">
          <div class="swiper-wrapper">
            <div
              :class="itemStyle(product_middle.id)"
              v-for="product_middle in this.valid_product_middles"
              :key="product_middle.id"
            >
              <p @click="selectProductMiddle(product_middle.product_major_id, product_middle.id, product_middle.product_middle_name, product_middle.first_rack_set_id, product_middle.first_minor_id, product_middle.use_product_minors)">
                <a href="javascript:void(0)">{{ product_middle.product_middle_name }}</a>
              </p>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <MiddleCategoryModal
      v-if="selected_product_middle_id != 0"
      :product_major_id="selected_product_major_id"
      :product_middle_id="selected_product_middle_id"
      :product_middle_name="selected_product_middle_name"
      :product_minor_id="selected_first_minor_id"
      :rack_set_id="selected_first_rack_set_id"
    />
    <MiddleBrandModal 
      :productMiddleId="selected_product_middle_id"
      :productMiddleName="selected_product_middle_name" />
  </div>
</template>

<script>
import Api from "../../lib/api";

import $ from "jquery";
import Swiper from "swiper";
import MiddleCategoryModal from "../parts/MiddleCategoryModal";
import MiddleBrandModal from "../parts/MiddleBrandModal";

var swiper;

export default {
  name: "MiddleCategoryHeader",
  components: {
    MiddleCategoryModal,
    MiddleBrandModal,
  },
  props: {
    isHidden: Boolean,
  },
  data() {
    return {
      selected_product_major_id: 0, // モーダルで選択した大カテゴリID
      selected_product_middle_id: 0, // モーダルで選択した中カテゴリID
      selected_product_middle_name: "", // モーダルで選択した中カテゴリ名
      selected_first_rack_set_id: 0, // モーダルで選択した中カテゴリに紐づく最初の棚ID
      selected_first_minor_id: 0, // モーダルで選択した中カテゴリに紐づく最初の小分類ID
      product_middles: [], // ヘッダーに表示中カテゴリリスト(URLの大カテゴリIDから取得)
      selected_product_middles: [], // モーダルで選択中の大カテゴリ配下の中カテゴリリスト
    };
  },
  methods: {
    itemStyle: function(id) {
      if (id == this.$route.query.product_middle_id) {
        return "sMenuCurrent swiper-slide btnModalMiddleCategory"
      } else {
        return "swiper-slide btnModalMiddleCategory"
      }
    }, 
    setProductMiidle: function (info) { // ヘッダーに表示するカテゴリリストを設定
      this.product_middles = info.data;

      let index = this.valid_product_middles.findIndex (
        (item) => item.id == this.$route.query.product_middle_id
      )
      this.jquery(index)

      if (this.$route.query.modal === undefined) {
        return
      }

      let selected_product_major_id = null;
      if (this.$route.query.selected_product_major_id) {
        selected_product_major_id = this.$route.query.selected_product_major_id;
      } else {
        selected_product_major_id = this.$route.query.product_major_id;
      }

      let selected_product_middle_id = null;
      if (this.$route.query.selected_product_middle_id) {
        selected_product_middle_id = this.$route.query.selected_product_middle_id;
      } else {
        selected_product_middle_id = this.$route.query.product_middle_id;
      }

      let product_middle = null;
      if (selected_product_major_id == 4) {
        product_middle = this.product_middles.find((product_middle) => product_middle.id === parseInt(selected_product_middle_id));
      }else {
        product_middle = this.valid_product_middles.find((product_middle) => product_middle.id === parseInt(selected_product_middle_id));
      }
      if (product_middle) {
        this.selectProductMiddle(product_middle.product_major_id, product_middle.id, product_middle.product_middle_name, product_middle.first_rack_set_id, product_middle.first_minor_id, product_middle.use_product_minors)
      }
    },
    callProductMiidleApi() { // ヘッダーに表示するカテゴリリストを取得
      Api.getProductMiddles(this.$route.query.product_major_id , this.setProductMiidle, this.$root.onDefaultFailureRequest);
    },

    setSelectedProductMiidle: function (info) { // モーダルで選択中の大カテゴリ配下の中カテゴリリストを設定(ヘッダーに表示中の大カテゴリとモーダルで選択した大カテゴリが異なる場合のみ利用)
      this.selected_product_middles = info.data;

      let selected_product_major_id = null;
      if (this.$route.query.selected_product_major_id) {
        selected_product_major_id = this.$route.query.selected_product_major_id;
      } else {
        selected_product_major_id = this.$route.query.product_major_id;
      }

      let selected_product_middle_id = null;
      if (this.$route.query.selected_product_middle_id) {
        selected_product_middle_id = this.$route.query.selected_product_middle_id;
      } else {
        selected_product_middle_id = this.$route.query.product_middle_id;
      }

      let product_middle = null;
      if (selected_product_major_id == 4) {
        product_middle = this.product_middles.find((product_middle) => product_middle.id === parseInt(selected_product_middle_id));
      } else {
        product_middle = this.valid_selected_product_middles.find((product_middle) => product_middle.id === parseInt(selected_product_middle_id));
      }
      if (product_middle) {
        this.selectProductMiddle(product_middle.product_major_id, product_middle.id, product_middle.product_middle_name, product_middle.first_rack_set_id, product_middle.first_minor_id, product_middle.use_product_minors)
      }
    },
    callSelectedProductMiidleApi() {  // モーダルで選択中の大カテゴリ配下の中カテゴリリストを取得(ヘッダーに表示中の大カテゴリとモーダルで選択した大カテゴリが異なる場合のみ利用)
      Api.getProductMiddles(this.$route.query.selected_product_major_id , this.setProductMiidle, this.$root.onDefaultFailureRequest);
    },
    selectProductMiddle(product_major_id, product_middle_id, name, first_rack_set_id, first_minor_id, use_product_minors) {
      if(product_major_id === 4) { // カウンセリング化粧品のとき

        this.selected_product_middle_id = product_middle_id
        this.selected_product_middle_name = name

        $('#modalCategory4').fadeIn();

      } else { // カウンセリング化粧品以外のとき
        if(use_product_minors) {
          // 小分類選択モーダルを開く
          this.selected_product_major_id = product_major_id
          this.selected_product_middle_id = product_middle_id
          this.selected_product_middle_name = name
          this.selected_first_rack_set_id = first_rack_set_id
          this.selected_first_minor_id = first_minor_id

          setTimeout(()=> {
            $('#modalMiddleCategory #modalCategory').fadeIn();
          }, 0)
        } else {
          
          // 小分類選択なしで棚を開く
          this.$router.push({
            name: "product_detail_03",
            query: { 
              product_major_id: product_major_id, 
              product_middle_id: product_middle_id,
              product_minor_id: first_minor_id,
              rack_set_id: first_rack_set_id,
              stand_no: 1,
              can_cross_product_minors: null,
            }
          });
        }
      }
    },
    jquery(index) {
      // jQuery
      $(function () {
        if (swiper === undefined) {
          swiper = new Swiper("#sMenuList", {
            speed: 600,
            loop: false,
            centeredSlides: true,
            slidesPerView: "auto",
            initialSlide: index,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        } else {
          swiper.slideTo(index)
        }
      });
    }
  },
  computed: {
    valid_product_middles() {
      if (this.$route.query.product_major_id === 4) { // カウンセリング化粧品のとき
        return this.product_middles; // そのまま
      } else { // カウンセリング化粧品以外のとき
        return this.product_middles.filter((product_middle) => product_middle.first_minor_id !== null); // 棚があるものだけ
      }
    },
    valid_selected_product_middles() {
      return this.selected_product_middles.filter((product_middle) => product_middle.first_minor_id !== null)
    }
  },
  watch: {
    "$route.query.product_major_id"() {
      if (this.$route.query.product_major_id !== 0) {
        this.callProductMiidleApi();
      }
    },
    "$route.query.product_middle_id"() {
      let index = this.valid_product_middles.findIndex (
        (item) => item.id == this.$route.query.product_middle_id
      )
      swiper.slideTo(index, false, false);    
    },
    "$route.query.modal"() {
      if (this.$route.query.modal === undefined) { // モーダルが閉じた時
        return
      }

      // モーダルが開いた時
      if (this.$route.query.product_major_id !== this.$route.query.selected_product_major_id) { // 表示中の大分類と選択した大分類が異なる場合は、中カテゴリを取得しselected_product_middlesに格納する
        this.callSelectedProductMiidleApi()
      } else { // 表示中の大分類と選択した大分類が同じ場合は、表示中の中カテゴリリストから選択した中カテゴリを検索し、選択中にする。
        let selected_product_middle_id = null;
        if (this.$route.query.selected_product_middle_id) {
          selected_product_middle_id = this.$route.query.selected_product_middle_id;
        } else {
          selected_product_middle_id = this.$route.query.product_middle_id;
        }

        const product_middle = this.valid_product_middles.find((product_middle) => product_middle.id === parseInt(selected_product_middle_id));
        if (product_middle) {
          this.selectProductMiddle(product_middle.product_major_id, product_middle.id, product_middle.product_middle_name, product_middle.first_rack_set_id, product_middle.first_minor_id)
        }
      }
    }
  },
  mounted() {
    // swiperを初期化
    swiper = undefined;

    this.callProductMiidleApi();    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '';
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '';
}

  @import "../../assets/stylesheets/responsive/base.scss";  
</style>
