<template>
  <div>
    <div class="mainContent">
      <div class="innerContent01">
        <div class="productDetail-wrapper"><!--タグ追加-->

          <div class="itemInfo">
            <div class="itemInfoInner">
              <div class="itemCategory-wrapper">
                <div class="itemCategory" v-if="product_minor_name">
                  {{ product_minor_name }}
                </div>
                <p class="txtCat01"><span v-if="product_item_name !== ''">{{ product_item_name }}</span></p>
              </div>
              <p class="txtCat03"><small>{{ maker_name }}</small></p>
              <h1 class="txtCat02">{{ product_name }}</h1>
              <p class="txtPrice01">
                販売価格：<b>{{ price | numberFormat }}円</b> (税込：{{
                  taxed_price | taxedPrice
                }}円)
              </p>
              <p class="txtCaution01">※店舗での特売やチラシの価格は反映されておりません。</p>
              <p class="txtCaution02">価格は店舗により異なる可能性があります。詳しくは店頭でご確認ください。</p>
              <p class="txtCat02">JANコード : {{ jan_code }}</p>
              <div class="txtPoint01" 
                v-for="product_point in product_points"
                :key="product_point.id">
                <PointBox :productPoint="product_point" :position="'left'" :tmpName="'エコ活ポイント'" />
              </div>
            </div>
          </div>
          <div class="btnBox">
            <div class="btnPrev" v-show="hasNeighborhood() && index > 0">
              <div class="arrow product-button-prev">前の商品</div>
            </div>
            <div class="btnNext" v-show="hasNeighborhood() && index < rack_rows.length - 1">
              <div class="arrow product-button-next">次の商品</div>
            </div>
          </div>
          <div class="productSlide01" v-if="hasNeighborhood()">
            <div class="boxItem01 swiper-wrapper" @click="openModal">
              <div class="item01 swiper-slide"
                v-for="rack_item in rack_rows"
                :key="rack_item.id"
              >
                <v-lazy-image
                  :src="$baseApiURL + rack_item.product_main_image_url" alt=""
                  :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                  v-if="rack_item.product_main_image_url != ''"
                />
                <NoImage 
                  :categoryName='categoryName(product)' 
                  :productMajorId='product_major_id'
                  v-else />
                <div class="text02">
                  {{ rack_item.regulation }}
                </div>
                <Favorite :product_id="rack_item.product_id" :favorite="favorite" v-if="isCenter(rack_item.product_id)" />
                <!-- <button type="button" class="myList"><span>マイリスト</span></button> -->
              </div>
            </div>
          </div>
          <div class="productSlide01" v-else>
            <div class="boxItem01 swiper-wrapper" @click="openModal" style="transform: translate3d(-11px, 0px, 0px);">
              <div class="item01 item2 swiper-slide">
                <v-lazy-image
                  :src="$baseApiURL + product_image_url" alt=""
                  :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                  v-if="product_image_url != ''"
                />
                <NoImage 
                  :categoryName='categoryName(product)' 
                  :productMajorId='product_major_id'
                  v-else />
                <div class="text02">
                  {{ regulation }}
                </div>
                <Favorite :product_id="Number($route.params.product_id)" :favorite="favorite" />
                <!-- <button type="button" class="myList"><span>マイリスト</span></button> -->
              </div>
            </div>
          </div>
          <!-- <div class="boxItem01">
            <p class="item01">
              <a href="#"><img src="/user_data/packages/welcia/img/product_details/img_item01.jpg" alt=""></a>
              <button type="button" class="myList"><span>マイリスト</span></button>
            </p>
            <p class="btnPrev"><a data-href="/product_details/04.html"><span class="arrow">前の商品</span><img src="/user_data/packages/welcia/img/product_details/img_prev.jpg" alt=""></a></p>
            <p class="btnNext"><a data-href="/product_details/05.html"><span class="arrow">次の商品</span><img src="/user_data/packages/welcia/img/product_details/img_next.jpg" alt=""></a></p>
          </div> -->
          <div class="boxBtn01">
            <p class="btnModal01" v-if="hasDetails()"><a href="javascript:void(0)" @click="openModal">商品詳細を見る</a></p>
            <ul class="listBtn">
              <li class="btnOnline01" v-if="this.ext_link"><a v-bind:href="this.ext_link + '&pub_cate=001&pub_media=018&pub_name=029'" target="_blank">オンライン<br>ショップを見る</a></li>
              <li class="btnOnline02" v-else><p>オンラインショップ<br>取扱なし</p></li>
              <li class="btnDetail01">
                <router-link
                  :to="{
                    name: 'product_detail_02',
                    query: Object.assign({},$route.query),
                  }"
                  >店舗の取り扱いを確認</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <ItemModal
      :product_id="Number(this.$route.params.product_id)"
      :product_detail="this.product_detail"
      :product_images="this.product_images"
      :product_pdf_url="this.product_pdf_url"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import Api from "../../lib/api";
import $ from "jquery";
import Swiper from "swiper";

import ItemModal from "../parts/ItemModal";
import Favorite from "../parts/Favorite"
import NoImage from "../parts/NoImage"
import PointBox from "../parts/PointBox.vue";
import utils from "../../packs/mixins/utils.js";

export default {
  name: "ProductDetail01",
  components: {
    ItemModal,
    Favorite,
    NoImage,
    PointBox,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      product: null,
      product_name: "",
      maker_name: "",
      product_item_name: "",
      price: "",
      product_detail: "",
      regulation: "",
      product_points: [],
      taxed_price: "",
      product_major_id: 0,
      product_middle_name: "",
      product_minor_name: "",
      product_image_url: "",
      product_images: [],
      product_pdf_url: "",
      jan_code: "",
      rack_rows: [],
      index: 0,
      ext_link: "",
      favorite: false,
    };
  },
  computed: {
  },
  methods: {
    openModal() {
      if (this.hasDetails()) {
        $('#itemModal').fadeIn(function(){
          var currentIndex = $('.tab li.active').index();
        
          $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').show().addClass('active');
          var itemH = $('.itemModalBox02').find('.itemModalBoxInner01.active').outerHeight();
          $('.itemModalBox02').find('.itemModalBoxOuter01').css('height',itemH);

          new Swiper('#itemModalSlider', {
            slidesPerView: 'auto',
            speed: 600,
            loop: false,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          });

        });
      }
    },
    closeModal() {
      $('#itemModal').fadeOut(function(){
        $('.itemModalBox02').find('.itemModalBoxInner01').removeClass('active');//.hide();
      });
    },
    hasNeighborhood() {
      return this.$route.query.rack_set_id !== undefined
    },
    hasDetails() {
      return this.product_image_url !== "" || this.product_detail !== null || this.product_pdf_url !== ""
    },
    isCenter(product_id) {
      return product_id === Number(this.$route.params.product_id)
    },
    setProduct: function (info) {
      // 選択中の商品
      this.product = info.data;
      this.product_name = info.data.product_name;
      this.maker_name = info.data.maker ? info.data.maker.maker_name : "";
      this.product_item_name = info.data.product_item ? info.data.product_item.item_name : "";
      this.price = info.data.price;
      this.product_detail = info.data.product_detail;
      this.regulation = info.data.regulation;
      this.product_points = info.data.product_points ? info.data.product_points : [];
      this.taxed_price = info.data.taxed_price;
      this.product_major_id = info.data.product_major ? info.data.product_major.id : 0;
      this.product_middle_name = info.data.product_middle ? info.data.product_middle.product_middle_name : "";
      this.product_minor_name = info.data.product_minor ? info.data.product_minor.product_minor_name : "";
      this.product_image_url = info.data.product_main_image_url;
      this.product_images = info.data.product_images;
      this.product_pdf_url = info.data.product_pdf_url;
      this.jan_code = info.data.jan_code;
      this.ext_link = info.data.ext_link;
      this.favorite = info.data.favorite;

      // this.$root.hideLoading() // 横スクロール時にくるくるが出てしまうので表示しないようにする
    },
    callGetProductApi() {
      Api.getProduct(Number(this.$route.params.product_id), this.setProduct, this.$root.onDefaultFailureRequest);

      // this.$root.showLoading() // 横スクロール時にくるくるが出てしまうので表示しないようにする
    },
    setRackSetItems: function (info) {
      this.rack_rows = info.data;

      // 表示中の商品は何番目か
      this.index = this.rack_rows.findIndex(
        (item) => item.product_id === Number(this.$route.params.product_id)
      );
      setTimeout(()=>{
        this.initSwiper(this.index)
      }, 0)

      // 商品詳細取得
      this.callGetProductApi();

      this.$root.hideLoading()
    },
    callGetRackSetItemsApi() {
      this.$root.showLoading()
      Api.getRackSetItems(
        this.$route.query.rack_set_id,
        this.$route.query.stand_no,
        this.$route.query.row_no,
        this.setRackSetItems,
        this.$root.onDefaultFailureRequest
      );
    },
    initSwiper(index) {
      var self = this

      var swiper = new Swiper(".productSlide01", {
        speed: 600,
        loop: false,
        centeredSlides: true,
        slidesPerView: "auto",
        initialSlide: index,
        navigation: {
          nextEl: ".product-button-next",
          prevEl: ".product-button-prev",
        },
        on : {
          activeIndexChange: () => {
            // swiperがundefinedになるので遅延実行
            setTimeout(() => {
              self.$router.push({
                name: "product_detail_01",
                params: {
                  product_id: self.rack_rows[swiper.activeIndex].product_id,
                },
                query: {
                  product_major_id: self.$route.query.product_major_id,
                  product_middle_id: self.$route.query.product_middle_id,
                  product_minor_id: self.$route.query.product_minor_id,
                  rack_set_id: self.$route.query.rack_set_id,
                  stand_no: self.$route.query.stand_no,
                  row_no: self.$route.query.row_no,
                  can_cross_product_minors: self.$route.query.can_cross_product_minors,
                }
              }, 500)
            })
          }
        },
      })
    },
  },
  watch: {
    "$route.params.product_id"() {
      this.index = this.rack_rows.findIndex(
        (item) => item.product_id === Number(this.$route.params.product_id)
      );
      this.callGetProductApi();
    },
  },

  mounted() {
    if (this.hasNeighborhood()) {
      this.callGetRackSetItemsApi()
    } else {
      this.callGetProductApi()
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  .boxItem01 {
    .item01 {
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .item2 {
      margin: 0 auto;
    }
  }

  // 「オンラインショップ取扱なし」の場合のデザインが崩れるため、修正
  .mainContent {
    .listBtn {
      li{
        &.btnOnline02 {
          p {
            font-size: 13px;
            height: 40px;
            border-radius: 6px;
          }
        }
      }
    }
  }
</style>