<template>
  <div class="mainContent">
    <div class="innerContent01">
      <ul class="anchorList">
        <li v-for="faq_group in faq_groups" :key="faq_group.id"><p :data-href="`#faq-${faq_group.id}`">{{ faq_group.title }}</p></li>
      </ul>
      <div v-for="faq_group in faq_groups" :key="faq_group.id" class="faqWrapper" :id="`faq-${faq_group.id}`">
        <div class="title">{{ faq_group.title }}</div>
        <div class="faqContent">
          <div v-for="faq in faq_group.faqs" :key="faq.id" class="faq">
            <div class="q"><span></span>{{ faq.question }}</div>
            <div class="a"><span></span>{{ faq.answer  }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="btnWrapper"> -->
        <!-- <div class="title">その他お問合せ先</div> -->
        <!-- <div class="flex"> -->
          <!-- <a href="https://www.welcia-yakkyoku.co.jp/inquire/inquire.html" target="_blank" class="btn">店舗について</a> -->
          <!-- <a href="/faq/02.html" class="btn">おうちウエルシアについて</a> -->
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Api from "../../lib/api";

export default {
  name: "Faq01",
  components: {
  },
  data() {
    return {
      faq_groups: [],
    };
  },
  methods: {
    jquery() {
      $(function(){
        $('.anchorList li p').click(function() {
          var t = $(this).data('href');
          $("html, body").animate({scrollTop: $(t).offset().top - $('#header').height() - $('.title01').height() - 20}, 500, 'swing');
          return false;
        })

        $('.faq .a a').click(function() {
          var t = $(this).data('href');
          $("html, body").animate({scrollTop: $(t).offset().top - $('#header').height() - $('.title01').height() - 10}, 500, 'swing');
          return false;
        })

        $('.faqContent .faq .q').click(function(){
          $(this).toggleClass('active');
          $(this).parent().find('.a').slideToggle();
        });
      });
    },
    setFaqs: function (info) {
      this.faq_groups = info.data;
      this.$root.hideLoading()
      this.jquery()
    },
    callGetFaqGroups() {
      this.$root.showLoading()
      Api.getFaqGroups(this.setFaqs, this.$root.onDefaultFailureRequest);
    },

  },
  watch: {
  },
  mounted() {
    this.callGetFaqGroups()
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/faq.scss";
</style>
