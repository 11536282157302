<template>
  <div>
    <BackorderHeader />
    <router-view/>
  </div>
</template>

<script>
import BackorderHeader from '../global/BackorderHeader'

export default {
  name: 'DefaultLayout',
  components: {
    BackorderHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = '';
  },
}
</script>

<style scoped>
</style>
