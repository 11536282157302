<template>
  <h1 class="title01">
    よくあるご質問
  </h1>
</template>

<script>
export default {
  name: "FaqHeader",
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/faq.scss";
</style>