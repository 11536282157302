<template>
  <div class="mainContent">
    <div class="innerContent01 total">
      <div class="bgVertical"></div>
    <!-- <div class="innerContent01 total new"> -->
      <div class="standSlide">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-no-swiping" v-for="rack_stand in rack_stands" :key="rack_stand.id">
            <StandBox 
              :product_middle_id=$data.product_middle_id
              :rack_set_id=rack_stand.rack_set_id
              :stand_no=rack_stand.stand_no
              @open-special-modal="openSpecialModal"
            />
              
          </div>
        </div>
      </div>
    </div>
    <SpecialModal :image="image" />
  </div>
</template>
<script>
import StandBox from "../parts/StandBox"
import Api from "../../lib/api";
import $ from 'jquery';
import Swiper from "swiper";
import SpecialModal from "../parts/SpecialModal";

var swiper;

export default {
  name: "ProductDetail03",
  components: {
    StandBox,
    SpecialModal,
  },
  data() {
    return {
      product_major_id: 0,
      product_middle_id: 0,
      product_minor_id: 0,
      rack_set_id: 0,
      stand_no: 0,
      rack_stands: [],
      rack_rows: [],
      can_cross_product_minors: false,

      image: undefined,
    };
  },
  methods: {
    openSpecialModal(image) {
      this.image = image;
      $('#specialModal').fadeIn(0, function () {
    });
      
    },
    setRackSets: function (info) {
      this.rack_stands = info.data.rack_set_stands;

      const index = this.rack_stands.findIndex((rack_stand) => {
        return rack_stand.rack_set_id === this.rack_set_id && rack_stand.stand_no === this.stand_no
      })
      // jQuery
      setTimeout(() => {
        swiper = new Swiper(".standSlide", {
          slidesPerView: "auto",
          initialSlide: index,
          noSwiping: true,
        });
        this.$root.hideLoading()
      }, 1000);

    },
    callGetRackSetsApi() {
      this.$root.showLoading()
      if (this.can_cross_product_minors) {
        Api.getRackSets(
          this.product_middle_id, 
          null, 
          this.setRackSets, 
          this.$root.onDefaultFailureRequest, 
          this.$root.onDefaultFailureRequest
        );
      } else {
        Api.getRackSets(
          null, 
          this.product_minor_id, 
          this.setRackSets, 
          this.$root.onDefaultFailureRequest, 
          this.$root.onDefaultFailureRequest
        );
      }
    },
  },
  computed: {
  },
  mounted() {
    this.product_major_id = parseInt(this.$route.query.product_major_id);
    this.product_middle_id = parseInt(this.$route.query.product_middle_id);
    this.product_minor_id = parseInt(this.$route.query.product_minor_id);
    this.rack_set_id = parseInt(this.$route.query.rack_set_id);
    this.stand_no = parseInt(this.$route.query.stand_no);
    this.can_cross_product_minors = (this.$route.query.can_cross_product_minors !== undefined)  // 小分類選択なしで開く true ありで開く false

    if(this.$route.query.modal === undefined) { // モーダルなしで遷移してきた場合
      this.callGetRackSetsApi();
    }

  },
  updated() {
  },
  watch: {
    '$route' (to, from) {
      this.product_major_id = parseInt(to.query.product_major_id);
      this.product_middle_id = parseInt(to.query.product_middle_id);
      this.product_minor_id = parseInt(to.query.product_minor_id);
      this.rack_set_id = parseInt(to.query.rack_set_id);
      this.stand_no = parseInt(to.query.stand_no);
      this.can_cross_product_minors = (to.query.can_cross_product_minors !== undefined)  // 小分類選択なしで開く true ありで開く false

      if(this.$route.query.modal === undefined) { // モーダルを閉じた場合
        if(this.rack_stands.length > 0) {
          if (from.query.product_minor_id === to.query.product_minor_id) { // 小分類同じだったらスライドのみ
            const index = this.rack_stands.findIndex((rack_stand) => rack_stand.rack_set_id === this.rack_set_id && rack_stand.stand_no === this.stand_no)
            swiper.slideTo(index);
          } else {  // 小分類が変わったら棚を取得
            this.rack_stands = [];
            swiper.slideTo(0);
            this.callGetRackSetsApi();
          }
        } else {
          this.callGetRackSetsApi();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
</style>