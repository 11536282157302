<template>
  <div id="headMenu">
    <TitleHeader :hasOnlyMenu="true" :isTransparent="true" :isBlinkingMyList="true" />
  </div>
</template>

<script>
import TitleHeader from '../parts/TitleHeader'

export default {
  name: 'Header',
  components: {
    TitleHeader,
  },
  mounted(){
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";

  #headMenu {
    background-color: rgba(0, 0, 0, 0);
  }
</style>
