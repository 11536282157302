<template>
  <div>
    <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
      <SelectedStoreBox :selectedShop='this.$root.selectedShop'/>
      <div class="innerContent01">
        <CartBox :productsInCart='this.$root.productsInCart' :canEdit='true' @getProductsInCart='this.$root.callGetProductsInCart' />
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../../packs/mixins/utils.js";

import SelectedStoreBox from "../parts/SelectedStoreBox"
import CartBox from "../parts/CartBox"

export default {
  name: "Cart_01",
  components: {
    SelectedStoreBox,
    CartBox,
  },
  mixins: [utils],
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    this.$root.callGetProductsInCart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>
