<template>
  <div id="mOrder">
    <h1 class="ttlOrder">商品を取り寄せる</h1>
    <p class="btnOrder">
      <router-link
        :to="{
          name: 'product_detail_02',
          query: {
            product_major_id: $route.query.product_major_id,
            product_middle_id: $route.query.product_middle_id,
            rack_set_id: $route.query.rack_set_id,
            stand_no: $route.query.stand_no,
            row_no: $route.query.row_no,
            can_cross_product_minors: $route.query.can_cross_product_minors,
          },
        }"
        v-if="this.$route.params.product_id"
        ><span>商品取り扱い<br />ページに戻る</span></router-link
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "BackorderHeader",
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>