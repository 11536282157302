<template>
  <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
    <SelectedStoreBox :selectedShop='this.$root.selectedShop'/>
    <div class="innerContent01">
      <div class="gray-box-column"><!--タグ追加-->
        <CartBox :productsInCart='this.$root.productsInCart' @getProductsInCart='this.$root.callGetProductsInCart' />
        <div class="boxInfo01-wrapper"><!--タグ追加-->
          <div class="boxInfo01">
            <h2 class="ttlInfo01">お客様情報の登録</h2>
            <div class="info09">
              <ul class="listInfo01" v-if="this.$root.productsInCart.length > 0">
                <li>
                  <h3 class="ttlInfo03 fontGray">携帯電話番号</h3>
                  <p class="txtInfo05">{{ `*********${this.$root.productsInCart[0].tel_no.slice(-2)}` }}</p>
                </li>
                <li>
                  <h3 class="ttlInfo03 fontGray">氏名（カタカナ）</h3>
                  <p class="txtInfo05">{{ this.$root.productsInCart[0].name }}</p>
                  <p class="txtInfo09">※ご登録後はキャンセルできません。ご了承ください。</p>
                  <p class="txtInfo09 bold" v-for="errorMessage in this.errorMessages" :key="errorMessage.id">
                    {{ errorMessage }}
                  </p>
                </li>
                <li>
                </li>
              </ul>
            </div>
            <div class="info08">
              <ul class="listBtn">
                <li class="btnBack02">
                  <router-link
                    :to="{
                      name: 'backorder_03',
                      query: Object.assign({},$route.query),
                    }"
                    >修正する</router-link
                  >
                </li>
                <li class="btnRed01" >
                  <a @click="submit()">ご登録確定</a>
                </li>
              </ul>
            </div>
          </div>
        </div><!--タグ追加-->
      </div><!--タグ追加 /.gray-box-column-->
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import utils from "../../packs/mixins/utils.js";

import SelectedStoreBox from "../parts/SelectedStoreBox"
import CartBox from "../parts/CartBox"

export default {
  name: "Backorder04",
  props: {
  },
  components: {
    SelectedStoreBox,
    CartBox,
  },
  mixins: [utils],
  data() {
    return {
      errorMessages: [],
    };
  },
  computed: {
  },
  methods: {
    onError(info) {
      console.log(info);
      if (info.data.errors) {
        for(let error of info.data.errors) {
          this.errorMessages.push(error.message);
        }
      }
      this.$root.onDefaultFailureRequest(info);
    },
    callbackSubmit() {
      this.$root.callGetProductsInCart();
      this.$router.push({
        name: "backorder_05",
        query: Object.assign({},this.$route.query),
      });
      this.$root.hideLoading()
    },
    submit() {
      this.$root.showLoading();

      this.errorMessages = [];

      const user_reserve_ids = this.$root.productsInCart.map(i => i.id);
      Api.completeBackorder(
        user_reserve_ids,
        this.callbackSubmit,
        this.onError,
      );
    },
  },
  mounted() {
    this.$root.callGetProductsInCart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
  .mainContent {
    .boxInfo01 {
      .listInfo01 {
        li { 
          .txtInfo09 {
            text-align: left;
            margin-bottom: 1rem;
          }
          p:last-child {
            @media print, screen and (min-width: 1025px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
</style>
