<template>
  <div>
    <FaqHeader />
    <router-view/>
  </div>
</template>

<script>
import FaqHeader from '../global/FaqHeader'

export default {
  name: 'FaqLayout',
  components: {
    FaqHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = '';
  },
}
</script>

<style scoped>
</style>
