<template>
  <div class="mainContent" :class="{ 'is-hide-header' : is_no_header }">
    <SelectedStoreBox :selectedShop='this.$root.selectedShop'/>
    <div class="innerContent01">
      <div class="gray-box-column"><!--タグ追加-->
        <CartBox :productsInCart='this.$root.productsInCart' @getProductsInCart='this.$root.callGetProductsInCart' />
        <div class="boxInfo01-wrapper"><!--タグ追加-->
          <div class="boxInfo01">
            <h2 class="ttlInfo01">お客様情報の登録</h2>
            <div class="info04">
              <p class="txtInfo03">ご注文の確認用の連絡先を登録してください。</p>
            </div>
            <div class="info07">
              <h3 class="ttlInfo03">携帯電話番号</h3>
              <p class="txtInfo05" v-if="this.$root.productsInCart.length > 0">{{ `*********${this.$root.productsInCart[0].tel_no.slice(-2)}` }}</p>
              <h3 class="ttlInfo03">氏名（カタカナ）</h3>
              <p class="inputInfo01">
                <input
                  type="text"
                  placeholder="ウエルシア タロウ"
                  v-model="name"
                />
              </p>
              <p class="error" v-if="this.messageNameError.length">
                {{ this.messageNameError }}
              </p>
            </div>

            <div class="info11">
              <p class="txtInfo07">
                <label for="check01"
                  ><input
                    type="checkbox"
                    id="check01"
                    class="checkBox"
                    v-model="check"
                  />以下の注意事項、利用規約（プライバシーポリシー）に同意する</label
                >
              </p>
              <p class="txtInfo08">
                <a @click="openModal">
                  ※注意事項、利用規約（プライバシーポリシー）について確認する
                </a>
              </p>
            </div>

            <div class="info08">
              <ul class="listBtn">
                <li class="btnBack02">
                  <router-link
                    :to="{
                      name: 'backorder_02_1',
                      query: Object.assign({},$route.query),
                    }"
                    >戻る</router-link
                  >
                </li>
                <li class="btnRed01 btnCheck disabled">
                  <a @click="comfirm()">登録する</a>
                </li>
              </ul>
            </div>
          </div>
        </div><!--タグ追加-->
      </div><!--タグ追加 /.gray-box-column-->
    </div>
    <PrivacyPolicyModal @closeModal="closeModal" />
  </div>
</template>

<script>
import $ from "jquery";
import Api from "../../lib/api";
import utils from "../../packs/mixins/utils.js";

import SelectedStoreBox from "../parts/SelectedStoreBox"
import CartBox from "../parts/CartBox"
import PrivacyPolicyModal from "../parts/PrivacyPolicyModal";

export default {
  name: "Backorder03",
  props: {
  },
  components: {
    SelectedStoreBox,
    CartBox,
    PrivacyPolicyModal,
    // BoxItem,
  },
  mixins: [utils],
  data() {
    return {
      name: "",
      messageNameError: "",
      check: false,
    };
  },
  computed: {
  },
  methods: {
    comfirm() {
      if (!this.check) {
        return;
      }

      if (this.name == null || this.name.length == 0) {
        this.messageNameError = "氏名（カタカナ）を入力してください";
      } else {
        this.messageNameError = "";
      }
      if (
        this.messageNameError.length > 0
      ) {
        return;
      }

      this.callRegisterNameApi();
    },
    callbackRegisterName: function () {
      this.$router.push({
        name: "backorder_04",
        query: Object.assign({},this.$route.query),
      });
      this.$root.hideLoading()
    },
    callRegisterNameApi() {
      this.$root.showLoading();

      const user_reserve_ids = this.$root.productsInCart.map(i => i.id);
      Api.registerName(
        user_reserve_ids,
        this.name,
        this.callbackRegisterName,
        this.$root.onDefaultFailureRequest
      );
    },
    openModal() {
      $("#itemModal").fadeIn();
      return false;
    },
    closeModal() {
      $("#itemModal").fadeOut();
    },
    jquery() {
      // jQuery
      $(function () {
        // $(".btnModal01").click(function () {
        //   $("#itemModal").fadeIn();
        //   return false;
        // });

        // $("#itemModalClose , #itemOverlay").click(function () {
        //   $("#itemModal").fadeOut();
        // });

        $(".checkBox").on("click", function () {
          if ($(this).prop("checked") == false) {
            $(".btnCheck").addClass("disabled");

            this.check = false;
          } else {
            $(".btnCheck").removeClass("disabled");

            this.check = true;
          }
        });
      });
    },
  },
  mounted() {
    this.$root.callGetProductsInCart();
    this.jquery();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .error {
    font-size: 1.6rem;
    color: #dd4141;
  }
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>
