import { render, staticRenderFns } from "./BackorderHistoriesLayout.vue?vue&type=template&id=1e677249&scoped=true&"
import script from "./BackorderHistoriesLayout.vue?vue&type=script&lang=js&"
export * from "./BackorderHistoriesLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e677249",
  null
  
)

export default component.exports