<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div id="mv-list" v-if="main_product_pickups.length > 0">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="main_product_pickup in main_product_pickups" :key="main_product_pickup.id">
            <div v-if="main_product_pickup.product">
              <router-link
                :to="{
                  name: 'cosmetics_02',
                  params: {
                    product_id: main_product_pickup.product.id
                  },
                  query: {
                    product_major_id: $route.query.product_major_id,
                    product_middle_id: $route.query.product_middle_id,
                    product_minor_id: $route.query.product_minor_id,
                  },
                }">
                <img class="pc" :src="$root.$baseApiURL + main_product_pickup.product_pc_image_url" alt="">
                <img class="sp" :src="$root.$baseApiURL + main_product_pickup.product_sp_image_url" alt="">
              </router-link>
            </div>
            <div v-else>
              <img class="pc" :src="$root.$baseApiURL + main_product_pickup.product_pc_image_url" alt="">
              <img class="sp" :src="$root.$baseApiURL + main_product_pickup.product_sp_image_url" alt="">
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="brand-list-wrapper" v-if="sub_product_pickups.length > 0"><!--タグ追加-->
        <div id="brand-list">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="sub_product_pickup in sub_product_pickups" :key="sub_product_pickup.id">
              <div v-if="sub_product_pickup.product">
                <router-link
                  :to="{
                    name: 'cosmetics_02',
                    params: {
                      product_id: sub_product_pickup.product.id
                    },
                    query: {
                      product_major_id: $route.query.product_major_id,
                      product_middle_id: $route.query.product_middle_id,
                      product_minor_id: $route.query.product_minor_id,
                    },
                  }">
                  <img :src="$root.$baseApiURL + sub_product_pickup.product_sp_image_url" alt="">
                  <div class="label">{{ sub_product_pickup.description }}</div>
                </router-link>
              </div>
              <div v-else>
                <img :src="$root.$baseApiURL + sub_product_pickup.product_sp_image_url" alt="">
                <div class="label">{{ sub_product_pickup.description }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div><!--タグ追加-->

      <div class="category section">
        <div class="inner">
          <h2 class="category-title"> <img src="../../assets/images/cosmetics/ttl_category.png" alt="CATEGORY"> </h2>
          <ul class="category-list">
            <li class="category-item" v-for="product_category in product_categories" :key="product_category.id">
              <a data-filter=".filter-01" @click="selectCategory(product_category)">
                <h3 class="category-name">{{ product_category.name }}</h3>
                <div class="img">
                  <img :src="product_category.image_path" :alt="product_category.name">
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="resultTop">
        <p class="txtResult02">全<strong>{{ total_count }}</strong>商品</p>
      </div>
      <div class="filter-box">
        <select class="filter" v-model="selected_product_category">
          <option value="" hidden>カテゴリから探す</option>
          <option v-for="product_category in product_categories" :key="product_category.id" :value="product_category.value">{{ product_category.name }}</option>
        </select>
        <select class="sort" v-model="selected_sort">
          <!-- <option hidden>並べ替え</option> -->
          <option value="">並べ替え</option>
          <option value="product_name asc">名前順（昇順）</option>
          <option value="product_name desc">名前順（降順）</option>
          <option value="price asc">価格順（昇順）</option>
          <option value="price desc">価格順（降順）</option>
        </select>
      </div>

      <div class="resultOuter">
        <div class="resultInner">
          <div class="resultBox filter-01" v-for="product in products" :key="product.id">
            <router-link
              class="resultLink"
              :to="{
                name: 'cosmetics_02',
                params: {
                  product_id: product.id,
                },
                query: {
                  product_major_id: $route.query.product_major_id,
                  product_middle_id: $route.query.product_middle_id,
                  product_minor_id: $route.query.product_minor_id,
                },
              }">
              <div class="imageBlock">
                <v-lazy-image
                  :src="$root.$baseApiURL + product.product_images[0].product_image_url" alt=""
                  :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                  v-if="product.product_images[0].product_image_url != ''"
                  class="main"
                />
                <NoImage 
                  :categoryName='product.product_minor.product_minor_name' 
                  :productMajorId='product.product_major_id'
                  v-else class="main" />
                <div class="color-wrapper">
                  <img :src="$baseApiURL + product.product_variation_image_url" alt="" v-if="product.product_variation_image_url != ''" class="color">
                  <p>{{ product.product_variation_name }}</p>
                </div>
              </div>
              <div class="infoBlock">
                <div class="pointBox" v-for="product_point in product.product_points"
                    :key="product_point.id">
                  <PointBox :productPoint="product_point" :position="'left'" />
                </div>
                <div class="textBox">  
                  <p class="txtItem02">{{ product.product_name | ellipsis }}</p>
                  <p class="txtPrice01">販売価格：<strong class="en">{{ product.price | numberFormat }}円</strong> (税込：{{ product.taxed_price | taxedPrice }}円)</p>
                </div>
                <span class="btnDetail">詳しく見る</span>
              </div>
            </router-link>
            <Favorite :product_id="product.id" :favorite="product.favorite" />
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" spinner="circles" :distance="1000">
          <div slot="no-results" class="no-results">検索結果はありません</div>
          <div slot="no-more" class="no-more"></div>
        </infinite-loading>
      </div>
    </div>
  </div>

</template>
<script>
import Api from "../../lib/api";
import $ from 'jquery';
import Swiper, { Autoplay, Thumbs, Navigation } from "swiper";
import utils from "../../packs/mixins/utils.js";
Swiper.use([Autoplay, Thumbs, Navigation]);
// import jQueryBridget from 'jquery-bridget';
// import Isotope from "isotope-layout";

import Favorite from "../parts/Favorite";
import NoImage from "../parts/NoImage";
import PointBox from "../parts/PointBox.vue";

export default {
  name: "Cosmetics01",
  components: {
    Favorite,
    NoImage,
    PointBox,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      main_product_pickups: [],
      sub_product_pickups: [],
      product_categories: [],
      products: [],
      total_count: 0,
      current_page: 1,
      infiniteId: 0,
      auto_scroll: false,
      selected_product_category: "",
      selected_sort: "",
      product_categories_mst: [
        {id: 1, name: "リップ", value: "リップ", image_path: require("../../assets/images/cosmetics/img_category-item01.png"), class_name: "filter-01"},
        {id: 2, name: "チーク", value: "チーク", image_path: require("../../assets/images/cosmetics/img_category-item02.png"), class_name: "filter-02"},
        {id: 3, name: "アイメイク", value: "アイメイク", image_path: require("../../assets/images/cosmetics/img_category-item03.png"), class_name: "filter-03"},
        {id: 4, name: "アイブロウ", value: "アイブロウ", image_path: require("../../assets/images/cosmetics/img_category-item04.png"), class_name: "filter-04"},
        {id: 5, name: "ベースメイク", value: "ベースメイク", image_path: require("../../assets/images/cosmetics/img_category-item05.png"), class_name: "filter-05"},
        {id: 6, name: "スキンケア", value: "スキンケア", image_path: require("../../assets/images/cosmetics/img_category-item06.png"), class_name: "filter-06"},
        {id: 7, name: "ヘアケア", value: "ヘアケア", image_path: require("../../assets/images/cosmetics/img_category-item07.png"), class_name: "filter-07"},
        {id: 8, name: "その他", value: "その他", image_path: require("../../assets/images/cosmetics/img_category-item08.png"), class_name: "filter-08"},
      ],
      product_category_mst_all : {id: 0, name: "すべて", value: "", image_path: require("../../assets/images/cosmetics/img_category-item09.png"), class_name: "filter-09"},
    };
  },
  filters: {
    ellipsis(text) {
      return text.length >= 38 ? text.slice(0, 38 - "...".length) + "..." : text
    },
  },
  methods: {
    infiniteHandler($state) {
      var current_product_minor_id = 0;

      const onComplete = (info) => {
          if (current_product_minor_id !== this.$route.query.product_minor_id) { // product_minor_idが変わっていたら即時終了
            $state.complete();
            return;
          }
          if (this.products.length === 0 && this.auto_scroll) {          
            // スクロール
            $('body, html').animate({
              scrollTop: $('.resultTop').offset().top - $('#headMenu').height()
            }, 500)

            this.auto_scroll = false;
          }

          this.total_count = info.data.meta.total_count
          if (info.data.data.length != 0) {
            this.products = this.products.concat(info.data.data)
            this.current_page += 1
            $state.loaded();
          } else {
            $state.complete();
          }
          // this.$root.hideLoading()
      }

      current_product_minor_id = this.$route.query.product_minor_id; // サーバからデータ取得中にproduct_minor_idが変わる可能性があるため、取得する直前のproduct_minor_idを保存しあとで変わったかチェック

      // this.$root.showLoading()
      Api.searchProducts(
        null, // keyword
        this.$route.query.product_major_id, 
        this.$route.query.product_middle_id, 
        this.$route.query.product_minor_id, 
        null, // exists_points
        true, // exists_image
        this.selected_product_category,
        this.current_page,
        this.selected_sort.split(' ')[0],
        this.selected_sort.split(' ')[1],
        onComplete,
        this.$root.onDefaultFailureRequest
      )
    },
    resetHandler() {
      this.products = []
      this.total_count = 0
      this.current_page = 1
      this.infiniteId ++
    },
    selectCategory: function(product_category) {

      this.selected_product_category = product_category.value;
      
    },
    jquery: function() {
      const self = this;
      $(function(){
        if (self.main_product_pickups.length > 1) {
          new Swiper('#mv-list', {
            slidesPerView: 1,
            speed: 600,
            loop: true,
            spaceBetween: 30,
            centeredSlides: true,
            autoplay: {
              delay: 3000,
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
            },
          });
        }

        if ($(window).width() > 768) {
          new Swiper('#brand-list', {
            speed: 600,
            loop: true,
            spaceBetween: 0,
            autoplay: {
              delay: 3000,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            breakpoints: {
              1900: {
                slidesPerView: 5,
                spaceBetween: 35,
              },
              851: {
                slidesPerView: 4,
                spaceBetween: 35,
              },
              320: {
                slidesPerView: 2,
              }
            }
          });
        }
      });
    },
    setProductMinor: function(info) {
      this.main_product_pickups = info.data.product_pickups.filter(product_pickup => product_pickup.main_flag);
      this.sub_product_pickups = info.data.product_pickups.filter(product_pickup => !product_pickup.main_flag);
      this.product_categories = this.product_categories_mst.filter(product_category => info.data.product_categories.includes(product_category.name));
      this.product_categories.unshift(this.product_category_mst_all)
      this.$root.hideLoading();
      this.jquery();
    },
    callProductMinorApi() {
      this.$root.showLoading();
      Api.getProductMinor(
        this.$route.query.product_minor_id,
        this.setProductMinor,
        this.$root.onDefaultFailureRequest
      );
    }
  },
  computed: {
  },
  mounted() {
    this.callProductMinorApi();
    this.resetHandler();
  },
  updated() {
  },
  watch: {
    "$route" (to, from) {
      this.selected_product_category = ""; // 中分類や小分類が変わった時は選択中のカテゴリをクリア
      this.selected_sort = ""; // 中分類や小分類が変わった時は選択中の並べ替えをクリア
      
      if (from.query.product_minor_id !== to.query.product_minor_id) {
        this.callProductMinorApi();
        this.resetHandler();
      }
    },
    "selected_product_category"() {
      this.resetHandler();
      this.auto_scroll = true;
    },
    "selected_sort"() {
      this.resetHandler();
      this.auto_scroll = true;
    }
  },
}
</script>
<style lang="scss" scoped>
  // .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  //   content: '';
  // }
  // .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  //   content: '';
  // }
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/cosmetics.scss";
</style>