function getLocation(onSuccess, onFailure) {
  if (!navigator.geolocation) {
    alert(
      "現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。"
    );
    onFailure();
    return;
  }

  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  const error = (error) => {
    switch (error.code) {
      case 1: //PERMISSION_DENIED
        alert("位置情報の利用が許可されていません");
        break;
      case 2: //POSITION_UNAVAILABLE
        alert("現在位置が取得できませんでした");
        break;
      case 3: //TIMEOUT
        alert("タイムアウトになりました");
        break;
      default:
        alert("現在位置が取得できませんでした");
        break;
    }
    onFailure();
  }

  navigator.geolocation.getCurrentPosition(
    onSuccess,
    error,
    options
  );
}

export default {
  getLocation,
}