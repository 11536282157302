<template>
  <div class="modal" id="modalCategory4">
    <div id="categoryOverlay"></div>
    <p id="categoryModalClose01" class="modal-close" @click="closeModal()">閉じる</p>
    <div class="mCatBox">
      <div class="mCatBoxInner">
        <p class="mTitle01">{{ $props.productMiddleName }}</p>
        <div class="mListBox">
          <ul class="mListCat">
            <li v-for="product_minor in product_minors"
                :key="product_minor.id">
              <a href="javascript:void(0)" class="btnModalCategory" @click="selectBrandMinor(product_minor)">
                <img :src="$root.$baseApiURL + product_minor.product_image_url" alt="" v-if="product_minor.product_image_url !== ''">
                <span v-else>{{ product_minor.product_minor_name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from "jquery";

export default {
  name: "MiddleBrandModal",
  components: {
  },
  props: {
    productMiddleId: Number,
    productMiddleName: String,
  },
  data() {
    return {
      product_minors: [],
    };
  },
  methods: {
    selectBrandMinor(object) {
      $('.modal').fadeOut();

      this.$router.push({ 
        name: 'cosmetics_01', 
        query: {
          product_major_id: 4, // product_major_id :4(カウンセリング化粧品)
          product_middle_id: this.productMiddleId,
          product_minor_id: object.id,
        }
      })
    },
    closeModal() {
      // クエリパラメタにmodalがあれば削除
      var newQuery = Object.assign({}, this.$route.query);
      delete newQuery.selected_product_major_id;
      delete newQuery.selected_product_middle_id;
      delete newQuery.modal;
      this.$router.push({query: newQuery})

      $('.modal').fadeOut();
    },
    setProductMiddles: function (info) {
      this.product_minors = info.data;
      this.$root.hideLoading();
    },
    callProductMinorsApi() {
      this.$root.showLoading();
      Api.getProductMiners(this.productMiddleId, null, this.setProductMiddles, this.$root.onDefaultFailureRequest);
    },
  },
  mounted() {
  },
  computed: {
  },
  watch: {
    "$props.productMiddleId"() {
      if (this.$props.productMiddleId !== 0) {
        this.callProductMinorsApi();
      }
    },
  },

};
</script>
<style lang="scss" scoped>
#modalCategory4 {
  visibility: visible; // MiddleCategoryHeaderがvisibility: hidden;でも表示できるようにする
}


  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/store_map.scss";
</style>