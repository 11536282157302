<template>
  <div id="dMenu">
    <div id="dMenuList">
      <div class="swiper-wrapper">
        <div
          :class="itemStyle(rack_stand.rack_set_id, rack_stand.stand_no)"
          v-for="rack_stand in this.rack_stands"
          :key="rack_stand.id"
        >
          <router-link
            :to="{
              name: 'product_detail_03',
              query: {
                product_major_id: $route.query.product_major_id,
                product_middle_id: $route.query.product_middle_id,
                product_minor_id: $route.query.product_minor_id,
                rack_set_id: rack_stand.rack_set_id,
                stand_no: rack_stand.stand_no,
                can_cross_product_minors: $route.query.can_cross_product_minors,
              },
            }"
          >
            <span>
              {{ rack_stand.stand_name }}
            </span>
          </router-link>
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import $ from "jquery";
import Swiper from "swiper";

var swiper;

export default {
  name: "MinerCategoryHeader",
  props: {
  },
  data() {
    return {
      rack_stands: [],
    };
  },
  methods: {
    itemStyle: function(rackSetId, standNo) {
      if (rackSetId === this.$route.query.rack_set_id && standNo == this.$route.query.stand_no) {
        return "dMenuCurrent swiper-slide"
      } else {
        return "swiper-slide"
      }
    }, 
    setRackSets: function (info) {
      this.rack_stands = info.data.rack_set_stands;
      var index = this.rack_stands.findIndex((rack_stand) => rack_stand.rack_set_id == this.$route.query.rack_set_id && rack_stand.stand_no == this.$route.query.stand_no)

      this.jquery(index)
    },
    callRackSetsApi() {
      if (this.$route.query.can_cross_product_minors !== undefined) {  // 小分類選択なしで開く true ありで開く false
        Api.getRackSets(this.$route.query.product_middle_id, null, this.setRackSets, this.$root.onDefaultFailureRequest, this.$root.onDefaultFailureRequest);
      } else {
        Api.getRackSets(null, this.$route.query.product_minor_id, this.setRackSets, this.$root.onDefaultFailureRequest, this.$root.onDefaultFailureRequest);
      }
    },
    jquery(index) {
      var self = this
      // jQuery
      $(function () {
        if (swiper === undefined) {
          $("#dMenuCurrent").index();
          swiper = new Swiper("#dMenuList", {
            speed: 600,
            loop: false,
            centeredSlides: true,
            slidesPerView: 3,
            initialSlide: index,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // レスポンシブ用追加設定
            breakpoints: {
              1025: {
                loop: false,
                slidesPerView: 'auto'
              }
            }
          });

          swiper.on('slideChange', function () {
            self.$router.push({
              name: "product_detail_03",
              query: {
                product_major_id: self.$route.query.product_major_id,
                product_middle_id: self.$route.query.product_middle_id,
                product_minor_id: self.$route.query.product_minor_id,
                rack_set_id: self.rack_stands[swiper.realIndex].rack_set_id,
                stand_no: self.rack_stands[swiper.realIndex].stand_no,
                can_cross_product_minors: self.$route.query.can_cross_product_minors,
              },
            });
          });
        } else {
          swiper.slideTo(index);
        }
      });
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.query.product_minor_id === to.query.product_minor_id) {
        if(swiper === undefined) { // swiperがインスタンス化前に呼ばれることがあるため事前チェック
          return;
        }
        const index = this.rack_stands.findIndex((rack_stand) => rack_stand.rack_set_id == to.query.rack_set_id && rack_stand.stand_no == to.query.stand_no)
        swiper.slideTo(index);
      } else {
        swiper = undefined;
        this.rack_stands = [];
        this.callRackSetsApi();
      }
    },
  },
  mounted() {
    // swiperを初期化
    swiper = undefined;

    this.callRackSetsApi();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  //   content: '';
  // }
  // .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  //   content: '';
  // }
  @import "../../assets/stylesheets/responsive/base.scss";
</style>
