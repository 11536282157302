import Vue from 'vue'
import axios from "axios"

axios.defaults.withCredentials = true;

function getProductMiddles(product_major_id, onSuccess, onFailure) {
  callGet(
    "/v2/product_classifications/majors/" + product_major_id + "/middles",
    onSuccess,
    onFailure,
  )
}
function getProductMiners(product_middle_id, exists_rack_set, onSuccess, onFailure) {
  if(exists_rack_set) {
    callGet(
      "/v2/product_classifications/middles/" + product_middle_id + "/minors?exists_rack_set=true",
      onSuccess,
      onFailure,
    )
  } else {
    callGet(
      "/v2/product_classifications/middles/" + product_middle_id + "/minors",
      onSuccess,
      onFailure,
    )
  }
}
function getProductMinor(product_minor_id, onSuccess, onFailure) {
  callGet(
    "/v2/product_classifications/minors/" + product_minor_id,
    onSuccess,
    onFailure,
  )
}
function getRackSets(product_middle_id, product_minor_id, onSuccess, onFailure) {
  var params = {}
  if (product_middle_id != null) {
    params.product_middle_id = product_middle_id
  }
  if (product_minor_id != null) {
    params.product_minor_id = product_minor_id
  }
  callGet(
    "/v2/rack_sets?" + new URLSearchParams(params).toString(),
    onSuccess,
    onFailure,
  )
}
function getRackSetItems(rack_set_id, stand_no, row_no, onSuccess, onFailure) {
  if (row_no == null) {
    callGet(
      "/v2/rack_sets/" + rack_set_id + "/rack_set_items/?stand_no=" + stand_no,
      onSuccess,
      onFailure,
      )
  } else {
    callGet(
      "/v2/rack_sets/" + rack_set_id + "/rack_set_items/?stand_no=" + stand_no + "&row_no=" + row_no,
      onSuccess,
      onFailure,
      )
  }
}
function getProduct(product_id, onSuccess, onFailure) {
  callGet(
    "/v2/products/" + product_id + "/summary",
    onSuccess,
    onFailure,
  )
}

function searchProducts(keyword, product_major_id, product_middle_id, product_minor_id, exists_points, exists_image, product_category, page, key, order, onSuccess, onFailure) {
  var params = {}
  if (keyword) {
    params.keyword = keyword
  }
  if (product_major_id) {
    params.product_major_id = product_major_id
  }
  if (product_middle_id) {
    params.product_middle_id = product_middle_id
  }
  if (product_minor_id) {
    params.product_minor_id = product_minor_id
  }
  if (exists_points) {
    params.exists_points = exists_points
  }
  if (exists_image) {
    params.exists_image = exists_image
  }
  if (product_category) {
    params.product_category = product_category
  }
  if (page) {
    params.page = page
  }
  if (key) {
    params.key = key
  }
  if (order) {
    params.order = order
  }
  callGet(
    "/v2/products?" + new URLSearchParams(params).toString(),
    onSuccess,
    onFailure,
  )
}
function getHandling(shop_id, product_id, onSuccess, onFailure) {
  callGet(
    "/v2/shops/" + shop_id + "/handings/" + product_id,
    onSuccess,
    onFailure,
  )
}

function getUserReservesInCart(onSuccess, onFailure) {  
  callGet(
    "/v2/user_reserves/?cart=true",
    onSuccess,
    onFailure,
  )
}
function addToCart(shop_id, product_id, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('shop_id', shop_id)
  params.append('product_id', product_id)
  
  callPost(
    "/v2/user_reserves/",
    params,
    onSuccess,
    onFailure,
  )
}
function removeFromCart(user_reserve_id, onSuccess, onFailure) {
  callDelete(
    "/v2/user_reserves/" + user_reserve_id,
    onSuccess,
    onFailure,
  )
}
function editInCart(user_reserve_id, quantity, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('quantity', quantity)
  
  callPut(
    "/v2/user_reserves/" + user_reserve_id,
    params,
    onSuccess,
    onFailure,
  )
}

function sendAuthCode(user_reserve_ids, tel_no, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('tel_no', tel_no);
  params.append('user_reserve_ids', user_reserve_ids);
  
  callPost(
    "/v2/user_reserves/send_auto_code",
    params,
    onSuccess,
    onFailure,
  )
}

function verifyAuthCode(user_reserve_ids, auth_code, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('auth_code', auth_code);
  params.append('user_reserve_ids', user_reserve_ids);

  callPost(
    "/v2/user_reserves/verify",
    params,
    onSuccess,
    onFailure,
  )
}

function registerName(user_reserve_ids, name, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('name', name);
  params.append('user_reserve_ids', user_reserve_ids);

  callPost(
    "/v2/user_reserves/register_name",
    params,
    onSuccess,
    onFailure,
  )
}

function completeBackorder(user_reserve_ids, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('user_reserve_ids', user_reserve_ids);
  callPost(
    "/v2/user_reserves/complete",
    params,
    onSuccess,
    onFailure,
  )
}
function searchShops(product_id, place, zipcode, onSuccess, onFailure) {
  var params = new URLSearchParams()
  if (product_id) {
    params.append('product_id', product_id)
  }
  if (place) {
    params.append('place', place)
  }
  if (zipcode) {
    params.append('zipcode', zipcode)
  }  callGet(
    "/v2/shops?" + params.toString(),
    onSuccess,
    onFailure,
  )
}
function searchNearShops(product_id, latitude, longitude, onSuccess, onFailure) {
  var params = new URLSearchParams()
  if (product_id) {
    params.append('product_id', product_id)
  }
  if (latitude) {
    params.append('latitude', latitude)
  }
  if (longitude) {
    params.append('longitude', longitude)
  }
  callGet(
    "/v2/shops/neighborhoods?" + params.toString(),
    onSuccess,
    onFailure,
  )
}

function postFavorite(product_id, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('product_id', product_id)
  callPost(
    "/v2/favorites/",
    params,
    onSuccess,
    onFailure,
  )
}

function deleteFavorite(product_id, onSuccess, onFailure) {
  callDelete(
    "/v2/favorites?product_id=" + product_id,
    onSuccess,
    onFailure,
  )
}

function getFavorites(onSuccess, onFailure) {
  callGet(
    "/v2/favorites/",
    onSuccess,
    onFailure,
  )
}

function updateFavoritesOrder(product_ids, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('product_ids', product_ids)
  callPut(
    "/v2/favorites/update_order",
    params,
    onSuccess,
    onFailure,
  )
}

function getUserReserves(onSuccess, onFailure) {
  callGet(
    "/v2/user_reserves/",
    onSuccess,
    onFailure,
  )
}

function postMyshop(shop_id, onSuccess, onFailure) {
  var params = new URLSearchParams()
  params.append('shop_id', shop_id)
  callPost(
    "/v2/myshops/",
    params,
    onSuccess,
    onFailure,
  )
}

function putSelectedMyShop(shop_id, onSuccess, onFailure) {
  var params = new URLSearchParams()
  callPut(
    "/v2/myshops/selected/?shop_id=" + shop_id,
    params,
    onSuccess,
    onFailure,
  )
}

function deleteMyshop(shop_id, onSuccess, onFailure) {
  callDelete(
    "/v2/myshops/?shop_id=" + shop_id,
    onSuccess,
    onFailure,
  )
}

function getMyshops(product_id, onSuccess, onFailure) {
  if (product_id != null) {
    var params = new URLSearchParams()
    params.append('product_id', product_id)
    callGet(
      "/v2/myshops/?" + params.toString(),
      onSuccess,
      onFailure,
    )  
  } else {
    callGet(
      "/v2/myshops/",
      onSuccess,
      onFailure,
    )  
  }
}

function getNews(onSuccess, onFailure) {
  callGet(
    "/v2/news/",
    onSuccess,
    onFailure,
  )
}

function getFaqGroups(onSuccess, onFailure) {
  callGet(
    "/v2/faq_groups/",
    onSuccess,
    onFailure,
  )
}


function callGet(url, onSuccess, onFailure) {
  axios
    .get(Vue.prototype.$baseApiURL + url, {
      withCredentials: true
    })
    .then(res => {
      console.log(res)
      onSuccess(res)
    })
    .catch(e => {
      console.log("Error occurred in API")
      console.log(e, e.response)
      onFailure(e.response)
    });
}

function callPost(url, params, onSuccess, onFailure) {
  axios
    .post(Vue.prototype.$baseApiURL + url, params)
    .then(res => {
      console.log(res)
      onSuccess(res)
    })
    .catch(e => {
      console.log("Error occurred in API")
      console.log(e, e.response)
      onFailure(e.response)
    });
}

function callPut(url, params, onSuccess, onFailure) {
  axios
    .put(Vue.prototype.$baseApiURL + url, params)
    .then(res => {
      console.log(res)
      onSuccess(res)
    })
    .catch(e => {
      console.log("Error occurred in API")
      console.log(e, e.response)
      onFailure(e.response)
    });
}

function callDelete(url, onSuccess, onFailure) {
  axios
    .delete(Vue.prototype.$baseApiURL + url)
    .then(res => {
      console.log(res)
      onSuccess(res)
    })
    .catch(e => {
      console.log("Error occurred in API")
      console.log(e, e.response)
      onFailure(e.response)
    });
}

export default {
  getProductMiddles,
  getProductMiners,
  getProductMinor,
  getRackSets,
  getRackSetItems,
  getProduct,
  getHandling,
  getUserReservesInCart,
  addToCart,
  removeFromCart,
  editInCart,
  sendAuthCode,
  verifyAuthCode,
  registerName,
  completeBackorder,
  searchProducts,
  searchShops,
  searchNearShops,
  postFavorite,
  deleteFavorite,
  getFavorites,
  updateFavoritesOrder,
  getUserReserves,
  postMyshop,
  putSelectedMyShop,
  deleteMyshop,
  getMyshops,
  getNews,
  getFaqGroups,
}