<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div class="whatIsThis">
        <div class="whatIsThis__title">おうちウエルシアでできること</div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            店内にいる感覚で、棚から検索
          </div>
          <div class="whatIsThis__content__desc">
            お店にいるような感覚で、探したい商品を売り場の棚から検索。目当ての商品だけではなく、関連する商品が周囲にあることで、直感的なお買い物を楽しむ感覚が味わえます。
          </div>
          <div class="whatIsThis__content__caution">
            ※実際の各店の棚とは異なります。<br />
            ※現在、画像の登録が無い商品は棚で非表示としています。<br />
            フリーワード検索では画像の登録がない商品も検索できます。
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_1.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            フリーワードやJANコードの検索にも対応
          </div>
          <div class="whatIsThis__content__desc">
            欲しい商品が決まっていて素早く探したい場合は、メニューバーの「検索」から、任意の商品名やJANコードですぐに目的の商品を検索することができます。<br />
            カテゴリーの絞り込みに加えて、ボーナスポイントの絞り込みにも対応。
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_2.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            商品ごとに表示項目を見やすく精査<br />
            オンライン・店頭双方の情報にアクセス
          </div>
          <div class="whatIsThis__content__desc">
            基本となる商品名、価格、JANコード表示はもちろん、オンラインショップの取り扱いや店舗ごとの取り扱い状況を確認。<br />
            お客様がどこにいても、店舗／オンラインショップ双方にアクセスできる入り口をご用意しております。
          </div>
          <div class="whatIsThis__content__caution">
            ※販売価格は店舗ごとに異なる場合があるため、参考価格を表示しています。
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_3.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            医薬品は剤形や添付文書まで<br />
            商品詳細を整備
          </div>
          <div class="whatIsThis__content__desc">
            医薬品については、ほぼすべての商品のパッケージなども確認頂け、更に店頭では見ることができない剤形や添付文書まで情報を掲載。
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_4.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            店舗ごとに商品の取扱状況がわかる
          </div>
          <div class="whatIsThis__content__desc">
            店舗ごとの商品の取り扱い状況がわかるため、欲しい商品がこれから行く店舗で取り扱っているかを事前に確認することができます。<br />
            取り扱いがない商品は、オンラインで取り寄せ依頼をすることができます。
          </div>
          <div class="whatIsThis__content__caution">
            ※店舗により取り寄せ不可の商品もございます<br />
            ※取り扱いデータの更新は1日1回（毎朝7時前後）行われます
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_5.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            店舗に取り扱いがなくても取り寄せできる
          </div>
          <div class="whatIsThis__content__desc">
            欲しい商品が近くの店舗になくても、スマホ上で名前と携帯電話の番号の入力をするだけで、かんたんに取り寄せすることができます。<br />
            商品の入荷連絡の通知もスマホ（SMS）で受信。
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_6.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            現在地から、近くのウエルシア店舗を検索！<br />
            取り扱い状況も確認！
          </div>
          <div class="whatIsThis__content__desc">
            外出中など、欲しい商品をすぐに買いたい！<br />
            といった時、近くにあるウエルシアを検索。<br />
            お目当ての商品の取り扱いも確認し、安心して来店
          </div>
          <div class="whatIsThis__content__caution">
            ※MAP上で、現在地周辺店舗の取扱状況を確認
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_7.png">
          </div>
        </div>
        <div class="whatIsThis__content">
          <div class="whatIsThis__content__title">
            マイリストの活用で、より簡単！便利に！
          </div>
          <div class="whatIsThis__content__desc">
            ①よく使っている店舗をマイ店舗に登録
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_8.png">
          </div>
          <div class="whatIsThis__content__desc">
            ②良く買っている商品をマイリストに保存
          </div>
          <div class="whatIsThis__content__image">
            <img src="../../assets/images/else/img_what_is_this_9.png">
          </div>
        </div>
        <div class="whatIsThis__back">
          <router-link :to="{ name: 'top' }" class="whatIsThis__back__button">
            TOPに戻る
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatIsThis01",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/what_is_this.scss";
</style>
