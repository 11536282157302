<template>
  <div>
    <StoreMapHeader />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import StoreMapHeader from '../global/StoreMapHeader'
import Footer from "../global/Footer"

export default {
  name: 'StoreMap',
  components: {
    StoreMapHeader,
    Footer,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = 'blue';
  },
}
</script>

<style scoped>
</style>
