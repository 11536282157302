<template>
  <div>
    <div class="title02">
      <p>選択中の店舗</p>
      <div class="ttl" v-if="selectedShop">{{ selectedShop.shop_name }}</div>
      <div class="ttl" v-else>なし</div>
    </div>
  </div>
</template>


<script>
export default {
  name: "SelectedStoreBox",
  components: {
    // MyStoreList,
  },
  props: {
    selectedShop: Object
  },
  data() {
    return {
      user_reserves: [],
    }
  },
  computed: {
  },
  methods: {
    openShopSite(shop) {
      window.open(shop.site_url)
    },
  },
  watch: {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder.scss";
</style>