<template>
  <div id="specialModal">
    <div id="specialOverlay"></div>
    <p id="specialModalClose">閉じる</p>
    <div class="slider">
      <div class="swiper-slide">
        <div class="explanation">
          <div class="explanation__image">
            <img :src="image" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swiper from "swiper";

export default {
  name: 'specialModal',
  props: {
    image: {
      type: String,
      default: ''
    }
  },
  methods: {
    jquery() {
      // jQuery
      $(function () {
        new Swiper('.slider', {
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });
      });
    },
  },
  mounted() {
    // jQuery
    $('.modalspecial').click(function () {
      $('#specialModal').fadeIn(0, function () {
      });
    });

    $('#specialModalClose , #specialOverlay').click(function(){
      $('#specialModal').fadeOut(0, function () {
      });
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mobile {
  #specialModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 102;
    display: none;
    #specialOverlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      // z-index: 1000;
      opacity: 0.8;
      background-color: #707070;
    }
    #specialModalClose {
      position: fixed;
      width: 10rem;
      height: 10rem;
      top: 0;
      right: 0;
      z-index: 1000;
      cursor: pointer;
      padding-top: 6rem;
      font-size: 2rem;
      color: #fff;
      text-align: center;
      background: #0185cf url("../../assets/images/common/ico_close.png") 50% 4rem no-repeat;
      background-position: 50% 2.5rem;
      background-size: 2.5rem auto;
      @media screen and (max-width: 850px) {
        width: calc(100 / 750 * 100vw);
        height: calc(100 / 750 * 100vw);
        padding-top: calc(50 / 750 * 100vw);
        font-size: calc(20 / 750 * 100vw);
        background-position: 50% calc(25 / 750 * 100vw);
        background-size: calc(18 / 750 * 100vw) auto;
      }
      @media screen {
        
      }
    }
    .slider {
      height: 100%;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        .explanation {
          margin: 0 5rem;
          &__desc {
            margin-top: 2rem;
            font-size: 1.2rem;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>