<template>
  <div id="myListModal">
    <div id="myListOverlay"></div>
    <p id="myListModalClose">閉じる</p>
    <div class="slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="explanation">
            <div class="swiper-button-prev"></div>
            <div class="explanation__image">
              <img src="../../assets/images/else/img_mylist_00.png" alt="">
            </div>
            <div class="swiper-button-next"></div>
            <div class="explanation__desc">
              ※マイリストは登録されたブラウザでのみ表示されます。
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="explanation">
            <div class="swiper-button-prev"></div>
            <div class="explanation__image">
              <img src="../../assets/images/else/img_mylist_01.png" alt="">
            </div>
            <div class="swiper-button-next"></div>
            <div class="explanation__desc">
              ※マイリストは登録されたブラウザでのみ表示されます。
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="explanation">
            <div class="swiper-button-prev"></div>
            <div class="explanation__image">
              <img src="../../assets/images/else/img_mylist_02.png" alt="">
            </div>
            <div class="swiper-button-next"></div>
            <div class="explanation__desc">
              ※マイリストは登録されたブラウザでのみ表示されます。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swiper from "swiper";

export default {
  name: 'MyListModal',
  methods: {
    jquery() {
      // jQuery
      $(function () {
        new Swiper('.slider', {
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });
      });
    },
  },
  mounted() {
    // jQuery
    const self = this;
    $('.modalMyList').click(function () {
      $('#myListModal').fadeIn(0, function () {
        self.jquery();
      });
    });

    $('#myListModalClose , #myListOverlay').click(function(){
      $('#myListModal').fadeOut(0, function () {
      });
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mobile {
  #myListModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2001;
    display: none;
    #myListOverlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      // z-index: 1000;
      opacity: 0.8;
      background-color: #707070;
    }
    #myListModalClose {
      position: fixed;
      width: 10rem;
      height: 10rem;
      top: 0;
      right: 0;
      z-index: 1000;
      cursor: pointer;
      padding-top: 6rem;
      font-size: 2rem;
      color: #fff;
      text-align: center;
      background: #0185cf url("../../assets/images/common/ico_close.png") 50% 4.4rem no-repeat;
      background-position: 50% 2.5rem;
      background-size: 2.5rem auto;
      @media screen and (max-width: 850px) {
        width: calc(100 / 750 * 100vw);
        height: calc(100 / 750 * 100vw);
        padding-top: calc(50 / 750 * 100vw);
        font-size: calc(20 / 750 * 100vw);
        background-position: 50% calc(25 / 750 * 100vw);
        background-size: calc(18 / 750 * 100vw) auto;
      }
    }
    .slider {
      height: 100%;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .swiper-wrapper {
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          .swiper-button-prev {
            &::after {
              content: "";
              margin: 1rem 0;
              width: 0;
              height: 0;
              border-right: 15px solid #fff;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
            }
            &.swiper-button-disabled {
              display: none;
            }
          }
          .swiper-button-next {
            &::after {
              content: "";
              margin: 1rem 0;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 15px solid #fff;
            }
            &.swiper-button-disabled {
              display: none;
            }
          }
          .explanation {
            margin: 0 5rem;
            &__desc {
              margin-top: 2rem;
              font-size: 1.2rem;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>