<template>
  <div class="modal main-modal-2" id="main-modal-2">
    <div class="modal-overlay"></div>
    <p class="modal-close" @click="closeModal()">閉じる</p>
    <div class="content">
      <div class="whitebox">
        <div class="ttl">マイ店舗の選択</div>
        <p class="note">※一部実施していないエリア・店舗がございます。<br>対象店舗は
          <router-link class="link01" :to="{ name: 'store_list_01' }">
            こちら。
          </router-link>
        </p>
        <div class="boxItem03">
          <div class="store_choose_box">
            <div class="store_list">
              <div class="store" v-for="myshop in myshops" :key="myshop.id">
                <div class="checkbox">
                  <input type="radio" :id="'test' + myshop.shop_code" name="radio-group" :value="myshop.shop_code" v-model="selected_shop_code" @change="onChangeSelectedShopCode(myshop)">
                  <label :for="'test' + myshop.shop_code"></label>
                </div>
                <div class="leftBox">
                  <p class="shopName"><a href="javascript:void(0)" class="icoBlank" target="_blank" @click="openShopSite(myshop)">{{ myshop.shop_name }}</a></p>
                  <p class="shopText">〒{{ myshop.zip_code }}<br>{{ myshop.shop_address }}</p>
                </div>
                <div class="rightBox" v-if="productId">
                  <p>取り扱い</p>
                  <ul class="listBtn">
                    <li class="btnStock01">
                      <div class="stock icon" v-if="isInStock(myshop)">あり</div>
                      <div class="none icon" v-else>なし</div>
                    </li>
                  </ul>
                </div>
                <div class="btn_list right">
                  <div class="btnStock01 gray" @click="removeMyshop(myshop)">削除</div>
                </div>
              </div>
              <div class="store empty" v-if="myshops.length < 3" >
                <div class="btn js-modal" data-id='main-modal' @click="openMyStoreSearchModal">
                  マイ店舗を追加登録する
                </div>
                <p>※あと{{ 3 - myshops.length }}店舗登録できます（最大3店舗）</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "MyStoreListModal",
  components: {
  },
  props: {
    myshops: Array,
    productId: Number,
  },
  data() {
    return {
      selected_shop_code: "",
    };
  },
  computed: {
  },
  methods: {
    closeModal() {
      $('#main-modal-2').fadeOut();
    },
    openMyStoreSearchModal() {
      this.$emit("openMyStoreSearchModal");
    },
    openShopSite(shop) {
      window.open(shop.site_url)
    },
    isInStock: function(shop) { // 在庫有無
      return shop.available;
    },
    onChangeSelectedShopCode: function(shop) {
      this.$emit("changeSeletedShop", shop);

      // 変更後モーダルを閉じる
      this.closeModal();
    },
    removeMyshop: function(shop) {
      this.$emit("removeMyshop", shop);
    },
  },
  watch: {
    myshops() {
      const selected_shop = this.myshops.find(s => s.selected == true)
      this.selected_shop_code = selected_shop ? selected_shop.shop_code : ""
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";
</style>
