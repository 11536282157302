var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dMenu"}},[_c('div',{attrs:{"id":"dMenuList"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((this.rack_stands),function(rack_stand){return _c('div',{key:rack_stand.id,class:_vm.itemStyle(rack_stand.rack_set_id, rack_stand.stand_no)},[_c('router-link',{attrs:{"to":{
            name: 'product_detail_03',
            query: {
              product_major_id: _vm.$route.query.product_major_id,
              product_middle_id: _vm.$route.query.product_middle_id,
              product_minor_id: _vm.$route.query.product_minor_id,
              rack_set_id: rack_stand.rack_set_id,
              stand_no: rack_stand.stand_no,
              can_cross_product_minors: _vm.$route.query.can_cross_product_minors,
            },
          }}},[_c('span',[_vm._v(" "+_vm._s(rack_stand.stand_name)+" ")])])],1)}),0),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"})])])}
var staticRenderFns = []

export { render, staticRenderFns }