<template>
  <a class="modalCampaign">
    <div class="point" :style="{ 'justify-content': position }">
      <div class="point_img">
        <img src="../../assets/images/else/img_point01.svg" alt="" v-if="productPoint.gives_type == 'fix'">
        <img src="../../assets/images/else/img_point02.svg" alt="" v-else>
        <p class="point_string">
          <svg viewBox="0 0 200 200">
            <text x="100" y="100">
              {{productPoint.point_value}}
            </text>
          </svg>          
        </p>
        <p class="point_unit" :class="{rate : productPoint.gives_type == 'rate'}">
          <img src="../../assets/images/else/img_point_unit01.svg" alt="" v-if="productPoint.gives_type == 'fix'">
          <img src="../../assets/images/else/img_point_unit02.svg" alt="" v-else>
        </p>
      </div>
      <div class="point_expiry" :style="{ color: productPoint.color  }">
        <div class="point_expiry__top">
          <div class="point_expiry__year">{{ productPoint.to | year }}</div>
          <div class="point_expiry__name" v-if="productPoint.name" :style="{ background: productPoint.color  }">{{ productPoint.name }}</div>
        </div>
        <div class="point_expiry__bottom">
          <div class="point_expiry__date">{{ productPoint.to | month_day }}</div>
          <div class="point_expiry__desc">店頭購入分まで</div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import utils from "../../packs/mixins/utils.js";

export default {
  name: "PointBox",
  components: {
  },
  mixins: [
    utils
  ],
  props: {
    productPoint: {
      type: Object,
    },
    position: {
      type: String,
      default: "center",
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.modalCampaign {
  height: 100%;
}
.point {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  height: 100%;
  &_img {
    position: relative;
    height: 100%;
    aspect-ratio: 45 / 29;
  }
  &_string {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    z-index: 9;
    text {
      font-size: 1300%;
    }
  }

  &_unit {
    width: 17.5%  ;
    position: absolute;
    top: 42%;
    right: 5%;
    z-index: 9;
    &.rate {
      width: 20%;
      right: 12%;
    }
  }

  &_expiry {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    margin-left: 1rem;
    color: #707070;
    font-size: 1rem;
    font-weight: bold;
    &__top {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__year {
    }
    &__name {
      padding: 0 0.5rem;
      border-radius: 1rem;
      background-color: #707070;
      color: #fff;
    }
    &__bottom {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 1.6rem;
    }
    &__date {
      height: 100%;
      margin-right: 1rem;
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
    &__desc {
      line-height: 1rem;
    }
  }
}
</style>