<template>
  <div>
    <div class="mainContent">
      <div class="textBox01">
        <p class="text01"><strong>おうちウエルシア</strong></p>

        <!-- 要素追加 -->
        <router-link :to="{ name: 'what_is_this_01' }" class="link01">
          <div class="btnWhatIsThis">
            <span class="btnWhatIsThis__text">おうちウエルシアで<br>できること</span>
          </div>
        </router-link>
        <!-- 要素追加 -->

      </div>
      <div class="searchBox">        
        <div v-if="news" class="whatsNew">
          <span class="whatsNew__text">{{ news }}</span>
        </div>
        <router-link :to="{ name: 'what_is_this_01' }" class="link01">
          <div class="btnWhatIsThis">
            <span class="btnWhatIsThis__text">おうち<br>ウエルシアで<br>できること</span>
          </div>
        </router-link>
        <p class="btnRed01">
          <router-link :to="{ name: 'store_map_01' }">
            お店に入る
          </router-link>
          <img src="../../assets/images/top/hand.png" alt="">
        </p>
      </div>

      <div class="textBox02">
        <p class="text01">※現在は一部商品のみのお取り扱いとなります。<br>※一部実施していないエリア・店舗がございます。</p>
        <router-link class="link01" :to="{ name: 'store_list_01' }">
          対象店舗はこちら
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";

export default {
  name: "Top",
  components: {
  },
  data() {
    return {
      url: this.$baseFrontURL,
      news: undefined,
    };
  },
  methods: {
    setNews(info) {
      this.news = info.data.content;
      this.$root.hideLoading()
    },
    callGetNewsApi() {
      this.$root.showLoading()
      Api.getNews(this.setNews, this.$root.onDefaultFailureRequest);
    },
  },
  mounted() {
    this.callGetNewsApi()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/top.scss";
</style>
