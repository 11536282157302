<template>
  <div style="position: relative">
    <SearchResultsHeader />
    <router-view />
  </div>
</template>

<script>
import SearchResultsHeader from '../global/SearchResultsHeader'

export default {
  name: 'SearchResultsLayout',
  components: {
    SearchResultsHeader,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = '';
  },
}
</script>

<style scoped>
</style>
