<template>
  <div id="app">
    <div class="mobile">
      <component v-bind:is="layout()"></component>
      <div class="loading" v-if="$root.isLoading">
        <Loading />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from './components/layouts/DefaultLayout'
import TopLayout from './components/layouts/TopLayout'
import StoreListLayout from './components/layouts/StoreListLayout'
import BackorderLayout from './components/layouts/BackorderLayout'
import CartLayout from './components/layouts/CartLayout'
import SearchResultsLayout from './components/layouts/SearchResultsLayout'
import StoreMapLayout from './components/layouts/StoreMapLayout'
import MyListLayout from './components/layouts/MyListLayout'
import FaqLayout from './components/layouts/FaqLayout'
import HowToUseLayout from './components/layouts/HowToUseLayout'
import BackorderHistoriesLayout from './components/layouts/BackorderHistoriesLayout'
import WhatIsThisLayout from './components/layouts/WhatIsThisLayout'
import NoHeaderLayout from './components/layouts/NoHeaderLayout'

import $ from 'jquery';
// import isMobile from "ismobilejs";
import { judgePlatform } from './lib/lib.js'

import Vue from 'vue'
import VueQriously from 'vue-qriously'
import { VLazyImagePlugin } from "v-lazy-image";
import InfiniteLoading from 'vue-infinite-loading'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueQriously)
Vue.use(VLazyImagePlugin);
Vue.use(InfiniteLoading)
Vue.use(VueScrollTo)

import Loading from "@/components/parts/Loading.vue";

Vue.config.productionTip = true
if (`${window.location.origin}` == 'https://ouchi-www.e-welcia.com') { // ドットコムから遷移した本番

  Vue.prototype.$baseApiURL = 'https://ouchi-api.e-welcia.com';
  Vue.prototype.$baseFrontURL = 'https://ouchi-www.e-welcia.com';

} else if (`${window.location.origin}` == 'https://www.o-welcia.com') { // 本番

  Vue.prototype.$baseApiURL = 'https://api.o-welcia.com';
  Vue.prototype.$baseFrontURL = 'https://www.o-welcia.com';

} else if (`${window.location.origin}` == 'https://stg-www.o-welcia.com') {  // ステージング

  Vue.prototype.$baseApiURL = 'https://stg-api.o-welcia.com';
  Vue.prototype.$baseFrontURL = 'https://stg-www.o-welcia.com';

} else {

  Vue.prototype.$baseApiURL = 'http://localhost:3000'
  Vue.prototype.$baseFrontURL = 'http://localhost:8080'

}

// 暫定固定店舗
Vue.prototype.$shopId = 5144 // ウエルシア千代田御茶ノ水店
Vue.prototype.$shopName = "ウエルシア神田小川町2号店"
Vue.prototype.$shopLatitude = 35.6948626
Vue.prototype.$shopLongitude = 139.7668188

Vue.filter('numberFormat',function(value){
  return value !== null ? value.toLocaleString() : "";
})

Vue.filter('taxedPrice',function(value){
  return value !== null ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "";
})

export default {
  name: 'App',
  components: {
    DefaultLayout,
    TopLayout,
    StoreListLayout,
    BackorderLayout,
    CartLayout,
    SearchResultsLayout,
    StoreMapLayout,
    MyListLayout,
    FaqLayout,
    HowToUseLayout,
    BackorderHistoriesLayout,
    WhatIsThisLayout,
    NoHeaderLayout,
    Loading,
  },
  data() {
    return {
    };
  },
  methods :{
    layout() {
      let layout = this.$route.meta.layout ? this.$route.meta.layout + '-layout' : 'default-layout';
      return layout;
    }
  },
  mounted(){
    $(function(){
      // プラットフォームの判定
      var platform = judgePlatform();
      for(var i in platform){ $('body').addClass(platform[i]); }
    });
  },
}
</script>

<style>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}
</style>
