<template>
  <div class="mainContent">
    <div class="innerContent01">
      <div class="no_backorder_histories" v-if="user_reserves.length === 0">注文履歴はありません</div>
      <div class="button-wrapper" v-else>
        <p>※価格や商品名はご注文時のデータを表示しております。内容は変更になっている場合がございます。</p>
        <p>※履歴は注文時と同じブラウザのみでご確認いただけます。</p>
      </div>
      <div class="boxSearch01">
        <ul class="listSearch01">
          <li v-for="user_reserve in user_reserves" :key="user_reserve.id">
            <div class="user_reserve" v-if="user_reserve.product_enabled">
              <a href="javascript:void(0)" @click="selectProduct(user_reserve.product)">
                <div class="user_reserve_top">
                  <p>{{ user_reserve.created_at | short_year_month_day }}に注文</p>
                  <p>{{ user_reserve.shop.shop_name }}</p>
                </div>
                <div class="user_reserve_bottom">
                  <div class="user_reserve_bottom_left">
                    <div class="image">
                      <v-lazy-image
                        :src="$baseApiURL + user_reserve.product_main_image_url" alt=""
                        :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                        v-if="user_reserve.product_main_image_url != ''"
                      />
                      <NoImage 
                        :categoryName='categoryName(user_reserve.product)' 
                        :productMajorId='user_reserve.product.product_major_id'
                        v-else />
                    </div>
                    <div class="thumb" v-if="canShowVariation(user_reserve.product)">
                      <div class="img" v-if="canShowVariationImage(user_reserve.product)">
                        <img :src="$baseApiURL + user_reserve.product.product_variation_image_url" alt="">
                      </div>
                      <div class="label"><p>{{ user_reserve.product.product_variation_name }}</p></div>
                    </div>
                  </div>
                  <div class="user_reserve_bottom_right">
                    <p class="user_reserve_product_name">{{ user_reserve.product.product_name }}</p>
                    <p class="user_reserve_price">販売価格：<strong>{{ user_reserve.product.price | numberFormat }}円</strong></p>
                    <p class="user_reserve_taxed_price">(税込：{{ user_reserve.product.taxed_price | taxedPrice }}円)</p>
                    <p class="user_reserve_number">注文数：{{ user_reserve.number }}個</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="user_reserve" v-else>
              <div class="user_reserve_top">
                <p>{{ user_reserve.created_at | short_year_month_day }}に注文</p>
                <p>{{ user_reserve.shop.shop_name }}</p>
              </div>
              <div class="user_reserve_bottom">
                <div class="user_reserve_bottom_left">
                  <div class="image">
                    <NoImage :categoryName="'現在は取り扱いがない商品です'" />
                  </div>
                </div>
                <div class="user_reserve_bottom_right">
                  <p class="user_reserve_product_name">{{ user_reserve.product.product_name }}</p>
                  <p class="user_reserve_price">販売価格：{{ user_reserve.product.price | numberFormat }}円</p>
                  <p class="user_reserve_taxed_price">(税込：{{ user_reserve.product.taxed_price | taxedPrice }}円)</p>
                  <p class="user_reserve_number">注文数：{{ user_reserve.number }}個</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import moment from 'moment'
import NoImage from "../parts/NoImage"
import utils from "../../packs/mixins/utils.js";

export default {
  name: "BackorderHistories01",
  components: {
    NoImage,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      user_reserves: [],
    };
  },
  filters: {
    short_year_month_day(date) {
      return moment(date).format('YYYY/MM/DD');
    },
  },
  methods: {
    selectProduct(product) {
      if (this.canShowVariation(product)) { // カウンセリング化粧品 or ビューティーケア
        this.$router.push({ 
          name: 'cosmetics_02', 
          params: { product_id: product.id },
        })
      } else {
        this.$router.push({ 
          name: 'product_detail_01', 
          params: { product_id: product.id },
        })
      }
    },
    setUserReserves(info) {
      this.user_reserves = info.data;

      this.$root.hideLoading()
    },
    callGetUserReserves() {
      this.$root.showLoading()

      Api.getUserReserves(
        this.setUserReserves,
        this.$root.onDefaultFailureRequest
      );
    },
  },
  mounted() {
    this.callGetUserReserves();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/my_list.scss";
  @import "../../assets/stylesheets/responsive/backorder_history.scss";
  .mainContent {
    .boxSearch01 {
      .listSearch01 > li {
        .user_reserve_bottom_left {
          // height: 100%;
          .image {
            height: 12rem;
            @media screen and (max-width: 850px) {
              height: 10rem;
            }
            img {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
</style>
