<template>
  <div>
    <div id="searchOverlay"></div>
    <h1 class="title01">検索結果</h1>
    <div class="searchBox">
      <div class="searchBoxInner">
        <a class="btn close" @click="closeModal"></a>
        <div class="ttl">
        </div>
        <p class="txt01">キーワードで絞り込み</p>
        <p class="search"><input type="text" placeholder="商品名・キーワード・JANコードで探す" v-model="keyword"></p>
        <p class="txt02">カテゴリーで絞り込み</p>
        <div class="select01Wrap"><!-- タグ追加 -->
          <p class="select01">
            <select v-model="product_major_id">
              <option value="" selected>すべて</option>
              <option value="1">ヘルスケア</option>
              <option value="2">衛生介護</option>
              <option value="3">ベビー</option>
              <option value="4">カウンセリング化粧品</option>
              <option value="5">ビューティケア</option>
              <option value="6">ホームケア</option>
            </select>
          </p>
          <p class="select01">
            <select v-model="product_middle_id">
              <option 
                v-for="product_middle in product_middles"
                :key="product_middle.id"
                :value="product_middle.id" selected>
                  {{ product_middle.product_middle_name }}
              </option>
            </select>
          </p>
          <p class="select01">
            <select v-model="product_minor_id">
              <option 
                v-for="product_miner in product_miners"
                :key="product_miner.id"
                :value="product_miner.id" selected>
                  {{ product_miner.product_minor_name }}
              </option>
            </select>
          </p>
        </div><!-- タグ追加 -->
        <p class="txt02">ボーナスポイント</p>
        <p class="checkbox01">
          <label for="check01">ボーナスポイントあり</label>
          <input type="checkbox" name="" id="check01" v-model="exists_points">
        </p>
        <p class="button"><button class="btnSearch02" @click="gotoSearchResult()">検索</button></p>
      </div>
      <div class="explanation" id="explanation">
        <div class="explanation__title" @click="scrollToExplanation">
          マイリストに登録して<br>
          お買い物リストを作ろう！
        </div>
        <div class="explanation__wrap"><!-- タグ追加 -->
          <div class="explanation__image">
            <img src="../../assets/images/else/img_mylist_00.png" alt="">
          </div>
          <div class="explanation__arrow">
          </div>
          <div class="explanation__image">
            <img src="../../assets/images/else/img_mylist_01.png" alt="">
          </div>
          <div class="explanation__arrow">
          </div>
          <div class="explanation__image">
            <img src="../../assets/images/else/img_mylist_02.png" alt="">
          </div>
        </div><!-- タグ追加 -->
        <div class="explanation__desc">
          ※マイリストは登録されたブラウザでのみ表示されます。
        </div>
        <div class="explanation__backToTop" @click="scrollToTop">
          ページTOPに戻る
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import $ from "jquery";

export default {
  name: 'SearchResultsHeader',
  components: {
  },
  data() {
    return {
      keyword: this.$route.query.keyword,
      product_major_id: this.$route.query.product_major_id,
      product_middle_id: this.$route.query.product_middle_id,
      product_minor_id: this.$route.query.product_minor_id,
      exists_points: this.$route.query.exists_points,
      visible: false,

      product_middles: [],
      product_miners: [],
    }
  },
  methods: {
    scrollToExplanation() {
      $("html, body").animate({scrollTop: $("#explanation").offset().top - 50}, 500, 'swing');
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0}, 500, 'swing');
    },
    closeModal() {
      this.$router.push({
        name: "search_results_01",
        query: Object.assign({},this.$route.query,{modal: undefined}),
      });
    },
    gotoSearchResult() {
      this.visible = false

      var query = {}
      if (this.keyword !== "" || this.keyword !== null) {
        query.keyword = this.keyword 
      }
      if (this.product_major_id !== 0 && this.product_major_id !== '') {
        query.product_major_id = this.product_major_id 
      }
      if (this.product_middle_id !== 0 && this.product_middle_id !== '') {
        query.product_middle_id = this.product_middle_id 
      }
      if (this.product_minor_id !== 0 && this.product_minor_id !== '') {
        query.product_minor_id = this.product_minor_id 
      }
      if (this.exists_points !== 0 && this.exists_points !== '') {
        query.exists_points = this.exists_points 
      }
      this.$router.push({
        name: "search_results_01",
        query: query,
      });

      this.closeModal()
    },
    onEnter(input) {
      if (this.keyword === null) {
        this.keyword = input
      } else {
        this.keyword += input
      }
      this.gotoSearchResult()
    },
    onBksp() {
      this.keyword = this.keyword.slice(0, -1)
    },
    onClear() {
      this.keyword = ""
    },
    show() {
      if (!this.visible) {
        this.visible = true
      }
    },
    hide() {
      this.visible = false
    },
    setProductMiddles: function (info) {
      this.product_middles = info.data
      this.product_middles.unshift({ id: "", product_middle_name: "すべて"})
      this.product_middle_id = ""
    },
    callProductMiddleApi() {
      Api.getProductMiddles(this.product_major_id , this.setProductMiddles, this.$root.onDefaultFailureRequest);
    },
    setProductMiners: function (info) {
      this.product_miners = info.data
      this.product_miners.unshift({ id: "", product_minor_name: "すべて"})
      this.product_minor_id = ""
    },
    callProductMinerApi() {
      Api.getProductMiners(this.product_middle_id, null, this.setProductMiners, this.$root.onDefaultFailureRequest);
    },
  },
  mounted(){
    this.product_major_id = ""

    this.product_middles.unshift({ id: "", product_middle_name: "すべて"})
    this.product_middle_id = ""

    this.product_miners.unshift({ id: "", product_minor_name: "すべて"})
    this.product_minor_id = ""

  },
  watch: {
    product_major_id() {
      if (this.product_major_id === 0 || this.product_major_id === "") {
        this.product_middles = []
        this.product_middles.unshift({ id: "", product_middle_name: "すべて"})
        this.product_middle_id = ""
        this.product_miners = []
        this.product_miners.unshift({ id: "", product_minor_name: "すべて"})
        this.product_minor_id = ""
      } else {
        this.callProductMiddleApi()
      }
    },
    product_middle_id() {
      if (this.product_middle_id === 0 || this.product_middle_id === "") {
        this.product_miners = []
        this.product_miners.unshift({ id: "", product_minor_name: "すべて"})
        this.product_minor_id = ""
      } else {
        this.callProductMinerApi()
      }
    },
    "$route.query.modal"() {
      if (this.$route.query.modal !== null) {
        $('.btnSearch').removeClass('open');
        $('.searchBox').slideUp();
        $('#searchOverlay').fadeOut();
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/search_results.scss";
</style>