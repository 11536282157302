<template>
  <div>
    <div class="mainContent">
      <div class="innerContent01">
        <BoxItemWide 
          :title="'お取り寄せ希望商品'"
          :productId="product_id"
          :showCurrentShop="false"
          @setExtLink="setExtLink"
          @setReservable="setReservable"
        />
        <div class="boxItem-wrapper"><!--タグ追加-->
          <div class="boxItem03">
            <div class="ttlItem01 center">
              店舗でのお取り扱い
            </div>

            <div class="boxItemInner01">
              <p class="txtOrder03 left" v-if="reservable">※取り扱い状況は{{ tmp_import_date }}7時時点の情報です。<br>店頭では売り切れの場合もございます。商品によっては確認後に取り寄せができないこともございますのでご了承ください。</p>
              <p class="txtOrder03" v-else>※こちらの商品はお取り扱いできません。</p>

              <div class="store_choose_box">
                <div class="ttl">マイ店舗</div>
                <div class="store_list">
                  <div class="store" v-for="myshop in myshops" :key="myshop.id">
                    <div class="close js-modal" data-id="confirm-modal-01" @click="clickRemoveMyshop(myshop)"></div>
                    <div class="leftBox">
                      <p class="shopName"><a href="javascript:void(0)" class="icoBlank" target="_blank" @click="openShopSite(myshop)">{{ myshop.shop_name }}</a></p>
                      <p class="shopText">{{ myshop.shop_address }}</p>
                    </div>
                    <div class="rightBox">
                      <p>取り扱い</p>
                      <ul class="listBtn">
                        <li class="btnStock01">
                          <div class="stock icon" v-if="isInStock(myshop)"></div>
                          <div class="none icon" v-else></div>
                        </li>
                      </ul>
                    </div>
                    <div class="btn_list">
                      <div class="btnStock01 blue">
                        <router-link
                          :to="{
                            name: 'store_search_03',
                            params: {
                              shopCode: myshop.id,
                              shopName: myshop.shop_name,
                              currentLatitude: current_latitude,
                              currentLongitude: current_longitud,
                            },
                            query: {
                              shop_latitude: myshop.latitude,
                              shop_longitude: myshop.longitude,
                            },
                          }"
                          >
                          MAPで確認
                        </router-link>
                      </div>
                      <div class="btnStock01 red" v-if="backorderable(myshop)">
                        <a href="javascript:void(0)" @click="clickAddToCart(myshop)">この店舗に取り寄せ</a>
                      </div>
                    </div>
                  </div>
                  <div class="store empty btnModal02 js-modal" data-id="main-modal" v-if="myshops.length < 3" @click="openMyStoreListModal">
                    <p>よく使う店舗を登録する<br>（あと{{ 3 - myshops.length }}店舗登録できます）</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="boxItem03">
            <div class="ttlItem01 center">
              対象店舗を検索
            </div>
            <div class="boxItemInner01">
              <p class="txtOrder03 left">※一部実施していないエリア・店舗がございます。<br>対象店舗は
                <router-link class="link01" :to="{ name: 'store_list_01' }">
                  こちら。
                </router-link>
              </p>
              <div class="store_choose_box">
                <div class="ttl">現在地から店舗を検索</div>
                <div class="btn-wrapper">
                  <div class="btn-search red" @click="searchByCurrentLocation">近くの対象店舗を探す</div>
                </div>
                <hr class="dashed">
                <div class="ttl">地名・郵便番号から検索</div>
                <div class="label">地名を入力して検索</div>
                <input type="text" name="" placeholder="例：静岡県、千代田区など" v-model="place">
                <p class="error" v-if="this.messagePlaceError.length">
                  {{ this.messagePlaceError }}
                </p>
                <div class="label">郵便番号で検索</div>
                <input type="text" name="" placeholder="1110000（ハイフンなしで入力）" v-model="zipcode">
                <p class="error" v-if="this.messageZipcodeError.length">
                  {{ this.messageZipcodeError }}
                </p>
                <div class="btn-wrapper">
                  <div class="btn-search blue" @click="comfirmKeyword">検索</div>
                </div>
              </div>
            </div>
          </div>
        </div><!--タグ追加-->

        <div class="boxItem-result"><!--タグ追加-->
          <div id="storeSearchResult" class="boxItem03" v-if="has_searched">
            <div class="boxItemInner01">
              <div class="store_choose_box">
                <p class="txtOrder03 left border">※一部実施していないエリア・店舗がございます。<br>対象店舗は
                  <router-link class="link01" :to="{ name: 'store_list_01' }">
                    こちら。
                  </router-link>
                </p>
                <div class="result-info">
                  <p v-if="has_searched_by_current_location">「現在地」の検索結果</p>
                  <p v-else>「{{ keyword }}」の検索結果</p>
                  <p>全{{ filterd_shops.length }}件</p>
                </div>
                <p class="txtInfo07"><label for="check01">お取り扱いのある店舗のみ表示<input type="checkbox" id="check01" class="checkBox" v-model="filtered"></label></p>
                <div class="store_list result_list">
                  <div class="store" v-for="shop in filterd_shops" :key="shop.id">
                    <div class="leftBox">
                      <div class="tag blue js-modal" data-id="confirm-modal-02" @click="addMyshop(shop)" v-if="!registered(shop)"><span>よく使う店舗に登録</span></div>
                      <div class="tag gray js-modal" data-id="confirm-modal-03" v-else><span>よく使う店舗に登録済</span></div>
                      <p class="shopName"><a href="javascript:void(0)" class="icoBlank" target="_blank" @click="openShopSite(shop)">{{ shop.shop_name }}</a></p>
                      <p class="shopText">〒{{ shop.zip_code }}</p>
                      <p class="shopText">{{ shop.shop_address }}</p>
                    </div>
                    <div class="rightBox">
                      <p>取り扱い</p>
                      <ul class="listBtn">
                        <li class="btnStock01">
                          <div class="stock icon" v-if="isInStock(shop)"></div>
                          <div class="none icon" v-else></div>
                        </li>
                      </ul>
                    </div>
                    <div class="btn_list">
                      <div class="btnStock01 blue">
                        <router-link
                          :to="{
                            name: 'store_search_03',
                            params: {
                              shopCode: shop.id,
                              shopName: shop.shop_name,
                              currentLatitude: current_latitude,
                              currentLongitude: current_longitud,
                            },
                            query: {
                              shop_latitude: shop.latitude,
                              shop_longitude: shop.longitude,
                            },
                          }"
                          >
                          MAPで確認
                        </router-link>
                      </div>
                      <div class="btnStock01 red" v-if="backorderable(shop)">
                        <a href="javascript:void(0)" @click="clickAddToCart(shop)">この店舗に取り寄せ</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!--タグ追加-->
      </div>
      <div class="warning-modal modal" id="warning-modal-01">
        <div class="modal-overlay"></div>
        <div class="content">
          <div class="content-info">
            <div class="ttl"><span>ご注意</span></div>
            <p class="btnClose" @click="cancelAddToCart"></p>
            <p>取り寄せ店舗を変更すると<br>お取り寄せ一覧がリセットされます。</p>
            <div class="btn-wrapper">
              <div class="btn" @click="goToCart">お取り寄せ一覧を見る</div>
              <div class="btn red js-modal" data-id="main-modal-2" @click="confirmAddToCart">リセットして続ける</div>
            </div>
          </div>
        </div>
      </div>
      <MyStoreList
        ref="my_store_list"
        :productId="product_id" 
        :myshops="myshops"
        @getMyshops="callGetMyshopsApi" />
    </div>
  </div>
</template>

<script>
import Api from "../../lib/api";
import Geo from "../../lib/geo";
import $ from "jquery";

import BoxItemWide from "../parts/BoxItemWide"
import MyStoreList from "../parts/MyStoreList"

import VueScrollTo from 'vue-scrollto'

export default {
  name: "ProductDetail02",
  components: {
    BoxItemWide,
    MyStoreList,
  },
  data() {
    return {
      // common
      product_id: 0,
      ext_link: "",
      reservable: true, // 取扱可否

      // fullscreen
      available: "", // 在庫状態

      // mobile
      place: "",
      zipcode: "",
      filtered: false,

      shops: [],
      myshops: [],

      has_searched_by_keyword: false,
      has_searched_by_current_location: false,

      current_latitude: null,
      current_longitud: null,

      messagePlaceError: "",
      messageZipcodeError: "",

      selected_shop_code: "", // 取り寄せボタン押下時に一時的に選択した店舗を保存しておく
    };
  },
  computed: {
    tmp_import_date() {
      let date = new Date();
      if (date.getHours() > 7) {
        return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日"
      } else {
        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate() - 1 );
        return yesterday.getFullYear() + "年" + (yesterday.getMonth() + 1) + "月" + yesterday.getDate() + "日"
      }
    },
    isInStockAtCurrentShop: function() { // 在庫有無
      return this.available;
    },
    backorderableAtCurrentShop: function() { // 取り寄せ可否
      return !this.isInStockAtCurrentShop && this.reservable;
    },
    filterd_shops: function() {
      if (this.filtered) {
        return this.shops.filter(s => s.available)
      } else {
        return this.shops
      }
    },
    keyword: function() {
      if(this.place && this.zipcode) {
        return (this.place + " " + this.zipcode).trim(); // 場所と郵便番号が両方ある場合はスペース区切り
      } else {
        return (this.place + this.zipcode).trim(); // 場所と郵便番号が片方の場合はどちらかだけ
      }
    },
    has_searched: function() {
      return this.has_searched_by_keyword || this.has_searched_by_current_location;
    },
    selectedMyshop: function() { // 選択中のマイショップ
      return this.myshops.find(s=>s.selected)
    },
  },
  methods: {
    registered: function(shop) { // マイショップ登録済か
      return this.myshops.some(s => s.id === shop.id)
    },
    isInStock: function(shop) { // 在庫有無
      return shop.available;
    },
    backorderable: function(shop) { // 取り寄せ可否
      return !this.isInStock(shop) && this.reservable && shop.backorderable;
    },
    reset: function() {
      this.shops = [];
      this.place = "";
      this.zipcode = "";
      this.filtered = false;
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = false;
    },
    openShopSite(shop) {
      window.open(shop.site_url)
    },
    clickRemoveMyshop: function(myshop) {
      this.$refs.my_store_list.clickRemoveMyshop(myshop); // 子のメソッド呼び出し
    },
    openMyStoreListModal() {
      this.$refs.my_store_list.openMyStoreListModal(); // 子のメソッド呼び出し
    },
    setExtLink(ext_link) {
      this.ext_link = ext_link
    },
    setReservable(reservable) {
      this.reservable = reservable
    },
    callbackGetHandlingApi: function (info) {
      this.$root.hideLoading();
      this.available = info.data.available;
    },
    callGetHandlingApi: function() {
      this.$root.showLoading();
      Api.getHandling(
        this.$shopId, 
        this.product_id, 
        this.callbackGetHandlingApi, 
        this.$root.onDefaultFailureRequest
      );      
    },
    searchByCurrentLocation: function() {
      this.$root.showLoading();
      this.reset();
      const onSuccess = (position) => {
        this.current_latitude = position.coords.latitude;
        this.current_longitud = position.coords.longitude;
        this.callSearchNearShopsApi(
          position.coords.latitude,
          position.coords.longitude,
        )
        this.$root.hideLoading();
      }
      const onFailure = () => {
        this.$root.hideLoading();
      }
      Geo.getLocation(onSuccess, onFailure)
    },
    addMyshop: function(shop) {
      this.$refs.my_store_list.addMyshop(shop); // 子のメソッド呼び出し
    },
    confirmAddToCart: function() {
      $('#warning-modal-01').fadeOut();
      this.addToCart();
    },
    cancelAddToCart: function() {
      this.selected_shop_code = "";
      $('#warning-modal-01').fadeOut();
    },
    goToCart: function() {
      this.$router.push({ 
        name: 'cart_01', 
      });
    },
    clickAddToCart: function(shop) {
      this.selected_shop_code = shop.shop_code;
      if (this.$root.selectedShop === null || shop.shop_code === this.$root.selectedShop.shop_code) {
        this.confirmAddToCart();
      } else {
        $('#warning-modal-01').fadeIn();
      }
    },
    callbackAddToCart: function () {
      this.$root.hideLoading();
      this.$root.callGetProductsInCart();
      this.$router.push({
        name: "cart_01",
        query: Object.assign({},this.$route.query),
      });
    },
    addToCart: function () {
      this.$root.showLoading();
      Api.addToCart(
        this.selected_shop_code,
        this.product_id,
        this.callbackAddToCart,
        this.$root.onDefaultFailureRequest
      );
    },
    setMyshops: function (info) {
      this.myshops = info.data;
      this.$root.hideLoading()
    },
    callGetMyshopsApi() {
      this.$root.showLoading()
      Api.getMyshops(
        this.product_id,
        this.setMyshops,
        this.$root.onDefaultFailureRequest
      );
    },
    setShops: function (info) {
      this.shops = info.data;
      this.$root.hideLoading()

      VueScrollTo.scrollTo("#storeSearchResult", 1000, { offset: -100 });
    },
    callSearchNearShopsApi: function(latitude, longitude) {
      this.$root.showLoading();
      this.has_searched_by_keyword = false;
      this.has_searched_by_current_location = true;
      Api.searchNearShops(
        this.product_id,
        latitude,
        longitude,
        this.setShops,
        this.$root.onDefaultFailureRequest
      );
    },
    comfirmKeyword() {
      if (this.keyword.length == 0) {
        this.messagePlaceError = "地名または郵便番号のどちらかを入力してください。";
        this.messageZipcodeError = "地名または郵便番号のどちらかを入力してください。";
        return;

      } else {
        this.messagePlaceError = "";
        this.messageZipcodeError = "";
      }

      if (this.zipcode.trim().length !== 0 && this.zipcode.trim().length < 3) {
        this.messageZipcodeError = "郵便番号は3桁以上を入力してください。";
        return;
      } else {
        this.messageZipcodeError = "";
      }

      this.callSearchShops()
    },
    callSearchShops: function() {
      this.$root.showLoading();
      this.has_searched_by_keyword = true;
      this.has_searched_by_current_location = false;
      Api.searchShops(
        this.product_id, 
        this.place,
        this.zipcode,
        this.setShops, 
        this.$root.onDefaultFailureRequest
      );
    },
  },
  watch: {
  },
  mounted() {
    this.product_id = parseInt(this.$route.params.product_id);
    this.callGetMyshopsApi();
    this.$root.callGetProductsInCart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .error {
    font-size: 1.6rem;
    color: #dd4141;
  }

  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/product_details_11.scss";
</style>