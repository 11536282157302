<template>
  <div>
    <div class="mainContent">
      <div class="innerContent01">
        <div class="productDetail-wrapper"><!--タグ追加-->

          <!--要素追加-->
          <div class="color-wrap" v-if="variations.length > 0">
            <div class="content">
              <div class="ttl">カラー／バリエーションを選択</div>
              <div class="item-list">
                <div data-name="text 1" data-price="1,000円" class="item"  v-for="variation in variations" :key="variation.id">
                  <div class="label">{{ variation.product_variation_name }}</div>
                  <div class="img" v-if="variation.product_variation_image_url">
                    <img :src="$baseApiURL + variation.product_variation_image_url" alt="">
                  </div>
                </div>
                <!-- <div data-name="text 1" data-price="1,000円" class="item">
                  <div class="label">BE310</div>
                  <div class="img">
                    <img src="https://api.o-welcia.com/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeDhuQmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--e0b05517a0f7b02bd4972448cdb6060a13d9ab47/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTXpBd2VETXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--eb9d1f6ee2c28440769c1162aef4f71e6f1cbacb/tmp-data-variation-20211221-images-gazo72-irodama_4971710295900.jpg" alt="">
                  </div>
                </div>
                <div data-name="text 1" data-price="1,000円" class="item">
                  <div class="label">PK812</div>
                  <div class="img">
                    <img src="https://api.o-welcia.com/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeUFuQmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--98635f2f605b5268c4d789f6eae52a8575f167fb/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTXpBd2VETXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--eb9d1f6ee2c28440769c1162aef4f71e6f1cbacb/tmp-data-variation-20211221-images-gazo72-irodama_4971710295917.jpg" alt="">
                  </div>
                </div>
                <div data-name="text 1" data-price="1,000円" class="item">
                  <div class="label">RD410</div>
                  <div class="img"><img src="https://api.o-welcia.com/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeG9uQmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--308e80a62dfae4cff4a492550cb9ba949fa8cb4e/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTXpBd2VETXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--eb9d1f6ee2c28440769c1162aef4f71e6f1cbacb/tmp-data-variation-20211221-images-gazo72-irodama_4971710295849.jpg" alt="">
                  </div>
                </div>
                <div data-name="text 1" data-price="1,000円" class="item">
                  <div class="label">OR210</div>
                  <div class="img"><img src="https://api.o-welcia.com/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeG9uQmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--308e80a62dfae4cff4a492550cb9ba949fa8cb4e/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTXpBd2VETXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--eb9d1f6ee2c28440769c1162aef4f71e6f1cbacb/tmp-data-variation-20211221-images-gazo72-irodama_4971710295849.jpg" alt=""></div>
                </div> -->
              </div>
            </div>
          </div>
          <!--要素追加-->

          <div class="itemInfo">
            <div class="itemInfoInner">
              <h1 class="txtCat02">{{ product_name }}</h1>
              <p class="txtPrice01">販売価格：<b>{{ price | numberFormat }}円</b> (税込：{{ taxed_price | taxedPrice }}円)</p>
              <p class="txtCaution01">※店舗での特売やチラシの価格は反映されておりません。</p>
              <p class="txtCaution02">価格は店舗により異なる可能性があります。詳しくは店頭でご確認ください。</p>
              <p class="txtCat02"><small>JANコード : {{ jan_code }}</small></p>
              <div class="txtPoint01" 
                v-for="product_point in product_points"
                :key="product_point.id">
                <PointBox :productPoint="product_point" :position="'left'" />
              </div>
            </div>
          </div>
          <div class="boxItem01">
            <div class="item01">
              <v-lazy-image
                :src="$baseApiURL + product_image_url" alt=""
                :src-placeholder="require('../../assets/images/else/img_product_now_loading.png')"
                v-if="product_image_url != ''"
              />
              <NoImage 
                :categoryName='categoryName(product)' 
                :productMajorId='product_major_id'
                v-else />
              <Favorite :product_id="Number($route.params.product_id)" :favorite="favorite" />
              <div class="thumb" data-name="text 1" data-price="1,000円" v-if="variations.length > 0">
                <div class="label">{{ product_variation_name }}</div>
                <div class="img">
                  <img :src="$baseApiURL + product_variation_image_url" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="boxBtn01">
            <p class="btnModal02" v-if="variations.length > 0"><a href="javascript:void(0)" @click="openVariationModal">カラー／バリエーションを選択</a></p>
            <p class="btnModal01"><a href="javascript:void(0)" @click="openModal">商品詳細を見る</a></p>
            <ul class="listBtn">
              <li class="btnOnline01" v-if="this.ext_link"><a v-bind:href="this.ext_link" target="_blank">オンライン<br>ショップを見る</a></li>
              <li class="btnOnline02" v-else><p>オンラインショップ<br>取扱なし</p></li>
              <li class="btnDetail01">
                <router-link
                  :to="{
                    name: 'product_detail_02',
                    query: Object.assign({},$route.query),
                  }"
                  >店舗の取り扱いを確認</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ItemModal
      :product_id="Number(this.$route.params.product_id)"
      :product_detail="this.product_detail"
      :product_images="this.product_images"
      :product_pdf_url="this.product_pdf_url"
      @closeModal="closeModal"
    />
    <VariationModal :variations="variations" />
  </div>
</template>
<script>
import Api from "../../lib/api";
import $ from 'jquery';
import Swiper from "swiper";

import ItemModal from "../parts/ItemModal";
import Favorite from "../parts/Favorite"
import NoImage from "../parts/NoImage"
import VariationModal from "../parts/VariationModal"
import PointBox from "../parts/PointBox.vue";
import utils from "../../packs/mixins/utils.js";

export default {
  name: "Cosmetics02",
  components: {
    ItemModal,
    Favorite,
    NoImage,
    VariationModal,
    PointBox,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      product: null,
      product_name: "",
      maker_name: "",
      product_item_name: "",
      price: "",
      product_detail: "",
      product_points: [],
      taxed_price: "",
      product_major_id: 0,
      product_middle_name: "",
      product_minor_name: "",
      product_image_url: "",
      product_images: [],
      product_pdf_url: "",
      jan_code: "",
      ext_link: "",
      product_variation_name: "",
      product_variation_image_url : "",
      variations: [],
      favorite: false,
    };
  },
  methods: {
    openVariationModal: function() {
      $('#modalCategory02').fadeIn();
    },
    openModal: function() {
      if (this.hasDetails()) {
        $('#itemModal').fadeIn(function(){
          var currentIndex = $('.tab li.active').index();
        
          $('.itemModalBox02').find('.itemModalBoxInner01:eq('+currentIndex+')').show().addClass('active');
          var itemH = $('.itemModalBox02').find('.itemModalBoxInner01.active').outerHeight();
          $('.itemModalBox02').find('.itemModalBoxOuter01').css('height',itemH);

          new Swiper('#itemModalSlider', {
            slidesPerView: 'auto',
            speed: 600,
            loop: false,
            spaceBetween: 27,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          });

        });
      }
    },
    closeModal() {
      $('#itemModal').fadeOut(function(){
        $('.itemModalBox02').find('.itemModalBoxInner01').removeClass('active');//.hide();
      });
    },
    hasDetails() {
      return this.product_image_url !== "" || this.product_detail !== null || this.product_pdf_url !== ""
    },
    setProduct: function (info) {
      // 選択中の商品
      this.product = info.data;
      this.product_name = info.data.product_name;
      if (info.data.maker) {
        this.maker_name = info.data.maker.maker_name;
      }
      if (info.data.product_item) {
        this.product_item_name = info.data.product_item.item_name;
      }
      this.price = info.data.price;
      this.product_detail = info.data.product_detail;
      this.regulation = info.data.regulation;
      if (info.data.product_points) {
        this.product_points = info.data.product_points;
      }
      this.taxed_price = info.data.taxed_price;
      if (info.data.product_major) {
        this.product_major_id = info.data.product_major.id;
      }
      if (info.data.product_middle) {
        this.product_middle_name = info.data.product_middle.product_middle_name;
      }
      if (info.data.product_minor) {
        this.product_minor_name = info.data.product_minor.product_minor_name;
      }
      this.product_image_url = info.data.product_main_image_url;
      this.product_images = info.data.product_images;
      this.product_pdf_url = info.data.product_pdf_url;
      this.jan_code = info.data.jan_code;
      this.ext_link = info.data.ext_link;
      this.favorite = info.data.favorite;

      this.product_variation_name = info.data.product_variation_name;
      this.product_variation_image_url = info.data.product_variation_image_url;

      this.variations = info.data.variations;

      this.$root.hideLoading();
    },
    callGetProductApi() {
      Api.getProduct(Number(this.$route.params.product_id), this.setProduct, this.$root.onDefaultFailureRequest);

      this.$root.showLoading();
    },
  },
  computed: {
  },
  mounted() {
    this.callGetProductApi();
  },
  updated() {
  },
  watch: {
    "$route" (to, from) {
      if (from.params.product_id !== to.params.product_id) {
        this.callGetProductApi();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/stylesheets/responsive/base.scss";
  @import "../../assets/stylesheets/responsive/product_details.scss";
  @import "../../assets/stylesheets/responsive/cosmetics_details.scss";
  // 「オンラインショップ取扱なし」の場合のデザインが崩れるため、修正
  .mainContent {
    .listBtn {
      li{
        &.btnOnline02 {
          p {
            font-size: 13px;
            height: 40px;
            border-radius: 6px;
          }
        }
      }
    }
  }
</style>