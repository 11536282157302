<template>
  <div class="mainContent">
    <div class="innerContent01">
      <BoxItem 
        :title="'お取り寄せ希望商品'"
        :productId="product_id"
        :showCurrentShop="true"
        :shopName="shopName"
      />
      <div class="boxInfo01">
        <h2 class="ttlInfo01">お客様情報の登録</h2>
        <div class="info01">
          <p class="txtInfo01">お手元のスマートフォンからご登録ください。</p>
          <p class="txtInfo02"><span>この画像をカメラで読み取ってください</span></p>
          <p class="imageInfo01">
            <qriously :value="`${this.url}`" :size="180" />
          </p>
          <p class="txtInfo09">※読み取りが完了しましたら右下の「終了」ボタンでTOPにお戻りください<br>※携帯電話をお持ちで無い方はお近くの店舗スタッフをお呼びください</p>
        </div>
        <div class="info09">
          <p class="ttl"><span>お取り寄せまでの流れ</span></p>
          <ul>
            <li>
              <div class="round">
                <img src="../../assets/images/backorder/backorder_icon_01.svg" alt="">
              </div>
              携帯電話のSMSで<br>お取り寄せ登録
            </li>
            <li>
              <div class="round">
                <img src="../../assets/images/backorder/backorder_icon_02.svg" alt="">
              </div>
              商品が届いたら店舗から<br>SMSで連絡
            </li>
            <li>
              <div class="round">
                <img src="../../assets/images/backorder/backorder_icon_03.svg" alt="">
              </div>
              店舗で<br>お会計＆受取
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxItem from "../parts/BoxItem"

export default {
  name: "Backorder01_1",
  components: {
    BoxItem,
  },
  props: {
    shopId: Number,
    shopName: String,
  },
  data() {
    return {
      product_id: 0,
      url: "",
    };
  },
  methods: {
  },
  mounted() {
    this.product_id = parseInt(this.$route.params.product_id);

    this.url = encodeURI(this.$baseFrontURL + "/backorder/" + this.product_id + "/01_2" + "?shop_id=" + this.shopId);
console.log(this.url)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
